import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import api from '../../../store/api'

export default function UserVerificationAction() {

    const { id } = useParams()


    useEffect(() => {
        api.post('/api/users/verify_user/', {uniqueString: id})
        .then((response) => {
            console.log(response)
        })
        .catch((error) => {
            console.log(error)
        })
    }, [id])

  return (
    <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-10">
        <div className="pt-lg-10 mb-10">
            {/*begin::Logo*/}
            <h1 className="fwt-bolder fs-4 text-gray-800 mb-7">Welcome to Beysix Studio</h1>
            {/*end::Logo*/}
            {/*begin::Message*/}
            <div className="fwt-bold fs-5 text-muted mb-10" style = {{maxWidth: '27vw', margin: '0 auto'}}>Your email has been verified. Click the button below to go to login and start using your account if the admin has approved you into the application.</div>
            {/*end::Message*/}
            {/*begin::Action*/}
            <div className="text-center">
                <a href="/auth/login" className="btn btn-lg btn-beysix fwt-bolder">Go to login</a>
            </div>
            {/*end::Action*/}
        </div>
        {/*end::Wrapper*/}
        {/*begin::Illustration*/}
        <div  style={{marginTop: "70px",
            backgroundImage: "url('/static/assets/media/illustrations/beysix-1/email-verification.svg')",
            width: "70vw",
            height: "40vh",
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
        }}></div>{/*end::Illustration*/}
    </div>
  )
}
