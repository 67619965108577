import React from 'react';

export default function TablePagination({ currentPage, totalPages, onPageChange }) {
    const renderPages = () => {
        const pages = [];
        const maxPages = 3; // Maximum number of pages to show
        const startPage = Math.max(1, currentPage - Math.floor(maxPages / 2));
        const endPage = Math.min(totalPages, startPage + maxPages - 1);

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <li key={i} className={`paginate_button page-item ${i === currentPage ? 'active' : ''}`}>
                    <a  onClick={(e) => {e.preventDefault(); handlePageChange(i)}} className="page-link">{i}</a>
                </li>
            );
        }
        return pages;
    };

    const handlePageChange = (page) => {
        if (page !== currentPage && page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    return (
        <div className="row">
            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                <div className="dataTables_paginate paging_simple_numbers" id="kt_table_users_paginate">
                    <ul className="pagination">
                        <li className={`paginate_button page-item previous ${currentPage === 1 ? 'd-none' : ''}`}>
                            <a  onClick={(e) => {e.preventDefault(); handlePageChange(currentPage - 1)}} className="page-link"><i className="previous"></i></a>
                        </li>
                        {renderPages()}
                        <li className={`paginate_button page-item next ${currentPage === totalPages ? 'd-none' : ''}`}>
                            <a  onClick={(e) => {e.preventDefault(); handlePageChange(currentPage + 1)}} className="page-link"><i className="next"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
