import React from 'react'
import './LoaderGrid.css'

export default function LoaderGrid() {
  return (
    <div>
    <section className="section--white p-3">
  <div className="row text-center">
    <div className="col" style={{margin: "0 auto"}}>
      <div className="loader--grid">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</section>
</div>
  )
}
