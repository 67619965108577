import React from 'react';
import { useSelector } from 'react-redux';

export default function DummyComponent() {

  const isAuthenticated = useSelector((state) => state.loginSlice.isAuthenticated);
  const user = useSelector((state) => state.loginSlice.user);
  const token = useSelector((state) => state.loginSlice.token);

  return (
    <div>DummyComponent</div>
  )
}
