import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import api from '../../../store/api';
import FeedbackModal from '../../Utilities/FeedbackModal';
import BrandSearchComponent from '../../Utilities/BrandSearchComponent';
import UserSearchComponent from '../../Utilities/UserSearchComponent';
import ProfileImageInput from '../../Utilities/ProfileImageInput';

export default function CreateProject() {

    const token = useSelector((state) => state.loginSlice.token);
    const [successfullSubmission, setSuccessfullSubmission] = useState(false)

    const [projectData, setProjectData] = useState(
        {
          projectName: '',
          projectBrand: '0',
          projectDescription: '',
          projectManager: '0',
          projectImage: null
        }
      )
    
      const handleValidate = (data) => {
        if (data.projectName.length <= 0) {
          setErrors({...errors, projectNameError: 'The name of the project cannot be empty.'})
          return false
        }
        if (data.projectBrand.toString() === '0') {
            setErrors({...errors, projectClientError: 'You need to select the brand this project belongs to.'})
            return false
        }
        if (data.projectManager.toString() === '0') {
            setErrors({...errors, projectClientError: 'You need to select the project manager of this brand.'})
            return false
        }
        else {
          setErrors({...errors, projectNameError: ''})
        }
    
        return true
      }
      
      const [res, setRes] = useState({
        message: "",
        severity: "success",
        show: false
      })

      const [loading, setLoading] = useState(false)
    
      const handleChange = (evt) => {
        evt.preventDefault()
        if (evt.target)
          setProjectData({...projectData, [evt.target.name]: evt.target.value})
      }

      const handleSubmit = (evt) => {
        evt.preventDefault()
        if (handleValidate(projectData)) {
          const formData = new FormData()
          formData.append('projectName', projectData.projectName)
          formData.append('projectBrand', projectData.projectBrand)
          formData.append('projectDescription', projectData.projectDescription)
          formData.append('projectManager', projectData.projectManager)
          formData.append('projectImage', projectData.projectImage)
          
          api.post('/api/projects/', projectData,
                {headers: {'Content-Type': 'multipart/form-data', Authorization: `Token ${token}`}})
    
          .then((response) => {
            const message = response.data.message
            setRes({
              message: message,
              severity: "success",
              show: true
            })
            setProjectData({
                projectName: '',
                projectBrand: '0',
                projectDescription: '',
                projectManager: '0',
                projectImge: null
            })
            setErrors({
                projectNameError: '',
                projectBrandError: '',
                projectDescriptionError: '',
                projectManagerError: '',
                projectImageError: ''
            })
            setSuccessfullSubmission(true)
          })
    
          .catch((error) => {
            setRes({
              message: error.response.data.error,
              severity: "error",
              show: true
            })
          })
        }
      }
    
    const [errors, setErrors] = useState({
        projectNameError: '',
        projectBrandError: '',
        projectDescriptionError: '',
        projectManagerError: ''
    })

  return ( <>
    <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
			setRes({
				message: "",
				severity: "success",
				show: false
			})
    }}/><div id="kt_content_container" className="container-xxl">
    <form className="form">
        <div className="card">
            <div className="card-header"><div className="card-title fs-4 fwt-bolder">Create Project</div></div>
            <div className="card-body" style={{maxWidth: "80%", margin: "0px auto", minWidth: "70%"}}>
                <div className="py-10 px-lg-17">
                    <div
                        className="scroll-y me-n7 pe-7"
                        id="kt_modal_new_address_scroll"
                        data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}"
                        data-kt-scroll-max-height="auto"
                        data-kt-scroll-dependencies="#kt_modal_new_address_header"
                        data-kt-scroll-wrappers="#kt_modal_new_address_scroll"
                        data-kt-scroll-offset="300px"
                        style={{maxHeight: "476px"}}
                    >
                      
                        <div className="row mb-3">
                        <div className="col-md-6 fv-row fv-plugins-icon-container">
                          {/*begin::Label*/}
                          <label className="required fs-6 fwt-bold mb-2">Project Image</label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <ProfileImageInput setImage={(image) => {
                              setProjectData({...projectData, projectImage: image})
                          }} edit={false} image={projectData.projectImage} 
                          SuccessfullSumbission={successfullSubmission} />
                        </div>
                            <div className="col-md-12 fv-row fv-plugins-icon-container">
                                <label className="required fs-6 fwt-bold mb-2">Project Name</label>
                                <input type="text" value={projectData.projectName} onChange={handleChange} name="projectName" className="form-control form-control-solid" placeholder="" />
                                <div className="fv-plugins-message-container invalid-feedback"><div data-field="account_name" data-validator="notEmpty">{errors.projectNameError}</div></div>
                            </div>
                        </div>
                    </div>

                    <UserSearchComponent returnResults = {(id) => {
                        setProjectData({...projectData, projectManager: id})
                    }} successfullSubmission={successfullSubmission} userType={'staff'} inputName={'Project Manager'}/>
                    <div className="fv-plugins-message-container invalid-feedback">{errors.projectManagerError}</div>
                    <br></br>
                    <BrandSearchComponent returnResults = {(id) => {
                        setProjectData({...projectData, projectBrand: id})
                    }} successfullSubmission={successfullSubmission} style={{marginTop: '20px'}}/>
                    <div className="fv-plugins-message-container invalid-feedback">{errors.projectBrandError}</div>

                    <div className="d-flex flex-column mb-3 fv-row fv-plugins-icon-container">
                        <label className="required fs-6 fwt-bold mb-2">Description</label>
                        <textarea value={projectData.projectDescription} className="form-control form-control-solid" onChange={handleChange} name="projectDescription" rows="7" cols="30"></textarea>
                        <div className="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                </div>
            </div>
            <div className="card-footer d-flex justify-content-end py-6">
                <button type="submit" className="btn btn-beysix" onClick={handleSubmit}>Create Project</button></div>
        </div>
    </form>
</div>
</>
  )
}
