import React, {useEffect, useState} from 'react'
import api from '../../../store/api';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export default function AssetHistory() {

    const {organization, token, user} = useSelector((state) => {
        return {
          'organization': state.loginSlice.organization, 
          'token': state.loginSlice.token,
          'user': state.loginSlice.user
        }
    });

    const { id } = useParams() 
    const [assetHistory, setAssetHistory] = useState([])
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    function revealTimelineItems() {
        var items = document.querySelectorAll('.timeline-item');
        items.forEach(function(item) {
            var position = item.getBoundingClientRect().top;
            var windowHeight = window.innerHeight;
            if (position < windowHeight - 100) {
                item.classList.add('visible');
            }
        });
    }

    const getAssetHistory = () => {
        let url = `/api/assets/asset-history/${id}/`
        if (user.user_type === 'client') {
            url = `/api/assets/asset-history/${id}/${organization.id}/`
        }
        api.get(url,
        {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
        .then((response) => {
            setAssetHistory(response.data.data)
        })
        .catch((error) => {
            throw new Error(error)
        })
    }

    useEffect(() => {
        revealTimelineItems()
        window.addEventListener('scroll', revealTimelineItems);
        getAssetHistory()
    }, [])

  return (
  
    <div>
        {assetHistory.length === 0 ?
        <center>No asset histories found.</center> :
        <>
            <div class="timeline-container">
                <div class="timeline-container">
                {assetHistory && assetHistory.map((history) => <div class="timeline-item left">
                    <div class="timeline-date">{history.activation.event_date}</div>
                    <div class="timeline-content">
                        <img src={history.asset_brand.image} alt="Avatar 1" class="timeline-avatar" />
                        <div>
                            <h2 class="timeline-title">{history.activation.name}</h2>
                            <p class="timeline-description">
                                {history.dispatch_asset_media && <img 
                                    src={history.dispatch_asset_media[0].media_path} 
                                    width="340" 
                                    height="260" 
                                    class="enlarge-on-hover" 
                                    onClick={() => {
                                        setPhotoIndex(0);
                                        setIsOpen(true);
                                    }}
                                    alt={history.activation.name}
                                />}
                                <br />
                                {isOpen && (
                                <Lightbox
                                    mainSrc={history.dispatch_asset_media[photoIndex].media_path}
                                    nextSrc={history.dispatch_asset_media[(photoIndex + 1) % history.dispatch_asset_media.length].media_path}
                                    prevSrc={history.dispatch_asset_media[(photoIndex + history.dispatch_asset_media.length - 1) % history.dispatch_asset_media.length].media_path}
                                    onCloseRequest={() => setIsOpen(false)}
                                    onMovePrevRequest={() =>
                                        setPhotoIndex((photoIndex + history.dispatch_asset_media.length - 1) % history.dispatch_asset_media.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setPhotoIndex((photoIndex + 1) % history.dispatch_asset_media.length)
                                    }
                                />
                            )}
                                {history.activation.description}
                            </p>
                            {history.is_dispatch && <span className='badge badge-primary'>Dispatch Operation</span>}
                            <div class="d-flex flex-wrap ftw-bold fs-6 mb-4 pe-2 mt-2">
                                <span class="d-flex align-items-center text-gray-400 text-hover-primary me-3 mb-2">
                                    <span class="svg-icon svg-icon-2x me-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M16.0077 19.2901L12.9293 17.5311C12.3487 17.1993 11.6407 17.1796 11.0426 17.4787L6.89443 19.5528C5.56462 20.2177 4 19.2507 4 17.7639V5C4 3.89543 4.89543 3 6 3H17C18.1046 3 19 3.89543 19 5V17.5536C19 19.0893 17.341 20.052 16.0077 19.2901Z"
                                                fill="black"
                                            ></path>
                                        </svg>
                                    </span>
                                    {history.asset_brand.name}
                                </span>
                                <a class="d-flex align-items-center ftw-bold text-gray-400 text-hover-primary me-3 mb-2">
                                    <span class="svg-icon svg-icon-2x me-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                            <path
                                                opacity="0.3"
                                                d="M8.9 21L7.19999 22.6999C6.79999 23.0999 6.2 23.0999 5.8 22.6999L4.1 21H8.9ZM4 16.0999L2.3 17.8C1.9 18.2 1.9 18.7999 2.3 19.1999L4 20.9V16.0999ZM19.3 9.1999L15.8 5.6999C15.4 5.2999 14.8 5.2999 14.4 5.6999L9 11.0999V21L19.3 10.6999C19.7 10.2999 19.7 9.5999 19.3 9.1999Z"
                                                fill="black"
                                            ></path>
                                            <path
                                                d="M21 15V20C21 20.6 20.6 21 20 21H11.8L18.8 14H20C20.6 14 21 14.4 21 15ZM10 21V4C10 3.4 9.6 3 9 3H4C3.4 3 3 3.4 3 4V21C3 21.6 3.4 22 4 22H9C9.6 22 10 21.6 10 21ZM7.5 18.5C7.5 19.1 7.1 19.5 6.5 19.5C5.9 19.5 5.5 19.1 5.5 18.5C5.5 17.9 5.9 17.5 6.5 17.5C7.1 17.5 7.5 17.9 7.5 18.5Z"
                                                fill="black"
                                            ></path>
                                        </svg>
                                    </span>
                                    {history.asset_project.name}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>)}
            </div>
            </div>
            </>}
        </div>
  )
}
