import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import RoleComponent from './RoleComponent'
import api from '../../../store/api';
import AddRole from './AddRole';
import UpdateRole from './UpdateRole';
import MainModal from '../../Modals/MainModal'
import FeedbackModal from '../../Utilities/FeedbackModal'
import parseTimestamp from '../../Utilities/parseTimeStamp';

export default function RolesList() {
    const token = useSelector((state) => state.loginSlice.token);
    const [roles, setRoles] = useState([])
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
    const [groupedPermissions, setGroupedPermissions] = useState({})
    const [currentRole, setCurrentRole] = useState(null)
    const [res, setRes] = useState({
		message: "",
		severity: "success",
		show: false
	})
    const permissions = useSelector((state) => state.loginSlice.permissions);
    const getRoles = async () => {
        const response = await api.get('/api/users/groups/', 
            {
                headers: {
					'Content-Type': 'application/json',
					 Authorization: `Token ${token}`,
				},
            }
        );
        setRoles(response.data.data);
    }

    const getGroupedPermissions = async () => {
        const response = await api.get('/api/users/get-grouped-permissions/',
                                {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        Authorization: `Token ${token}`,
                                    },
                                });
        setGroupedPermissions(response.data['grouped_permissions']) 
    }

    const handleUpdateModal = (isOpen) => {
        setIsUpdateModalOpen(isOpen)
    }

    const handleAddRole = (role) => {
        const copied_roles = [...roles]
        copied_roles.push(role)
        setRoles(copied_roles)
    }

    const handleModalClose = () => {
        setCurrentRole(null)
        setIsUpdateModalOpen(false)
    }

    useEffect(() => {
        getRoles();        
        getGroupedPermissions();
    }, [])

  return (
    <>
    <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
        setRes({
            message: "",
            severity: "success",
            show: false
        })
    }}/>
    
    <MainModal noPadding={true} handleClose={() => handleModalClose()} show={isUpdateModalOpen} modalTitle={currentRole ? `Update the ${currentRole.name} role` : 'Add Role'}>
        { currentRole !== null ? 
            <UpdateRole role={currentRole} 
            isOpen={isUpdateModalOpen} 
            role_id = {currentRole.id} 
            groupedPermissions={groupedPermissions}
            handleSuccess={(res) => {
                setRes(res)
                handleModalClose()
            }}
             /> 
        :   <AddRole 
            groupedPermissions={groupedPermissions} 
            addRole={handleAddRole}
            handleClose={() =>  handleModalClose()}
            handleSuccess={(res) => {
                setRes(res)
                handleModalClose()
            }}
            isOpen={isUpdateModalOpen}
            returnResponse={
                (data) => {
                    setRes({
                            message: data.message,
                            severity: data.severity,
                            show: true
                    })
                }
            }
            />
         }
    </MainModal>

    <div className="row">
        {
            roles.map((Role, index) => {
                return (
                    <div className="col-md-4 mt-3" key={index}>
                        <RoleComponent role={Role}  handleUpdateModal={handleUpdateModal} setCurrRole={(role) => setCurrentRole(role)}/>
                    </div>
                )
            })
        }
        {permissions["add_group"] &&
        <div className="col-md-4 mt-3">
            {/*begin::Card*/}
            <div className="card h-md-100">
                {/*begin::Card body*/}
                <div className="card-body d-flex flex-center">
                    {/*begin::Button*/}
                    <button type="button" className="btn btn-clear d-flex flex-column flex-center" onClick={() => setIsUpdateModalOpen(true)}>
                        {/*begin::Illustration*/}
                        <img src="/static/assets/media/illustrations/sketchy-1/4.png" alt="" className="mw-100 mh-150px mb-7" />
                        {/*end::Illustration*/}
                        {/*begin::Label*/}
                        <div className="fw-bolder fs-5 text-gray-600 text-hover-primary">Add New Role</div>
                        {/*end::Label*/}
                    </button>
                    {/*begin::Button*/}
                </div>
                {/*begin::Card body*/}
            </div>
        </div>}
    </div>
    </>
  )
}
