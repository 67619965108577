import React, {useEffect} from 'react';
import Aside from '../Aside/Aside'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ActivitiesDrawer from '../LayoutComponents/ActivitiesDrawer';
import ChatsDrawer from '../LayoutComponents/ChatsDrawer';
import CreateAssetModal from '../LayoutComponents/CreateAssetModal';
import Toolbar from '../Toolbar/Toolbar';
import loadScripts from '../Utilities/loadScripts';

export default function Layout({children}) {
  

  useEffect(() => {
    const scripts = loadScripts();

    // Cleanup function
    return () => {
      for (let key in scripts)
        document.body.removeChild(scripts[key]); 
    };
  }, [])

  return (
    <>
     <Aside />
     <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
      <Header/>
      <div style={{minHeight: "85vh"}} className="content d-flex flex-column flex-column-fluid" id="kt_content" data-select2-id="select2-data-kt_content">
        {/* <Toolbar /> */}
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-xxl">
            {children}
          </div>
        </div>
      </div>
      <Footer />
     </div>    
     <ActivitiesDrawer />
     <ChatsDrawer/>
     <CreateAssetModal /> 
     </>
  )
}
