import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import RestrictedModal from './RestrictedModal';

const withAuthorization = (WrappedComponent, requiredPermission) => {
  return (props) => {
    const user = useSelector((state) => state.loginSlice.user);
    const isAuthenticated = useSelector((state) => state.loginSlice.isAuthenticated);
    const permissions = useSelector((state) => state.loginSlice.permissions);
    const navigate = useNavigate();
    const { id } = useParams();
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
      if (!isAuthenticated) {
        navigate('/auth/login');
      } else if (user && requiredPermission !== 'view_user_profile' && !permissions[requiredPermission]) {
        setModalOpen(true);
      }
    }, [isAuthenticated, user, requiredPermission, navigate, permissions]);

    const closeModal = () => setModalOpen(false);

    if (!isAuthenticated) {
      return null;
    }

    if (requiredPermission === 'view_user_profile') {
      if (user.id === parseInt(id)) {
        return <WrappedComponent {...props} />;
      } 
    } else if (!permissions[requiredPermission]) {
      return <RestrictedModal />;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAuthorization;
