import React,  { useState, useEffect }  from 'react'
import { useSelector } from 'react-redux'
import api from '../../../store/api'
import ProjectTable from '../ProjectTable/ProjectTable';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function ProjectList() {
    const permissions = useSelector((state) => state.loginSlice.permissions);
    const [projects, setProjects] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [ogProjects, setOgProjects] = useState([])
    const [pageLoading, setPageLoading] = useState(false)
    const [resultsFound, setResultsFound] = useState(true)
    const [count, setCount] = useState(0)

    const {organization, token, user} = useSelector((state) => {
      return {
        'organization': state.loginSlice.organization, 
        'token': state.loginSlice.token,
        'user': state.loginSlice.user
      }
    }); //

    const handleSearch = (evt) => {
      evt.preventDefault()
      const value = evt.target.value
      setSearchQuery(value)
      if (value.length > 2) {
        setPageLoading(true)
        let url = `/api/projects/detailed-search-project/`
        if (user.user_type === 'client') {
          url = `/api/projects/detailed-search-project/${organization.id}/`
        }
          api.post(url, {searchQuery: value},
              {headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Token ${token}`,
                  },
              })
              .then((response) => {
                  if (response.status === 200) {
                      if (response.data.results.length > 0){
                          setProjects(response.data.results)
                          setResultsFound(true)
                      } else {
                          setProjects(response.data.results)
                          setResultsFound(false)
                      }
                    setPageLoading(false)
                  }
              })
              .catch((error) => {
                  if (response.status === 404) {
  
                  } else {
                      throw new Error(error)
                  }
                  setPageLoading(true)
              })
        } else {
            setProjects(ogProjects)
            if (ogProjects.length > 0)
                setResultsFound(true)
        }
    }

    useEffect(() => {
      setPageLoading(true)
        let url = '/api/projects/'
        if (user.user_type === 'client') {
          url = `/api/projects/organization-projects/${organization.id}/`
        }

        api.get(url,
        {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
        .then((response) => {
          if (response.data.results.length > 0){
            setProjects(response.data.results)
            setOgProjects(response.data.results)
            setPageLoading(false)
            setResultsFound(true)
            setCount(response.data.count)
          } else {
            setPageLoading(false)
            setResultsFound(true)
            setProjects(response.data.results)
          }
        })
        .catch((error) => {
            throw new Error('Error getting data.')
        })
    }, [])

  return (
  <div id="kt_content_container" className="container-xxl">
    {!pageLoading && <motion.p 
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.3 }}style={{fontWeight: '600'}}>{count} Projects Total</motion.p>}
      <div className="card mb-5 mb-xl-8">
        <div></div>
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <div className="d-flex align-items-center position-relative my-1">
              <span className="svg-icon svg-icon-1 position-absolute ms-6">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect><path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path></svg>
              </span>
              <input onChange={handleSearch} 
                type="text" 
                className="form-control form-control-solid w-250px ps-14" 
                placeholder="Search Project" />
              </div>
            </h3>
            <div className="card-toolbar">
                {permissions['add_project'] &&
                <Link className="btn btn-sm btn-light-primary" to="/project-management/create-project">
                    <span className="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="black"></rect>
                            <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="black"></rect>
                        </svg>
                    </span>
                    Create Project
                </Link>}
            </div>
        </div>
        <div className="card-body py-3"></div>
    <ProjectTable projectData={projects} loading={pageLoading} resultsFound={resultsFound} />
    </div>
    </div>
  )
}
