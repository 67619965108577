export default function passwordStrengthValidator(password) {
    const letterRegex = /[a-z]/;
    const capitalLetterRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;
    const symbolRegex = /[^a-zA-Z0-9]/;

    let strength = 0;
    let feedback = "";

    
    if (password.match(/(?=.*[0-9])/)) {
        strength += 1;
    } else {
        feedback = "Password must contain at least one number.";
    }

    if (password.match(/(?=.*[!,%,&,@,#,$,^,*,?,_,~,<,>,])/)) {
        strength += 1;
    } else {
        feedback = "Password must contain at least one special character.";
    }

    if (password.match(/(?=.*[a-z])/) && password.match(/(?=.*[A-Z])/)) {
        strength += 1;
    } else {
        feedback = "Password must contain both lowercase and uppercase letters.";
    }

    if (password.length >= 8) {
        strength += 1;
    } else {
        feedback = "Password must be at least 8 characters long.";
    }

    const divs = document.querySelectorAll('[data-kt-password-meter-control="highlight"] > div');
    divs.forEach((div, index) => {
        if (index < strength) {
            div.classList.add('active');
        } else {
            div.classList.remove('active');
        }
    });

return { strong: strength === 4, feedback };
}
