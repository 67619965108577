import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import api from '../../store/api';
import { useSelector } from 'react-redux';
import FeedbackModal from '../Utilities/FeedbackModal';


export default function BulkUpload({setTools}) {

    const [file, setFile] = useState(null);
    const [isValidFile, setIsValidFile] = useState(false);
    const [fileName, setFileName] = useState('');
    const [fileSize, setFileSize] = useState('');
    const [loading, setLoading] = useState(false)
    const [res, setRes] = useState({
        message: "",
        severity: "success",
        show: false
    })

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel') {
        setIsValidFile(true);
        setFile(file);
        setFileName(file.name);
        setFileSize(file.size);
      } else {
        setIsValidFile(false);
        setRes({
            message: 'Please upload a valid Excel file.',
            severity: "error",
            show: true
        })
      }
    }
  }, []);

  
  const token = useSelector((state) => state.loginSlice.token);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: ['.xlsx', '.xls'],
  });

  const handleFileUpload = (evt) => {
    evt.preventDefault()
    if (isValidFile && file) {
      setLoading(true)
      const data = new FormData();
      data.append('file', file);
      api.post(`/api/tools/bulk-upload/`, data,
        {headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Token ${token}`,
            },
        })
        .then((response) => {
            setRes({
                message: response.data.message,
                severity: 'success',
                show: true
            })
            setTools(response.data.tools)
            setFile(null);
            setIsValidFile(false);
            setFileName('');
            setFileSize('');
            setLoading(false)
        })
        .catch((error) => {
            setRes({
                message: error.response.data.error,
                severity: 'error',
                show: true
            })
            if (error.response.data.status === 'error' && error.response.data.errorFileUrl) {
                window.open(error.response.data.errorFileUrl, '_blank');
            }
            setLoading(false)
        })
    }
  };

  return (
    <>
        <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
			setRes({
				message: "",
				severity: "success",
				show: false
			})
		}}/>
        <div className="d-flex flex-column flex-start flex-column-fluid mb-10">
            <p className='fw-bold'>Example Screenshot:</p>
            <img src="/static/assets/media/bulk-upload-instructions/tool.png" alt="" className="mw-100" />
        </div>
        <div className="d-flex flex-column flex-start flex-column-fluid mb-10">
            <p className='fw-bold'>Instructions:</p>
            <div className="d-flex flex-column">
                <li className="d-flex align-items-center py-2">
                    <span className="bullet me-3"></span> <span>Make sure there is a column called <b>(toolName)</b> in the first row. This field is required and is supposed to be unique in each row.</span>
                </li>
                <li className="d-flex align-items-center py-2">
                    <span className="bullet me-3"></span> <span>Make sure there is a column called <b>(toolDescription)</b> in the first row. This field is not required and is not required to be unique in each row.</span>
                </li>
            </div>
        </div>
        <form className="form" action="#" method="post">
        <div className="fv-row">
            {isValidFile ? (
                <>
                    <p className='fw-bold'>Uploaded Excel File:</p>
                    <div className='d-flex' style={{justifyContent: 'flex-start', alignItems: 'center'}}>
                        <div style={{width: '100px', height: '100px', marginRight: '10px'}}>
                            <img src="/static/assets/media/excel-logo/1.png" alt="" className="mw-100" />
                        </div>
                        <div>
                            <p className='mb-0 ftw-bold'>File Name: {fileName}</p>
                            <p className='mb-0 ftw-bold'>File Size: {fileSize} kb</p>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button type="submit" onClick={handleFileUpload} className="btn btn-beysix" disabled={!loading ? false : true}>
                            {!loading ? <span className="indicator-label">Submit</span>
                            : <span className="indicator-progress">Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
                        </button>
                    </div>
                </>
            ): (
                <div {...getRootProps({ className: 'dropzone dz-clickable' })}  id="kt_dropzonejs_example_1">
                <input {...getInputProps()} />
                <div className="dz-message needsclick">
                    <i className="bi bi-file-earmark-arrow-up text-primary-beysix fs-3x"></i>
                    <div className="ms-4">
                        <h3 className="fs-5 ftw-bolder text-gray-900 mb-1">Drop the excel file here or click to upload.</h3>
                        <span className="fs-7 fw-bold opacity-75">Upload 1 file</span>
                    </div>
                </div>
            </div>)}
        </div>
    </form>
    </>
  )
}
