// src/SkeletonLoader.js
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './SkeletonLoader.css'; // We'll define some styles here

const SkeletonLoader = () => {
  const rows = Array(7).fill(0); // You can change the number of rows as needed

  return (
    <div className="table-responsive">
      <table className="skeleton-table">
        <thead>
          <tr>
            <th style={{width: '100px'}}><Skeleton width={100} height={15} /></th>
            <th><Skeleton width={100} height={15} /></th>
            <th><Skeleton width={100} height={15} /></th>
            <th><Skeleton width={150} height={15} /></th>
            <th><Skeleton width={100} height={15} /></th>
          </tr>
        </thead>
        <tbody>
          {rows.map((_, index) => (
            <tr key={index}>
              <td className="d-flex">
                <Skeleton height={50} width={50} style={{ borderRadius: '10px', marginRight: '20px' }} />
                <Skeleton height={30} width={180} />
              </td>
              <td><Skeleton height={20} /></td>
              <td><Skeleton height={20} /></td>
              <td><Skeleton height={20} /></td>
              <td><Skeleton height={20} /></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SkeletonLoader;
