import React, {useEffect, useState} from 'react'
import {Link, useParams, useNavigate} from 'react-router-dom'
import api from '../../../store/api'
import FeedbackModal from '../../Utilities/FeedbackModal'
import LoaderGrid from '../../Utilities/LoaderGrid/LoaderGrid'
import passwordStrengthValidator from '../../Utilities/passwordStrengthValidator'

export default function ResetPassword() {

    const { param1, param2, param3 } = useParams()
    const [loading, setLoading] = useState(false)
    const [pageLoading, setPageLoading] = useState(true)
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [errorMessage, setErrorMessage] = useState('')
    const [token, setToken] = useState('')
    const [userId, setUserId] = useState('')
    const [fpk, setFpk] = useState('')
    const [passwordStrength, setPasswordStrength] = useState(false)
    const [err, setErr] = useState('')
    const [resetDone, setResetDone] = useState(false)
    const navigate = useNavigate()
    const [res, setRes] = useState({
        message: "",
        severity: "success",
        show: false
    })
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    const getResetForm = () => {
        api.get(`/api/users/reset-password/${param1}/${param2}/${param3}`)
        .then((response) => {
            if (response.status === 200) {
                setToken(response.data.token)
                setUserId(response.data.id)
                setFpk(response.data.fpk)
                const isResetDone = response.data.reset_done
                if (isResetDone) {
                    setResetDone(true)
                    const rootDiv = document.querySelector('.d-flex.flex-column.flex-column-fluid.bgi-position-y-bottom.position-x-center.bgi-no-repeat.bgi-size-contain.bgi-attachment-fixed')
                    rootDiv.style.backgroundImage = "none"
                }

                setPageLoading(false)
            }
        })
        .catch((error) => {
            if (error.response.status === 404)
                navigate(error.response.data.url)
            else
                setRes({ message: error.response.data.error, severity: "error", show: true })
        })
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        const {strong, feedback} = passwordStrengthValidator(e.target.value)
        if (!strong) {
            setErr(feedback)
            setPasswordStrength(true)
        } else {
            setErr('')
            setPasswordStrength(false)
        }
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const {strong, feedback} = passwordStrengthValidator(password)
        if (password === confirmPassword && strong) {
            setLoading(true)
            const baseURL = `/api/users/update-password/${userId}/`;
            api.put(baseURL, {
                new_password: password,
                token_key: token,
                fpk: fpk
            }, 
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
            })
            .then((response) => {
                navigate(response.data.url)
            })
            .catch((error) => {
                setLoading(false)
                setRes({ message: error.response.data.error, severity: "error", show: true })
                throw new Error(error)
            })
        } else {
            if (password !== confirmPassword) {
                setPasswordsMatch(false);
                setErrorMessage("Passwords do not match.")
            } else {
                setPasswordsMatch(true);
                setErrorMessage("")
            }

            if (!strong) {
                setPasswordStrength(true)
                setErr(feedback)
            } else {
                setPasswordStrength(false)
                setErr('')
            }
        }
    };

    useEffect(() => {
        getResetForm() 
    }, [])

  return (
    <div>
    <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
        setRes({
            message: "",
            severity: "success",
            show: false
        })
    }}/>
    {!pageLoading ? 
    resetDone === false ?
    <div className="w-lg-550px bg-body rounded shadow-sm-sm p-10 p-lg-15 mx-auto">
        {/*begin::Form*/}
        <form className="form w-100" noValidate id="kt_new_password_form"  onSubmit={handleSubmit}>
            {/*begin::Heading*/}
            <div className="text-center mb-10">
                {/*begin::Title*/}
                <h1 className="text-dark mb-3">Setup New Password</h1>
                {/*end::Title*/}
                {/*begin::Link*/}
                <div className="text-gray-400 fwt-bold fs-5">Already have reset your password?
                <Link to="/auth/login" className="link-primary fwt-bolder" style = {{marginLeft: "5px"}}>Sign in here</Link></div>
                {/*end::Link*/}
            </div>
            {/*begin::Heading*/}
            {/*begin::Input group*/}
            <div className="mb-10 fv-row" data-kt-password-meter="true">
                {/*begin::Wrapper*/}
                <div className="mb-1">
                    {/*begin::Label*/}
                    <label className="form-label fwt-bolder text-dark fs-6">Password</label>
                    {/*end::Label*/}
                    {/*begin::Input wrapper*/}
                    <div className="position-relative mb-3">
                        <input className="form-control form-control-lg form-control-solid" type={showPassword ? 'text' : 'password'} placeholder="" name="password" autoComplete="off" onChange={handlePasswordChange}/>
                        <span onClick={togglePasswordVisibility} className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                            {!showPassword ? <i className="bi bi-eye-slash fs-4"></i> : <i className="bi bi-eye fs-4"></i>}
                        </span>
                    </div>
                    {/*end::Input wrapper*/}
                    {/*begin::Meter*/}
                    <div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
                    <div className="flex-grow-1 bg-secondary2 bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-secondary2 bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-secondary2 bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-secondary2 bg-active-success rounded h-5px"></div>
                    </div>
                    {/*end::Meter*/}
                </div>
                {/*end::Wrapper*/}
                {/*begin::Hint*/}
                {passwordStrength ? <div className="fv-plugins-message-container invalid-feedback"><div data-field="password" data-validator="password">{err}</div></div> : <div className="text-muted">Use 8 or more characters with a mix of letters, numbers &amp; symbols.</div>}
                {/*end::Hint*/}
            </div>
            {/*end::Input group=*/}
            {/*begin::Input group=*/}
            <div className="fv-row mb-10">
                <label className="form-label fwt-bolder text-dark fs-6">Confirm Password</label>
                <input className="form-control form-control-lg form-control-solid" type="password" placeholder="" name="confirm-password" autoComplete="off" onChange={handleConfirmPasswordChange}/>
            </div>
            {!passwordsMatch && <div className="fv-plugins-message-container invalid-feedback"><div data-field="password" data-validator="password">{errorMessage}</div></div>}
            {/*end::Input group=*/}
            {/*end::Input group=*/}
            {/*begin::Action*/}
            <div className="text-center">
                <button type="submit" disabled={loading ? true : false} id="kt_new_password_submit" className="btn btn-lg btn-beysix fwt-bolder">
                    {!loading && <span className="indicator-label">Submit</span>}
                    {loading && <span className="indicator-progress">Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
                </button>
            </div>
            {/*end::Action*/}
        </form>
        {/*end::Form*/}
    </div> : 
    <div style = {{
        maxWidth: '71vw',
        textAlign: 'center'
    }}>
    <div className="pt-lg-10 mb-10">
        {/*begin::Logo*/}
        <h1 className="fw-bolder fs-3 text-gray-800 mb-7">Password Reset Link Unavailable</h1>
        {/*end::Logo*/}
        {/*begin::Message*/}
        <div className="fwt-bold fs-5 text-muted mb-15" style={{
            maxWidth: "80%",
            margin: "0 auto"
        }}>You already used this link to reset your password before, if you need to reset your password again go to <Link to = '/auth/forgot-password'>Forgot Password</Link> or use the button below to login to your account.</div>
        {/*end::Message*/}
        {/*begin::Action*/}
        <div className="text-center">
            <Link to="/auth/login" className="btn btn-lg btn-beysix fwt-bolder">Go to login</Link>
        </div>
        {/*end::Action*/}
    </div>
    <div  style={{marginTop: "70px",
					backgroundImage: "url('/static/assets/media/illustrations/beysix-1/invalid-link.svg')",
					width: "100%",
					height: "40vh",
					backgroundSize: "contain",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat"
				}}></div>
    </div>
    : 
        <LoaderGrid />
    }
    </div>
  )
}
