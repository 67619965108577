import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import api from '../../../store/api'

export default function ForgotPasswordEmail() {
	const [maskedEmail, setMaskedEmail] = useState('')
	const { id } = useParams()
	const navigate = useNavigate()
	const [timeLeft, setTimeLeft] = useState(30);
	const [showResendOption, setShowResendOption] = useState(false)
			
	const GetMaskedEmail = async () => {
		api.post(`/api/users/forgot-password-email/`, {
            "id": id
        })
        .then(response => {
            if (response.status === 200) {
                setMaskedEmail(response.data.masked_email);
				setShowResendOption(response.data.show_resend_option)
            } 
        })
        .catch(error => {
			const err = error.response
			if (err.status === 404)  {
				navigate(err.data.url);
				return;
			}
			throw new Error(err.data.error)
        });
	}

	useEffect(() => {
		GetMaskedEmail();
	}, []);

	
	useEffect(() => {
		if (showResendOption === false) {
			if (timeLeft === 0) {
				setShowResendOption(true)
				update_resend_option(true)
				return;
			}

			const countdownInterval = setInterval(() => {
				setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
			}, 1000);
			return () => clearInterval(countdownInterval);
		}
	}, [timeLeft, showResendOption]);

	const update_resend_option = (update) => {
		api.post(`/api/users/update-resend-btn/`, {
            "id": id, 'show_resend_btn': update, 'resend_type': 'forgot_password'
        })
	}

	
	const handleResendResetPasswordEmail = (evt) => {
		evt.preventDefault();
		api.post(`/api/users/resend-email/`, {
            "show_resend_option": !showResendOption,
			"id": id,
			"resend_type": "forgot_password"
        })
        .then(response => {
            if (response.status === 200) {
				setTimeLeft(30)
                setShowResendOption(response.data.show_resend_option)
            } 
        })
        .catch(error => {
			const err = error.response
			throw new Error(err.data.error)
        });
	}

  return (
    <div className="pt-lg-10 mb-10 text-center">
		{/*begin::Logo*/}
		<h1 className="fwt-bolder fs-3 text-gray-800 mb-7 text-center">Forgot Password Email Reset Link</h1>
		{/*end::Logo*/}
		{/*begin::Message*/}
		<div className="fs-5 fwt-bold text-muted mb-10">We sent an email to
		<Link to="" className="link-primary fwt-bolder" style={{marginLeft: "5px"}}>{maskedEmail}</Link>
		<br />please follow the link on your email to reset your password.</div>
		{/*end::Message*/}
		{/*begin::Action*/}
		{/* <div className="text-center mb-10">
			<a href="../../demo1/dist/authentication/flows/basic/sign-in.html" className="btn btn-lg btn-beysix fwt-bolder">Skip for now</a>
		</div> */}
		{/*end::Action*/}
		{/*begin::Action*/}
		<div className="fs-6 text-center">
			<span className="fwt-bold text-gray-700">Did’t receive an email?</span>
			{showResendOption === false ? <p>Resend email in {timeLeft} seconds.</p>
			:  <a  onClick={handleResendResetPasswordEmail} className="link-primary fwt-bolder" style={{marginLeft: "5px"}}>Resend</a>
			}	
		</div>
		
	<div  style={{marginTop: "70px",
					backgroundImage: "url('/static/assets/media/illustrations/beysix-1/email-verification.svg')",
					width: "70vw",
					height: "40vh",
					backgroundSize: "contain",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat"
				}}></div>
		{/*end::Action*/}
	</div>
  )
}
