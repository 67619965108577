import React, {useEffect} from 'react'

export default function NoResults({ type }) {

    useEffect(() => {}, [])
  return (
    <div className="content-center" style={{maxWidth: '50vw', background: '#fefefe', padding: '20px', borderRadius: '5px', margin: '0 auto'}}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2556.55 1587.44">
          <g id="a" />
          <g id="b">
              <g id="c">
                  <g>
                      <path
                          className="d"
                          d="M2516.8,884.89c-25.47-60.22-61.93-114.3-108.36-160.73-46.43-46.43-100.51-82.89-160.73-108.36-50.28-21.27-103.07-34.08-157.41-38.25-3.9-38.04-13.38-75.02-28.34-110.39-19.46-46-47.3-87.3-82.76-122.76-35.46-35.46-76.76-63.3-122.76-82.76-47.65-20.15-98.23-30.37-150.36-30.37-61.62,0-121.65,14.51-175.82,42.26-51.53-76.67-119.92-141.27-199.7-188.31C1236,29.47,1127.7,0,1017.36,0c-83.35,0-164.22,16.33-240.38,48.54-73.54,31.1-139.57,75.62-196.27,132.32-56.7,56.7-101.22,122.73-132.32,196.27-28.27,66.83-44.3,137.29-47.81,209.87-106.13,22.49-202.77,78.92-275.06,161.27-39.63,45.15-70.6,96.36-92.05,152.21C11.26,958.31,0,1019.29,0,1081.74c0,68.25,13.38,134.48,39.76,196.85,25.47,60.22,61.93,114.3,108.36,160.73,46.43,46.43,100.51,82.89,160.73,108.36,62.37,26.38,128.6,39.76,196.85,39.76h1545.16c68.25,0,134.48-13.38,196.85-39.76,60.22-25.47,114.3-61.93,160.73-108.36,46.43-46.43,82.89-100.51,108.36-160.73,26.38-62.37,39.76-128.6,39.76-196.85s-13.38-134.48-39.76-196.85Zm-465.94,619.61H505.7c-233.1,0-422.75-189.65-422.75-422.75,0-209.92,156.53-389.93,364.09-418.71,20.63-2.86,35.94-20.58,35.78-41.4v-1.15c-.02-.99-.03-1.97-.03-2.96,0-294.76,239.81-534.57,534.57-534.57,190.28,0,367.74,102.46,463.11,267.39,5.72,9.89,15.27,16.98,26.39,19.61,11.12,2.62,22.83,.54,32.37-5.75,49.52-32.69,107.22-49.97,166.85-49.97,167.24,0,303.31,136.06,303.31,303.31,0,22.91,18.57,41.47,41.47,41.47,233.1,0,422.75,189.65,422.75,422.75s-189.64,422.75-422.75,422.75Z"
                      />
                      <path
                          className="d"
                          d="M1507.38,1103.53c-30.4-30.4-65.82-54.28-105.26-70.96-40.86-17.28-84.23-26.04-128.93-26.04s-88.07,8.76-128.93,26.04c-39.44,16.68-74.86,40.56-105.26,70.96-30.4,30.4-54.28,65.82-70.96,105.26-17.28,40.86-26.04,84.23-26.04,128.93,0,22.91,18.57,41.47,41.47,41.47s41.47-18.57,41.47-41.47c0-136.88,111.36-248.24,248.24-248.24s248.24,111.36,248.24,248.24c0,22.91,18.57,41.47,41.47,41.47s41.47-18.57,41.47-41.47c0-44.69-8.76-88.07-26.04-128.93-16.68-39.44-40.56-74.86-70.96-105.26Z"
                      />
                      <path
                          className="d"
                          d="M1095.8,861.09c8.1,8.1,18.71,12.15,29.33,12.15s21.23-4.05,29.33-12.15c16.2-16.2,16.2-42.46,0-58.65l-80.89-80.89,80.89-80.89c16.2-16.2,16.2-42.46,0-58.65-16.2-16.2-42.46-16.2-58.65,0l-80.89,80.89-80.89-80.89c-16.2-16.2-42.46-16.2-58.65,0-16.2,16.2-16.2,42.46,0,58.65l80.89,80.89-80.89,80.89c-16.2,16.2-16.2,42.46,0,58.65,8.1,8.1,18.71,12.15,29.33,12.15s21.23-4.05,29.33-12.15l80.89-80.89,80.89,80.89Z"
                      />
                      <path
                          className="d"
                          d="M1681.19,582.01c-16.2-16.2-42.46-16.2-58.65,0l-80.89,80.89-80.89-80.89c-16.2-16.2-42.46-16.2-58.65,0-16.2,16.2-16.2,42.46,0,58.65l80.89,80.89-80.89,80.89c-16.2,16.2-16.2,42.46,0,58.65,8.1,8.1,18.71,12.15,29.33,12.15s21.23-4.05,29.33-12.15l80.89-80.89,80.89,80.89c8.1,8.1,18.71,12.15,29.33,12.15s21.23-4.05,29.33-12.15c16.2-16.2,16.2-42.46,0-58.65l-80.89-80.89,80.89-80.89c16.2-16.2,16.2-42.46,0-58.65Z"
                      />
                  </g>
              </g>
          </g>
      </svg>
      <div style={{marginLeft: '20px', marginTop: '20px'}}>
      {/* <h2 className="error">OOPS!</h2> */}
      <span>Records not found.</span>
      </div>
    </div>
  )
}
