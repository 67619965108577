import React, {useEffect, useState} from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import getRandomHexColor from '../../Utilities/getRandomHexColor';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

// Function to generate an array of colors based on the count
const generateColors = (length) => {
  const colors = [];
  
  for (let i = 0; i < length; i++) {
      // Generate colors (you can customize this logic)
      const { darkerShade } = getRandomHexColor()
      colors.push(darkerShade);
  }
  
  return colors;
};

const DoughnutChart = ({ _data, _value = null, _name = null }) => {

  const  [data, setData] = useState([])

  const [colors, setColors] = useState([]);

  useEffect(() => {
    setData(_data);
    if (_data && (_data.length > 0)){
      // Generate colors based on the length of _data
      const generatedColors = generateColors(_data.length);
      setColors(generatedColors);
    }
  }, [_data])


  return (
    <ResponsiveContainer width="100%" height={450}>
      <PieChart>
        <Pie
          data={data && data}
          dataKey={_value ? _value : "value"}
          nameKey={_name ? _name : "name"}
          cx="50%"  // Center x-axis
          cy="50%"  // Center y-axis
          innerRadius={70}  // Adjust inner radius to create the doughnut effect
          outerRadius={120} // Adjust outer radius for the size of the chart
          fill="#8884d8"
          paddingAngle={0}  // Remove the white space between segments
          cornerRadius={0}
        >
          {data && data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend layout="horizontal" verticalAlign="bottom" align="left" />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DoughnutChart;
