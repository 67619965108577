import React,  { useState, useEffect }  from 'react'
import parseDateTimeStamp from '../../Utilities/parseDateTimeStamp.'
import { Link } from 'react-router-dom';
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader'
import { motion } from 'framer-motion';
import NoResults from '../../Utilities/NoResults';

export default function BrandTable({brandData, resultsFound, loading}) {

    const [brandsList, setBrandsList] = useState([])
    useEffect(() => {
        setBrandsList(brandData)
    }, [brandData])
	

  return (
            <>
            {!loading  && resultsFound ?
            <div className="table-responsive">
                <motion.table 
                initial={{ y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.3 }} 
                className="table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                        <tr className="fw-bolder text-muted-2 bg-tr-light">
                            <th className="ps-4 min-w-200px rounded-start">Brand Name</th>
                            <th className="min-w-150px">client</th>
                            <th className="min-w-175px">Commissioning Agency</th>
                            <th className="min-w-200px">Brand Representative</th>
                            <th className="min-w-125px">No. Of Projects</th>
                            <th className="min-w-125px">No. Of Assets</th>
                            {/* <th className="min-w-200px">Created Date</th>
                            <th className="min-w-200px">Created By</th> */}
                        </tr>
                    </thead>
                    <tbody>
                    {brandsList.map((brand, index) => (
                        <tr key={index}>
                            <td>
                                <div className="d-flex align-items-center">
                                    <div className="symbol symbol-60px me-3" style={{width: '60px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        {brand.profile_brand_image_path && brand.profile_brand_image_path !== '' ? 
                                        <img src={brand.profile_brand_image_path} className="h-100 align-self-end" alt={brand.profile_brand_image_name} style={{width: '100%', height: '100%', borderRadius: '5px'}} />
                                        :
                                        <span className="svg-icon svg-icon-muted svg-icon-3x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M16.0077 19.2901L12.9293 17.5311C12.3487 17.1993 11.6407 17.1796 11.0426 17.4787L6.89443 19.5528C5.56462 20.2177 4 19.2507 4 17.7639V5C4 3.89543 4.89543 3 6 3H17C18.1046 3 19 3.89543 19 5V17.5536C19 19.0893 17.341 20.052 16.0077 19.2901Z" fill="black"></path></svg></span>
                                        }
                                    </div>
                                    <div className="d-flex justify-content-start flex-column">
                                        <Link to = {`/brand-management/detailed-brand/${brand.id}`} className="text-dark fw-bolder text-hover-primary fs-6" href="/asset-management/detailed-asset/1">{brand.Brand_name}</Link>
                                    </div>
                                </div>
                            </td>
                            <td>
                                {brand.client ?
                                    <Link to = {`/client-management/clients/detailed-client-organization/${brand.client.client_id}`} className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{brand.client.client_name}</Link> :
                                    <a className="text-dark fw-bolder d-block mb-1 fs-6">Not Added</a>
                                }
                            </td>
                            <td>
                                {brand.commissioning_agency ?
                                    <Link to = {`/agency-management/agencies/detailed-agency/${brand.commissioning_agency.commissioning_agency_id}`} className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{brand.commissioning_agency.commissioning_agency_name}</Link> : 
                                    <a className="text-dark fw-bolder d-block mb-1 fs-6">Not Added</a>}
                            </td>
                            <td>
                                <Link className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6" to={`/user-management/users/user-profile/${brand.Brand_representative}`}>{brand.Brand_representative_name}</Link>
                            </td>
                            <td><p className="text-dark fw-bolder d-block mb-1 fs-6">{brand.project_count} {brand.project_count === 1 ? 'Project' : 'Projects'}</p></td>
                            <td><p className="text-dark fw-bolder d-block mb-1 fs-6">{brand.asset_count} {brand.asset_count === 1 ? 'Asset' : 'Assets'}</p></td>
                            {/* <td><a className="text-dark fw-bolder d-block mb-1 fs-6">{parseDateTimeStamp(brand.Created_at)}</a></td>
                            <td>
                                <Link className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6" to={`/user-management/users/user-profile/${brand.Created_by}`}>{brand.Created_by_name}</Link>
                            </td> */}
                        </tr>
                    ))}

                    </tbody>
                </motion.table>
            </div>: 
            !loading  && !resultsFound ?
            <NoResults /> :
            <SkeletonLoader />
    }</>

  )
}
