import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setRedirectPath } from '../../store/loginSlice';
import { useLocation } from 'react-router-dom';
import publicRoutes from '../../routes/pubRoutes';

const RedirectHandler = () => {
  const redirectPath = useSelector((state) => state.loginSlice.redirectPath);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = useSelector((state) => state.loginSlice.isAuthenticated);
  // const [publicPaths, setPublicPaths] = useState([])

  let publicPaths = []
  useEffect(() => {
    const _publicPaths = []
    for (let i = 0; i < publicRoutes.length; i++) {
      _publicPaths.push(publicRoutes[i].path)
    }
    publicPaths = _publicPaths
    // setPublicPaths(_publicPaths)
  }, [publicRoutes])

  useEffect(() => {
    if (redirectPath) {
      navigate(redirectPath);
      dispatch(setRedirectPath(null));
    }
  }, [redirectPath, dispatch, navigate]);

  const checkIfUserIsAuthenticated = (path) => {
    if (isAuthenticated) {
      const lastChar = path[path.length - 1]
      if (lastChar === '/') {
        const chars = path.split('')
        chars.splice(chars.length - 1, 1)
        path = chars.join('')
      }
      if (publicPaths.includes(path)) {
        navigate('/dummy-page')
      }
    }
  }

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/auth/login')
    }
    checkIfUserIsAuthenticated(location.pathname);
  }, [location.pathname]);

  return null;
};

export default RedirectHandler;
