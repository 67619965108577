import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import AssetsTable from '../../Assets/AssetsTable/AssetsTable';
import api from '../../../store/api';
import getAssetSearchData from '../../Utilities/getAssetSearchData';
import { useParams } from 'react-router';

export default function BrandAssets() {

  const [assets, setAssets] = useState([])
  const token = useSelector((state) => state.loginSlice.token);
  const { id } = useParams()
  const [pageLoading, setPageLoading] = useState(false)

  const getAssets = () => {
      setPageLoading(true)
      api.get(`/api/brands/brand-assets/${id}/`,
      {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
      .then((response) => {
        if (response.data.length === 0) 
          setResultsFound(false)
          setAssets(response.data)
          setOriginalAssets(response.data)
          setPageLoading(false)
      })
      .catch((error) => {
          throw new Error(error) 
      })
  }

  useEffect(() => {
    getAssets()
  }, [])

  const [resultsFound, setResultsFound] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')
  const [originalAssets, setOriginalAssets] = useState([])
  const handleSearchChange = async (e) => {
    const keyword = e.target.value;
    setSearchQuery(keyword)
    if (keyword.length === 0) {
      setAssets(originalAssets)
    } else {
      const assets = await getAssetSearchData(keyword, `/api/brands/brand-assets/search/${id}/`, token)
      setAssets(assets)
    }
  }

  return (
    <>
      <AssetsTable assetData={assets}  loading={pageLoading} searchQuery={searchQuery} handleSearchChange={handleSearchChange} resultsFound={resultsFound}/>
    </>
  )
}
