import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import api from '../../../store/api';
import { useSelector } from 'react-redux';
import FeedbackModal from '../../Utilities/FeedbackModal';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export default function BulkUpload({ setMaterials }) {

    const [file, setFile] = useState(null);
    const [isValidFile, setIsValidFile] = useState(false);
    const [fileName, setFileName] = useState('');
    const [fileSize, setFileSize] = useState('');
    const [loading, setLoading] = useState(false);
    const [res, setRes] = useState({
        message: "",
        severity: "success",
        show: false
    });

    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const images = ['/static/assets/media/bulk-upload-instructions/item.png'];

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel') {
                setIsValidFile(true);
                setFile(file);
                setFileName(file.name);
                setFileSize(file.size);
            } else {
                setIsValidFile(false);
                setRes({
                    message: 'Please upload a valid Excel file.',
                    severity: "error",
                    show: true
                });
            }
        }
    }, []);

    const token = useSelector((state) => state.loginSlice.token);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: false,
        accept: ['.xlsx', '.xls'],
    });

    const handleFileUpload = (evt) => {
        evt.preventDefault();
        if (isValidFile && file) {
            setLoading(true);
            const data = new FormData();
            data.append('file', file);
            api.post(`/api/items/bulk-upload/`, data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Token ${token}`,
                    },
                })
                .then((response) => {
                    setRes({
                        message: response.data.message,
                        severity: 'success',
                        show: true
                    });
                    setMaterials(response.data.materials);
                    setFile(null);
                    setIsValidFile(false);
                    setFileName('');
                    setFileSize('');
                    setLoading(false);
                })
                .catch((error) => {
                    setRes({
                        message: error.response.data.message,
                        severity: 'error',
                        show: true
                    });
                    if (error.response.data.status === 'error' && error.response.data.errorFileUrl) {
                        window.open(error.response.data.errorFileUrl, '_blank');
                    }
                    setLoading(false);
                });
        }
    };


    const downloadTemplate = () => {
        const templateFileUrl = '/static/assets/media/bulk-upload-templates/item-template.xlsx'
        window.open(templateFileUrl, '_blank');
    }

    return (
        <>
            {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % images.length)
                    }
                />
            )}
            <FeedbackModal message={res.message} severity={res.severity} show={res.show} onClose={() => {
                setRes({
                    message: "",
                    severity: "success",
                    show: false
                });
            }} />

            <div className="d-flex flex-column flex-start flex-column-fluid mb-10">
                <p className='fw-bold'>Example Screenshot (click to zoom):</p>
                <img src={images[0]} alt="" 
                    className="mw-100" 
                    onClick={() => { setIsOpen(true); setPhotoIndex(0); }}
                    style={{ width: '100%', height: '100px', cursor: 'pointer' }} 
                />
            </div>
            <div className="d-flex flex-column flex-start flex-column-fluid mb-10">
                <div className="d-flex flex-column">
                <a className="btn btn-active-dark ftw-bold" onClick={downloadTemplate} style={{maxWidth: 'fit-content'}}>
                    <span className="svg-icon svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 12.6L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L8 12.6H11V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18V12.6H16Z" fill="black"></path><path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"></path></svg></span>
                    Download Template
                </a>
                </div>
            </div>
            <div className="d-flex flex-column flex-start flex-column-fluid mb-10">
                <p className='fw-bold'>Instructions:</p>
                <div className="d-flex flex-column">
                    <li className="d-flex align-items-center py-2">
                        <span className="bullet me-3"></span> <span>Make sure there is a column called <b>(ItemName)</b> in the first row. This field is required and is supposed to be unique in each row.</span>
                    </li>
                    <li className="d-flex align-items-center py-2">
                        <span className="bullet me-3"></span> <span>Make sure there is a column called <b>(Quantity)</b> in the first row. This field is mandatory and its value is always expected to be above 0.</span>
                    </li>
                    <li className="d-flex align-items-center py-2">
                        <span className="bullet me-3"></span> <span>The other fields are mandatory.</span>
                    </li>
                    <li className="d-flex align-items-center py-2">
                        <span className="bullet me-3"></span> <span>You can provide extra details of each individual item by populating the row below column <b>(SingleItemDescription)</b>.</span>
                    </li>
                </div>
            </div>
            <form className="form" action="#" method="post">
                <div className="fv-row">
                    {isValidFile ? (
                        <>
                            <p className='fw-bold'>Uploaded Excel File:</p>
                            <div className='d-flex' style={{justifyContent: 'space-between', alignItems: 'center'}}>
                                <div style={{width: '100%', height: '100px', display:'flex', alignItems: 'center'}}>
                                    <img style ={{height: '100px', marginRight: '10px'}}src="/static/assets/media/excel-logo/1.png" alt="" className="mw-100" />
                                    <div>
                                        <p className='mb-0 ftw-bold'>File Name: {fileName}</p>
                                        <p className='mb-0 ftw-bold'>File Size: {fileSize} kb</p>
                                    </div>
                                </div>
                                <div className='cancel-item-btn' style={{position: 'relative'}}>
                                    <span className='bi bi-x fs-3 cancel-icon' onClick={() => {
                                    setIsValidFile(false);
                                    setFile(null);
                                    setFileName('');
                                    setFileSize('');}}> </span>
                                </div>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <button type="submit" onClick={handleFileUpload} className="btn btn-beysix" disabled={loading}>
                                    {!loading ? <span className="indicator-label">Submit</span>
                                        : <span className="indicator-progress">Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
                                </button>
                            </div>
                        </>
                    ) : (
                        <div {...getRootProps({ className: 'dropzone dz-clickable' })} id="kt_dropzonejs_example_1">
                            <input {...getInputProps()} />
                            <div className="dz-message needsclick">
                                <i className="bi bi-file-earmark-arrow-up text-primary-beysix fs-3x"></i>
                                <div className="ms-4">
                                    <h3 className="fs-5 ftw-bolder text-gray-900 mb-1">Drop the excel file here or click to upload.</h3>
                                    <span className="fs-7 fw-bold opacity-75">Upload 1 file</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </form>
        </>
    )
}
