import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import parseDateTimestamp from '../../Utilities/parseDateTimeStamp.'
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader'
import { motion } from 'framer-motion';
import NoResults from '../../Utilities/NoResults';

export default function ClientOrganizationsListTable({clientData, resultsFound, loading}) {
  
  const [clientOrganizations, setClientOrganizations] = useState([])
  const [res, setRes] = useState({
    message: "",
    severity: "success",
    show: false
  })

  useEffect(() => {
    setClientOrganizations(clientData)
  }, [clientData])

  return (
    <>
      {(!loading && resultsFound) ? 
        <div className="table-responsive">
          <motion.table
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.3 }}
                    className="table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 p-5"
            >
            {/*begin::Table head*/}
            <thead>
              <tr className="fw-bolder text-muted-2 bg-tr-light">
                <th className="ps-4 min-w-300px rounded-start">Client Name</th>
                <th className="min-w-125px">No. of Brands</th>
                <th className="min-w-125px">No. of Projects</th>
                <th className="min-w-125px">No. of Assets</th>
                <th className="min-w-125px">No. of Members</th>
                <th className="min-w-125px">Manager</th>
                {/* <th className="min-w-125px">Created Date</th>
                <th className="min-w-200px">Created By</th> */}
              </tr>
            </thead>
            {/*end::Table head*/}
            {/*begin::Table body*/}
            <tbody>
              {clientOrganizations.map((client) => {
              return (<tr>
                    <td>
                      <div className="d-flex align-items-center">
                      <div className="symbol symbol-60px me-3" style={{width: '60px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        {client.profile_photo_path && client.profile_photo_path !== '' ? 
                        <img src={client.profile_photo_path} className="h-100 align-self-end" alt={client.profile_photo_name} style={{width: '100%', height: '100%', borderRadius: '5px'}} />
                           :
                        <span className="svg-icon svg-icon-muted svg-icon-3x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path opacity="0.3" d="M7.16973 20.95C6.26973 21.55 5.16972 20.75 5.46972 19.75L7.36973 14.05L2.46972 10.55C1.56972 9.95005 2.06973 8.55005 3.06973 8.55005H20.8697C21.9697 8.55005 22.3697 9.95005 21.4697 10.55L7.16973 20.95Z" fill="black"/>
                        <path d="M11.0697 2.75L7.46973 13.95L16.9697 20.85C17.8697 21.45 18.9697 20.65 18.6697 19.65L13.1697 2.75C12.7697 1.75 11.3697 1.75 11.0697 2.75Z" fill="black"/>
                        </svg></span>
                        }
                      </div>
                      <div className="d-flex justify-content-start flex-column">
                          <Link to={`/client-management/clients/detailed-client-organization/${client.id}`} className="text-dark fw-bolder text-hover-primary fs-6" href="/asset-management/detailed-asset/1">{client.name}</Link>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className="text-dark fw-bolder d-block mb-1 fs-6">
                      {client.brand_count} {client.brand_count === 1 ? 'Brand' : 'Brands'}
                    </p>
                  </td>
                  <td>
                    <p  className="text-dark fw-bolder d-block mb-1 fs-6">
                      {client.project_count} {client.project_count === 1 ? 'Project' : 'Projects'}  
                    </p>
                  </td>
                  <td>
                    <p  className="text-dark fw-bolder d-block mb-1 fs-6">
                      {client.asset_count} {client.asset_count === 1 ? 'Asset' : 'Assets'}
                    </p>
                  </td>
                  <td>
                    <p  className="text-dark fw-bolder d-block mb-1 fs-6">
                      {client.member_count} {client.member_count === 1 ? 'Member' : 'Members'}
                    </p>
                  </td>
                  <td>
                    <Link to={`/user-management/users/user-profile/${client.manager}`} className="text-dark fw-bolder d-block mb-1 fs-6">{client.manager_name}</Link>
                  </td>
                  {/* <td>
                    <a className="text-dark fw-bolder d-block mb-1 fs-6">{parseDateTimestamp(client.created_at)}</a>
                  </td>
                  <td>
                    <Link to={`/user-management/users/user-profile/${client.created_by}`} className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{client.created_by_name}</Link>
                  </td> */}
                </tr>
              )})}
            </tbody>
            {/*end::Table body*/}
          </motion.table>
        </div> : 
        (!resultsFound  && !loading) ? <NoResults /> :<SkeletonLoader />
        }
    </>
  )
}
