import React, {useState, useEffect} from 'react'

export default function Footer() {

  const [currentYear, setCurrentYear] = useState("")

  useEffect(() => {
    var currentDate = new Date();
    var _currentYear = currentDate.getFullYear();
    setCurrentYear(_currentYear)
  }, [])


  return  (<div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
    <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
      <div className="text-dark order-2 order-md-1">
        <span className="text-muted fwt-bold me-1">{currentYear}©</span>
        <a href="https://beysix.co.ke" target="_blank" className="text-gray-800 text-hover-primary">Beysix Studio</a>
      </div>
      <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
        
      </ul>
    </div>
  </div>
  )
}
