import React, {useState, useEffect, useRef} from 'react'
import { useSelector } from 'react-redux';
import api from '../../store/api';


export default function ItemSerialBulkSearchComponent({returnResults, successfullSubmission, edit=null, itemName=null}) {

    const [searchQuery, setSearchQuery] = useState("")
    const [searchResults, setSearchResults] = useState([])
    const [isSearching, setIsSearching] = useState(false)
    const [userSearching, setUserSearching] = useState(false)
    const [resultsFound, setResultsFound] = useState(false)
    const token = useSelector((state) => state.loginSlice.token);

    useEffect(() => {
        if (successfullSubmission === true) {
            setResultsFound(false)
            setIsSearching(false)
            setUserSearching(false)
            setSearchResults([])
            setResultsFound(false)
            setSearchQuery("")
            returnResults(null)
        }
    }, [successfullSubmission])

    useEffect(() => {
        if (itemName)
            setSearchQuery(itemName)
    }, [edit])

    const handleSearch = (evt) => {
        evt.preventDefault()
        const query = evt.target.value;
            setSearchQuery(query)
            if (query.length >= 2) {
                setUserSearching(true)
                setIsSearching(true)
                api.post(`/api/items/search-item-serial/`, {searchQuery: query},
                {headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${token}`,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.results.length > 0){
                            setResultsFound(true)
                            setSearchResults(response.data.results)
                            setIsSearching(false)
                        } else {
                            setResultsFound(false)
                            setSearchResults(response.data.results)
                            setIsSearching(false)
                        }
                    }
                })
                .catch((error) => {
                    if (response.status === 404) {

                    } else {
                        throw new Error(error)
                    }
                })
            } else if (query.length === 0) {
                setResultsFound(false)
                setIsSearching(false)
                setUserSearching(false)
            }
    } 

    const handleCancelSearch = (evt) => {
        evt.preventDefault()
        setResultsFound(false)
        setIsSearching(false)
        setUserSearching(false)
        setSearchResults([])
        setResultsFound(false)
        setSearchQuery("")
        returnResults(null)
    }

    const handleManagerClick = (item) => {
        setResultsFound(false)
        setIsSearching(false)
        setUserSearching(false)
        setSearchResults([])
        setResultsFound(false)
        setSearchQuery('')
        returnResults(item)
    }
  return (
    <>
    <div className="fv-row mb-10 fv-plugins-icon-container">
    {/*begin::Label*/}
    <label className="form-label required">Item</label>
    {/*end::Label*/}
    <div>
        {/*begin::Search*/}
        <div id="kt_docs_search_handler_basic" data-kt-search-keypress="true" data-kt-search-min-length="2" data-kt-search-enter="true" data-kt-search-layout="inline" data-kt-search="true" className="">
            <input type="hidden" />
            <div style={{position: 'relative'}}>
            <span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500" style={{position: "absolute", left: "2%", top: '25%'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path>
                </svg>
            </span>
                <input type="text" autoComplete="off" className="form-control form-control-lg form-control-solid px-15" name="search" value={searchQuery} placeholder="Try searching by item name..." onChange={handleSearch} />
                {isSearching ? <span className="lh-0 me-5" data-kt-search-element="spinner" style={{position: "absolute", top: "35%", left: "94%"}}>
                    <span className="spinner-border h-15px w-15px align-middle text-gray-400"></span>
                </span> :
                searchQuery.length > 0 && 
                <span className="btn btn-flush btn-active-color-primary end-0 lh-0" style={{position: "absolute", top: "25%", left: "93%"}} data-kt-search-element="clear" onClick={handleCancelSearch}>
                    <span className="svg-icon svg-icon-2 svg-icon-lg-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                        </svg>
                    </span>
                </span>}
                {userSearching && 
                <div className="quick-search-dropdown" style={{position: "absolute"}}>
                    {searchResults.length > 0 && resultsFound ? <div data-kt-search-element="results" className="">
                    {searchResults.map((result, index) => {
                    return (
                    <div className="d-flex align-items-center rounded-3 border-hover border border-dashed border-gray-300 cursor-pointer mb-1" key = {index} onClick={() => handleManagerClick(result)}>
                        <div className="symbol symbol-50px me-2">
                            <span className="symbol-label bg-light">
                                <span className="svg-icon svg-icon-muted svg-icon-3x">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path
                                            opacity="0.3"
                                            d="M22.0318 8.59998C22.0318 10.4 21.4318 12.2 20.0318 13.5C18.4318 15.1 16.3318 15.7 14.2318 15.4C13.3318 15.3 12.3318 15.6 11.7318 16.3L6.93177 21.1C5.73177 22.3 3.83179 22.2 2.73179 21C1.63179 19.8 1.83177 18 2.93177 16.9L7.53178 12.3C8.23178 11.6 8.53177 10.7 8.43177 9.80005C8.13177 7.80005 8.73176 5.6 10.3318 4C11.7318 2.6 13.5318 2 15.2318 2C16.1318 2 16.6318 3.20005 15.9318 3.80005L13.0318 6.70007C12.5318 7.20007 12.4318 7.9 12.7318 8.5C13.3318 9.7 14.2318 10.6001 15.4318 11.2001C16.0318 11.5001 16.7318 11.3 17.2318 10.9L20.1318 8C20.8318 7.2 22.0318 7.59998 22.0318 8.59998Z"
                                            fill="black"
                                        ></path>
                                        <path
                                            d="M4.23179 19.7C3.83179 19.3 3.83179 18.7 4.23179 18.3L9.73179 12.8C10.1318 12.4 10.7318 12.4 11.1318 12.8C11.5318 13.2 11.5318 13.8 11.1318 14.2L5.63179 19.7C5.23179 20.1 4.53179 20.1 4.23179 19.7Z"
                                            fill="black"
                                        ></path>
                                    </svg>
                                </span>
                            </span>
                        </div>
                        <div className="d-flex align-items-start flex-column p-3">
                            <div className="fw-bold">
                                <span className="fs-6 text-gray-800 me-2">{result.name}</span>
                            </div>
                            <div className="fw-bold">
                                <span className="badge badge-secondary fs-6 text-gray-800 me-2">{result.serial}</span>
                            </div>
                        </div>
                    </div>)})}
                    </div> :
                    <div data-kt-search-element="empty" className="text-center">
                        {/*begin::Message*/}
                        <div className="fwt-bold py-0 mb-10">
                            <div className="text-gray-600 fs-3 mb-2">No items found</div>
                            <div className="text-gray-400 fs-6">Try searching by item name</div>
                        </div>
                        {/*end::Message*/}
                        {/*begin::Illustration*/}
                        <div className="text-center px-4">
                            <img className="mw-100 mh-200px" alt="" src="assets/media/illustrations/alert.png" />
                        </div>
                        {/*end::Illustration*/}
                    </div>}
                    {/*end::Empty*/}
                </div>}
                {/*end::Wrapper*/}
                </div>
                {/*end::Search*/}
                </div>
                </div>
                </div>
    </>
  )
}
