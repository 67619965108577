import React, {useEffect, useState, useRef} from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import L from 'leaflet'
import 'leaflet-search/dist/leaflet-search.min.css';
import 'leaflet-search';
import { useSelector } from 'react-redux'
import api from '../../../../store/api';
import MainBarChart from '../../../Charts/BarChart/BarChart';
import DoughnutChart from '../../../Charts/DoughnutChart/DoughnutChart';
import SimpleLineChart from '../../../Charts/LineChart/LineChart';
import formatMoney from '../../../Utilities/FormatMoney';
import DashboardCardComponent from '../../DashboardCardComponent/DashboardCardComponent';

export default function ActivationDashboard() {

    const [activationCount, setActivationCount] = useState({})
    const [pageLoading, setPageLoading] = useState(false)
    const [activationLocationInfo, setLocationActivationInfo] = useState([])
    const [activationPerCommissioningAgency, setActivationPerCommissioningAgency] = useState([])
    const [activationPerBrand, setActivationPerBrand] = useState([])
    const [activationPerProject, setActivationPerProject] = useState([])
    const [activationPerWeek, setActivationPerWeek] = useState([])
    const [activationPerMonth, setActivationPerMonth] = useState([])
    const [activationCostPerBrand, setActivationCostPerBrand] = useState([])
    const [activationCostPerAgency, setActivationCostPerAgency] = useState([])
    const [activationPerCounty, setActivationPerCounty] = useState([])
    const [activationPerRegion, setActivationPerRegion] = useState([])

    const mapRef = useRef(null)
    const marker = useRef(null)
    const initCoordinates = [-1.2460227009959508, 36.68168221244762];
    const [markerPosition, setMarkerPosition] = useState(null)

    const onMapReady = (map) => {
        map = map.current
        if (map){
            map.invalidateSize()
        }
    };

    const handleSetMarkerPosition = (coordinates) => {
        const { lat, long } = coordinates
        if ((lat && long) && mapRef.current) {
            setMarkerPosition({lat: parseFloat(lat), lng: parseFloat(long)})
            const marker = L.marker([51.505, -0.09], { icon: customIcon }).addTo(mapRef.current);
            marker.setLatLng([lat, long]);
            const newLatLng = marker._latlng;
            mapRef.current.flyTo(newLatLng, 8);
        }
    }

    const customIcon = L.icon({
        iconUrl: '/static/assets/media/illustrations/beysix-1/location-pin.png',
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        popupAnchor: [0, -38]
    });

    const getActivationDetails = () => {
        api.get(`/api/dashboards/get-activation-count/${organization.id}/`,
          {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
          .then((response) => {
              if (response.status === 200) {
                setActivationCount(response.data.count)
                setLocationActivationInfo(response.data.activation_coordinates)
                setActivationPerCommissioningAgency(response.data.activation_per_commissioning_agency)
                setActivationPerBrand(response.data.activations_per_brand)
                setActivationPerWeek(response.data.activations_per_week )
                setActivationPerMonth(response.data.activations_per_month )
                setActivationCostPerAgency(response.data.overall_cost_per_agency)
                setActivationCostPerBrand(response.data.overall_cost_per_brand)
                setActivationPerRegion(response.data.activation_count_per_region)
                setActivationPerCounty(response.data.activation_count_per_county)
                setActivationPerProject(response.data.project_activation_data)
                for (let i = 0; i < response.data.activation_coordinates.length; i++) 
                    handleSetMarkerPosition(response.data.activation_coordinates[i].coords)
                setPageLoading(false)
              }
          })
          .catch((error) => {
              setPageLoading(false)
              throw new Error('Error getting data.')
          })
      }
    
      const {organization, token} = useSelector((state) => {
        return {
          'organization': state.loginSlice.organization, 
          'token': state.loginSlice.token
        }
      }); //
    
      useEffect(() => {
        getActivationDetails()
      }, [])

  return (
    <div className="row g-6 g-xl-9">
        <div className="col-lg-6 col-xxl-2">
            {/*begin::Card*/}
            <DashboardCardComponent isLoading={pageLoading} >
                {/*begin::Card body*/}
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div className="fs-4 fw-bolder">{activationCount.total_activations__count}</div>
                      <span className="svg-icon svg-icon-primary svg-icon-3hx" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8 22C7.4 22 7 21.6 7 21V9C7 8.4 7.4 8 8 8C8.6 8 9 8.4 9 9V21C9 21.6 8.6 22 8 22Z" fill="black"></path><path opacity="0.3" d="M4 15C3.4 15 3 14.6 3 14V6C3 5.4 3.4 5 4 5C4.6 5 5 5.4 5 6V14C5 14.6 4.6 15 4 15ZM13 19V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19ZM17 16V5C17 4.4 16.6 4 16 4C15.4 4 15 4.4 15 5V16C15 16.6 15.4 17 16 17C16.6 17 17 16.6 17 16ZM21 18V10C21 9.4 20.6 9 20 9C19.4 9 19 9.4 19 10V18C19 18.6 19.4 19 20 19C20.6 19 21 18.6 21 18Z" fill="black"></path></svg>
                      </span>
                    </div>
                    <div className="fs-5 fwt-bold text-gray-400 mb-7">Total {activationCount.total_activations__count === 1 ? 'Activation' : 'Activations'}</div>
                </div>
                {/*end::Card body*/}
            </DashboardCardComponent>
            {/*end::Card*/}
        </div>
        <div className="col-lg-6 col-xxl-2">
            {/*begin::Budget*/}
            <DashboardCardComponent isLoading={pageLoading} >
                {/*begin::Card body*/}
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fs-4 fw-bolder">{activationCount.pending_activations_count}</div>
                      <span className="svg-icon svg-icon-3hx svg-icon-muted" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8 22C7.4 22 7 21.6 7 21V9C7 8.4 7.4 8 8 8C8.6 8 9 8.4 9 9V21C9 21.6 8.6 22 8 22Z" fill="black"></path><path opacity="0.3" d="M4 15C3.4 15 3 14.6 3 14V6C3 5.4 3.4 5 4 5C4.6 5 5 5.4 5 6V14C5 14.6 4.6 15 4 15ZM13 19V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19ZM17 16V5C17 4.4 16.6 4 16 4C15.4 4 15 4.4 15 5V16C15 16.6 15.4 17 16 17C16.6 17 17 16.6 17 16ZM21 18V10C21 9.4 20.6 9 20 9C19.4 9 19 9.4 19 10V18C19 18.6 19.4 19 20 19C20.6 19 21 18.6 21 18Z" fill="black"></path></svg>
                      </span>
                    </div>
                    <div className="fs-5 fwt-bold text-gray-400 mb-7">Pending {activationCount.pending_activations_count === 1 ? 'Activation' : 'Activations'}</div>
                </div>
            {/*end::Budget*/}
            </DashboardCardComponent>
        </div>
        <div className="col-lg-6 col-xxl-2">
            {/*begin::Budget*/}
            <DashboardCardComponent isLoading={pageLoading} >
                {/*begin::Card body*/}
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fs-4 fw-bolder">{activationCount.active_activations_count}</div>
                      <span className="svg-icon svg-icon-3hx svg-icon-info" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8 22C7.4 22 7 21.6 7 21V9C7 8.4 7.4 8 8 8C8.6 8 9 8.4 9 9V21C9 21.6 8.6 22 8 22Z" fill="black"></path><path opacity="0.3" d="M4 15C3.4 15 3 14.6 3 14V6C3 5.4 3.4 5 4 5C4.6 5 5 5.4 5 6V14C5 14.6 4.6 15 4 15ZM13 19V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19ZM17 16V5C17 4.4 16.6 4 16 4C15.4 4 15 4.4 15 5V16C15 16.6 15.4 17 16 17C16.6 17 17 16.6 17 16ZM21 18V10C21 9.4 20.6 9 20 9C19.4 9 19 9.4 19 10V18C19 18.6 19.4 19 20 19C20.6 19 21 18.6 21 18Z" fill="black"></path></svg>
                      </span>
                    </div>
                    <div className="fs-5 fwt-bold text-gray-400 mb-7">Active {activationCount.active_activations_count === 1 ? 'Activation' : 'Activations'}</div>
                </div>
            {/*end::Budget*/}
            </DashboardCardComponent>
        </div>
        <div className="col-lg-6 col-xxl-2">
            {/*begin::Clients*/}
            <DashboardCardComponent isLoading={pageLoading} >
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fs-4 fw-bolder">{activationCount.completed_activations_count}</div>
                      <span className="svg-icon svg-icon-3hx svg-icon-success" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8 22C7.4 22 7 21.6 7 21V9C7 8.4 7.4 8 8 8C8.6 8 9 8.4 9 9V21C9 21.6 8.6 22 8 22Z" fill="black"></path><path opacity="0.3" d="M4 15C3.4 15 3 14.6 3 14V6C3 5.4 3.4 5 4 5C4.6 5 5 5.4 5 6V14C5 14.6 4.6 15 4 15ZM13 19V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19ZM17 16V5C17 4.4 16.6 4 16 4C15.4 4 15 4.4 15 5V16C15 16.6 15.4 17 16 17C16.6 17 17 16.6 17 16ZM21 18V10C21 9.4 20.6 9 20 9C19.4 9 19 9.4 19 10V18C19 18.6 19.4 19 20 19C20.6 19 21 18.6 21 18Z" fill="black"></path></svg>
                      </span>
                    </div>
                    <div className="fs-5 fwt-bold text-gray-400 mb-7">Completed {activationCount.completed_activations_count === 1 ? 'Activation' : 'Activations'}</div>
                </div>
                </DashboardCardComponent>
            {/*end::Clients*/}
        </div>
        <div className="col-lg-6 col-xxl-2">
            {/*begin::Clients*/}
            <DashboardCardComponent isLoading={pageLoading} >
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fs-4 fw-bolder">{activationCount.cancelled_activations_count}</div>
                        <span className="svg-icon svg-icon-3hx svg-icon-danger" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8 22C7.4 22 7 21.6 7 21V9C7 8.4 7.4 8 8 8C8.6 8 9 8.4 9 9V21C9 21.6 8.6 22 8 22Z" fill="black"></path><path opacity="0.3" d="M4 15C3.4 15 3 14.6 3 14V6C3 5.4 3.4 5 4 5C4.6 5 5 5.4 5 6V14C5 14.6 4.6 15 4 15ZM13 19V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19ZM17 16V5C17 4.4 16.6 4 16 4C15.4 4 15 4.4 15 5V16C15 16.6 15.4 17 16 17C16.6 17 17 16.6 17 16ZM21 18V10C21 9.4 20.6 9 20 9C19.4 9 19 9.4 19 10V18C19 18.6 19.4 19 20 19C20.6 19 21 18.6 21 18Z" fill="black"></path></svg>
                      </span>
                    </div>
                    <div className="fs-5 fwt-bold text-gray-400 mb-7">Cancelled {activationCount.cancelled_activations_count === 1 ? 'Activation' : 'Activations'}</div>
                </div>
                </DashboardCardComponent>
            {/*end::Clients*/}
        </div>
        <div className="col-lg-6 col-xxl-2">
            {/*begin::Clients*/}
            <DashboardCardComponent isLoading={pageLoading} >
                <div className="card-body p-9">
                    {/*begin::Heading*/}
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fs-4 fw-bolder">KS {formatMoney(activationCount.overall_activation_cost)}</div>
                        <span className="svg-icon svg-icon-3hx svg-icon-primary" style={{
                          display: 'flex',
                          alignItems: 'center'
                      }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path opacity="0.3" d="M12.5 22C11.9 22 11.5 21.6 11.5 21V3C11.5 2.4 11.9 2 12.5 2C13.1 2 13.5 2.4 13.5 3V21C13.5 21.6 13.1 22 12.5 22Z" fill="black"/>
                      <path d="M17.8 14.7C17.8 15.5 17.6 16.3 17.2 16.9C16.8 17.6 16.2 18.1 15.3 18.4C14.5 18.8 13.5 19 12.4 19C11.1 19 10 18.7 9.10001 18.2C8.50001 17.8 8.00001 17.4 7.60001 16.7C7.20001 16.1 7 15.5 7 14.9C7 14.6 7.09999 14.3 7.29999 14C7.49999 13.8 7.80001 13.6 8.20001 13.6C8.50001 13.6 8.69999 13.7 8.89999 13.9C9.09999 14.1 9.29999 14.4 9.39999 14.7C9.59999 15.1 9.8 15.5 10 15.8C10.2 16.1 10.5 16.3 10.8 16.5C11.2 16.7 11.6 16.8 12.2 16.8C13 16.8 13.7 16.6 14.2 16.2C14.7 15.8 15 15.3 15 14.8C15 14.4 14.9 14 14.6 13.7C14.3 13.4 14 13.2 13.5 13.1C13.1 13 12.5 12.8 11.8 12.6C10.8 12.4 9.99999 12.1 9.39999 11.8C8.69999 11.5 8.19999 11.1 7.79999 10.6C7.39999 10.1 7.20001 9.39998 7.20001 8.59998C7.20001 7.89998 7.39999 7.19998 7.79999 6.59998C8.19999 5.99998 8.80001 5.60005 9.60001 5.30005C10.4 5.00005 11.3 4.80005 12.3 4.80005C13.1 4.80005 13.8 4.89998 14.5 5.09998C15.1 5.29998 15.6 5.60002 16 5.90002C16.4 6.20002 16.7 6.6 16.9 7C17.1 7.4 17.2 7.69998 17.2 8.09998C17.2 8.39998 17.1 8.7 16.9 9C16.7 9.3 16.4 9.40002 16 9.40002C15.7 9.40002 15.4 9.29995 15.3 9.19995C15.2 9.09995 15 8.80002 14.8 8.40002C14.6 7.90002 14.3 7.49995 13.9 7.19995C13.5 6.89995 13 6.80005 12.2 6.80005C11.5 6.80005 10.9 7.00005 10.5 7.30005C10.1 7.60005 9.79999 8.00002 9.79999 8.40002C9.79999 8.70002 9.9 8.89998 10 9.09998C10.1 9.29998 10.4 9.49998 10.6 9.59998C10.8 9.69998 11.1 9.90002 11.4 9.90002C11.7 10 12.1 10.1 12.7 10.3C13.5 10.5 14.2 10.7 14.8 10.9C15.4 11.1 15.9 11.4 16.4 11.7C16.8 12 17.2 12.4 17.4 12.9C17.6 13.4 17.8 14 17.8 14.7Z" fill="black"/>
                      </svg>
                      </span>
                    </div>
                    <div className="fs-5 fwt-bold text-gray-400 mb-7">Overall Activation Cost</div>
                </div>
                </DashboardCardComponent>
            {/*end::Clients*/}
        </div>
        <div className='col-md-8 mt-3'>
        <DashboardCardComponent isLoading={pageLoading} >
                <div className="card-body d-flex flex-column" style={{padding: "0px"}}>
                    <MapContainer
                            ref={mapRef}
                            center={initCoordinates}
                            zoom={14}
                            style={{ height: "495px", width: "100%" }}
                        >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {markerPosition && (
                            <Marker position={markerPosition} icon={customIcon} ref={marker}>
                                {activationLocationInfo.map((activation, index) => 
                                <Popup key={index} >
                                    {activation.activation_name.concat(' Activation')}<br /> {parseFloat(activation.coords.lat).toFixed(4)}, {parseFloat(activation.coords.long).toFixed(4)}
                                </Popup>)}
                            </Marker>
                        )}
                    </MapContainer>
                    </div>
                </DashboardCardComponent>
            </div>
            <div className="col-lg-4 col-xxl-4 mt-3">
                <DashboardCardComponent isLoading={pageLoading} >
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Activations per brand</span>
                        </h5>
                    <DoughnutChart _data = {activationPerBrand} _name = "Brand_name" />
                </div>
                </DashboardCardComponent>        
            </div> 
            <div className="col-lg-4 col-xxl-12 mt-3">
                <DashboardCardComponent isLoading={pageLoading} >
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Activations per project</span>
                        </h5>
                        <MainBarChart _data = {activationPerProject} />
                </div>
                </DashboardCardComponent>          
            </div> 
            <div className="col-lg-6 col-xxl-12 mt-3">
                <DashboardCardComponent isLoading={pageLoading} >
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Activation Per Commissioning Agency</span>
                        </h5>
                    <MainBarChart _data = {activationPerCommissioningAgency} />
                </div>
                </DashboardCardComponent>
            </div>
            <div className="col-lg-4 col-xxl-12 mt-3">
                <DashboardCardComponent isLoading={pageLoading} >
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Activations per County</span>
                        </h5>
                    </div>
                    <MainBarChart _data = {activationPerCounty} _name = "county" />
                </DashboardCardComponent>
                </div>  
            <div className="col-lg-4 col-xxl-12 mt-3">
                <DashboardCardComponent isLoading={pageLoading} >
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Activations per Region</span>
                        </h5>
                    </div>
                    <MainBarChart _data = {activationPerRegion} _name = "region" />
                </DashboardCardComponent>
                </div>  
            <div className="col-lg-4 col-xxl-12 mt-3">
                <DashboardCardComponent isLoading={pageLoading} >
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Number of Activations Per Week</span>
                        </h5>
                    <SimpleLineChart _data = {activationPerWeek}  />
                </div>
                </DashboardCardComponent>        
            </div> 
            <div className="col-lg-4 col-xxl-12 mt-3">
                <DashboardCardComponent isLoading={pageLoading} >
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Number of Activations Per Month</span>
                        </h5>
                    <SimpleLineChart _data = {activationPerMonth}  />
                </div>
                </DashboardCardComponent>        
            </div> 
            <div className="col-lg-4 col-xxl-12 mt-3">
                <DashboardCardComponent isLoading={pageLoading} >
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Activation Cost Per Activating Agency</span>
                        </h5>
                    <MainBarChart _data = {activationCostPerAgency} />
                </div>
                </DashboardCardComponent>          
            </div> 
            <div className="col-lg-4 col-xxl-12 mt-3">
                <DashboardCardComponent isLoading={pageLoading} >
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-2">
                        <h5 className="card-title align-items-start flex-column mb-2">
                        <span className="fwt-bolder text-dark">Activation Cost Per Brand</span>
                        </h5>
                    <MainBarChart _data = {activationCostPerBrand} _name = "Brand_name" />
                </div>
                </DashboardCardComponent>         
            </div> 
        </div> 
  )
}
