import React, {useState, useEffect, useRef} from 'react'
import ConfirmationModal from '../Utilities/ConfirmationModal'

export default function MainModal({modalTitle, show, handleClose, confirmationInfo = null, noPadding = null, width=null, children}) {

    const [confirmationShow, setConfirmationShow] = useState(false)

    const handleCloseClick = () => {
        setConfirmationShow(true)
    }

    const modal = useRef(null)
    const backdrop = useRef(null)

    useEffect(() => {
        const handleClickEvent = (evt) => {
            if (modal) {
                const element = modal.current;
                const target = evt.target;
                const isInitBtn = target.getAttribute('i-attr');
                if (element) {
                    if (!element.contains(target) && !isInitBtn) {
                        handleCloseClick();
                    }
                }
            }
        };

        const handleBackdropClick = (evt) => handleClickEvent(evt);

        if (show && backdrop.current) {
            backdrop.current.addEventListener('click', handleBackdropClick);
        }

        return () => {
            if (backdrop.current) {
                backdrop.current.removeEventListener('click', handleBackdropClick);
            }
        };
    }, [show]);
    
    

    function handleConfirmationCloseClick(evt) {
        const target = evt.target
        if (target) {
            const attr = target.getAttribute('close-attr')
            if (attr == 'true') {
                setConfirmationShow(false)
                handleClose(false)
            } else {
                setConfirmationShow(false)
            }
        }
    }

  return (<div ref = {backdrop}className={show ? 'modal fade'.concat(' show') : 'modal'} id="kt_modal_update_details" tabIndex="-1" aria-modal="true" role="dialog" style={show ? {display: "block",  background:'rgba(0,0,0,0.3)'} : {display: "none"}}>
            <div ref={modal} className={width ? "modal-dialog modal-dialog-centered" : "modal-dialog modal-dialog-centered mw-650px"} style={width ? {minWidth: width} : null }>
                <div className="modal-content">
                    <ConfirmationModal handleConfirmationCloseClick={handleConfirmationCloseClick} confirmationShow = {confirmationShow} confirmationDetails = {confirmationInfo} />
                        <div className="modal-header" id="kt_modal_update_user_header" style={{padding: '10px 40px'}}>
                            <h4 className="fwt-bolder text-dark">{modalTitle}</h4>
                            <div className="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" onClick={handleCloseClick}>
                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div className={noPadding ? "modal-body" : "modal-body py-10 px-lg-17"}>
                            {children}
                        </div>
                </div>
            </div>
        </div>
    )
}
