import React from 'react'
import { Link } from 'react-router-dom'

export default function error404() {

  return (
    <div className="d-flex flex-column flex-root">
      {/* <p style={{
        fontSize: "40vw",
        color: "grey",
        position: "absolute",
        zIndex: -1,
        opacity: .05
      }}>404</p> */}
        {/*begin::Authentication - 404 Page*/}
        <div className="d-flex flex-column flex-center flex-column-fluid p-10">
            {/*begin::Illustration*/}
            <img src="/static/assets/media/illustrations/beysix-1/error 404.svg" alt="" className="mw-100 mb-10 h-lg-450px" />
            {/*end::Illustration*/}
            {/*begin::Message*/}
            <h1 className="fwt-bold mb-10" style={{color: "#A3A3C7"}}>Seems there is nothing here</h1>
            {/*end::Message*/}
            {/*begin::Link*/}
            <Link to="/auth/login" className="btn btn-beysix">Return Home</Link>
            {/*end::Link*/}
        </div>
        {/*end::Authentication - 404 Page*/}
    </div>
  )
}
