// RestrictedModal.js
import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';

// Modal.setAppElement('#root');

const RestrictedModal = () => (
    <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
  <div class="alert alert-dismissible d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10" style={{
            width: '50vw',
            borderRadius: '10px',
            background: '#ffe1ea'
        }}>
        <span class="svg-icon svg-icon-5tx svg-icon-danger mb-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
                <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"></rect>
                <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"></rect>
            </svg>
        </span>
        <div class="text-center text-dark">
            <h1 class="fw-bolder mb-4">Access Denied</h1>
            <div class="separator separator-dashed border-danger opacity-25 mb-4"></div>
            <div class="mb-9" style={{fontSize: '15px'}}>You do not have sufficient rights to view the contents of this page.
            <br/> If you believe this is an error, please contact your administrator or try logging in with an account that has the appropriate permissions.
            </div>
            <div class="d-flex flex-center flex-wrap">
                <a onClick={() => window.history.back()} class="btn btn-outline btn-outline-danger btn-active-danger m-2">Take Me Back</a>
                <Link to="/dummy-page" class="btn btn-danger m-2">Homepage</Link>
            </div>
        </div>
    </div>
</div>
);

export default RestrictedModal;
