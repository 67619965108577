import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Root from '../components/Authentication/Root/Root';

const  PublicRoutes = ({ routes }) => {
  // const isAuthenticated = useSelector((state) => state.loginSlice.isAuthenticated);
  // if (isAuthenticated) {
  //   return <Navigate to="/dummy-page" />;
  // }

  return (
    <Routes>
      {routes.map(({ path, component: Component }, index) => (
        <Route key={index} path={path} element={<Root><Component /></Root>} />
      ))}
    </Routes>
  );
};

export default PublicRoutes;