import React, { useRef, useEffect, useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import 'leaflet-search/dist/leaflet-search.min.css';
import 'leaflet-search';
import deleteLeafletAttribution from '../../Utilities/deleteLeafletAttribution';
import parseDateTimestamp from '../../Utilities/parseDateTimeStamp.';
import { Link } from 'react-router-dom';
import getRandomHexColor from '../../Utilities/getRandomHexColor';

export default function OverviewTab({active, warehouse}) {

    const mapRef = useRef(null)
    const marker = useRef(null)
    const initCoordinates = [-1.2460227009959508, 36.68168221244762];
    const [markerPosition, setMarkerPosition] = useState(null)

    const onMapReady = (map) => {
        map = map.current
        if (map){
            map.invalidateSize()
        }
    };

    const handleSetMarkerPosition = (coordinates) => {
        const { lat, long } = coordinates
        if ((lat && long) && mapRef.current) {
            setMarkerPosition({lat: parseFloat(lat), lng: parseFloat(long)})
            const marker = L.marker([51.505, -0.09], { icon: customIcon }).addTo(mapRef.current);
            marker.setLatLng([lat, long]);
            const newLatLng = marker._latlng;
            mapRef.current.flyTo(newLatLng, 14);
        }
    }

    const customIcon = L.icon({
        iconUrl: '/static/assets/media/illustrations/beysix-1/location-pin.png',
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        popupAnchor: [0, -38]
    });

    const getRandomColor = () => {
        const { randomColor } = getRandomHexColor()
        return randomColor
    }

    useEffect(() => {
        handleSetMarkerPosition({lat: warehouse.Location_latitude, long: warehouse.Location_longitude})
        onMapReady(mapRef)
        deleteLeafletAttribution()
    }, [warehouse])
  return (
    <>
    {active && 
        <div className='row g-6 g-xl-9'>
            <div className="col-lg-8">
                {/*begin::Card*/}
                <div className="card card-flush">
                    {/*begin::Card header*/}
                    <div className="card-header mt-6">
                        {/*begin::Card title*/}
                        <div className="card-title flex-column">
                            <h4 className="ftw-bold mb-1 text-capitalize">{warehouse.Warehouse_name} physical location</h4>
                            <div className="fs-6 text-gray-400">Coordinates: {parseFloat(warehouse.Location_latitude).toFixed(4)}, {parseFloat(warehouse.Location_longitude).toFixed(4)}</div>
                        </div>
                        {/*end::Card title*/}
                    </div>
                    {/*end::Card toolbar*/}
                    {/*begin::Card body*/}
                    <div className="card-body d-flex flex-column" style={{padding: "0px"}}>
                    <MapContainer
                            ref={mapRef}
                            center={initCoordinates}
                            zoom={14}
                            style={{ height: "400px", width: "100%" }}
                        >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {markerPosition && (
                            <Marker position={markerPosition} icon={customIcon} ref={marker}>
                                <Popup>
                                    {warehouse.Warehouse_name}: {markerPosition.lat.toFixed(4)}, {markerPosition.lng.toFixed(4)}
                                </Popup>
                            </Marker>
                        )}
                    </MapContainer>
                    </div>
                    {/*end::Card body*/}
                </div>
                {/*end::Card*/}
            </div>
            <div className="col-lg-4">
                {/*begin::Card*/}
                <div className="card card-flush">
                    {/*begin::Card header*/}
                    <div className="card-header mt-6">
                        {/*begin::Card title*/}
                        <div className="card-title flex-column">
                            <h4 className="ftw-bold mb-1 text-capitalize">Warehouse Details</h4>
                            <div className="fs-6 text-gray-400">Basic warehouse details</div>
                        </div>
                        {/*end::Card title*/}
                    </div>
                    {/*end::Card toolbar*/}
                    {/*begin::Card body*/}
                    <div className="card-body pt-2">
                    {/*begin::Item*/}
                    <div className="d-flex align-items-center mb-8">
                        <span className="bullet bullet-vertical h-40px" style={{marginRight: "15px", background: getRandomColor()}}></span>
                        <div className="flex-grow-1">
                            <a className="text-gray-800 ftw-bold fs-6">Warehouse Creation Date</a>
                            <span className="text-muted fwt-bold d-block">{parseDateTimestamp(warehouse.Created_at)}</span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-8">
                        <span className="bullet bullet-vertical h-40px" style={{marginRight: "15px", background: getRandomColor()}}></span>
                        <div className="flex-grow-1">
                            <a className="text-gray-800 ftw-bold fs-6">Warehouse Created By</a>
                            <Link to={`/user-management/users/user-profile/${warehouse.Created_by}`} className="text-muted text-hover-primary fwt-bold d-block">{warehouse.created_by_name}</Link>
                        </div>
                    </div>
                    {(warehouse.Warehouse_contact !== "" && warehouse.Warehouse_contact !== null) &&
                    <div className="d-flex align-items-center mb-8">
                        <span className="bullet bullet-vertical h-40px" style={{marginRight: "15px", background: getRandomColor()}}></span>
                        <div className="flex-grow-1">
                            <a className="text-gray-800 ftw-bold fs-6">Warehouse Contact Detail</a>
                            <span className="text-muted fwt-bold d-block">{warehouse.Warehouse_contact}</span>
                        </div>
                    </div>}
                    {warehouse.Updated_by !== null &&
                    <>
                    <div className="d-flex align-items-center mb-8">
                        <span className="bullet bullet-vertical h-40px" style={{marginRight: "15px", background: getRandomColor()}}></span>
                        <div className="flex-grow-1">
                            <a className="text-gray-800 ftw-bold fs-6">Warehouse Update Date</a>
                            <span className="text-muted fwt-bold d-block">{parseDateTimestamp(warehouse.Updated_at)}</span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-8">
                        <span className="bullet bullet-vertical h-40px" style={{marginRight: "15px", background: getRandomColor()}}></span>
                        <div className="flex-grow-1">
                            <a className="text-gray-800 ftw-bold fs-6">Warehouse Update Done By</a>
                            <Link to={`/user-management/users/user-profile/${warehouse.Updated_by}`} className="text-muted fwt-bold text-hover-primary d-block">{warehouse.Updated_by_name}</Link>
                        </div>
                    </div>
                    </>}
                    <div className="d-flex align-items-center mb-8">
                        <span className="bullet bullet-vertical h-40px" style={{marginRight: "15px", background: getRandomColor()}}></span>
                        <div className="flex-grow-1">
                            <a className="text-gray-800 ftw-bold fs-6">Warehouse Capacity</a>
                            <span className="text-muted fwt-bold d-block">{warehouse.Warehouse_capacity}</span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-8">
                        <span className="bullet bullet-vertical h-40px" style={{marginRight: "15px", background: getRandomColor()}}></span>
                        <div className="flex-grow-1">
                            <a className="text-gray-800 ftw-bold fs-6">Warehouse Coordinates</a>
                            <span className="text-muted fwt-bold d-block">{parseFloat(warehouse.Location_latitude).toFixed(4)}, {parseFloat(warehouse.Location_longitude).toFixed(4)}</span>
                        </div>
                    </div>
                </div>
                    {/*end::Card body*/}
                </div>
                {/*end::Card*/}
            </div>
        </div>
    }
    </>
  )
}
