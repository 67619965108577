import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux';
import api from '../../store/api';
import FeedbackModal from '../Utilities/FeedbackModal';

export default function CreateTool({handleCloseModal=null, setTools=null, tools=null, returnResults = null}) {
    const token = useSelector((state) => state.loginSlice.token);
    const [toolData, setToolData] = useState({
        toolDescription: '',
        toolName: '',
        toolsWithinWarehouse: '',
        toolQuantity: ''
    })

    const handleChange = (evt) => {
        evt.preventDefault()
        const name = evt.target.name
        const value = evt.target.value;
        setToolData({...toolData, [name]: value})
    }

    const [loading, setLoading] = useState(false)
    const [res, setRes] = useState({
        message: "",
        severity: "success",
        show: false
    })

    const handleSubmit = (evt) => {
        evt.preventDefault()
        setLoading(true)
        if (toolData.toolName.length > 0) {
            api.post(`/api/tools/tool/`, toolData,
              {headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Token ${token}`,
                  },
              })
              .then((response) => {
                  if (response.status === 201) {
                        setLoading(false)
                        if (returnResults !== null)
                            returnResults(response.data.data, response.data.message)
                        if (setTools !== null && tools !== null && handleCloseModal !== null) {
                            handleCloseModal();
                            setTools([...tools, response.data.data], response.data.message)
                        }
                        setToolData({
                            toolDescription: '',
                            toolName: ''
                        })
                      } 
                })
              .catch((error) => {
                setLoading(false)
                setRes({
                    message: error.response.data.error,
                    severity: "error",
                    show: false
                })
                  if (error.response.status === 404) {
                        throw new Error(error)
                  } else {
                      throw new Error(error)
                  }
              })
        }
    }

  return (
    <div className="modal-body scroll-y mx-5 mx-xl-15" data-select2-id="select2-data-78-jwvd">
        <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
			setRes({
				message: "",
				severity: "success",
				show: false
			})
		}}/>
												{/*begin::Form*/}
												<form id="kt_modal_new_card_form" className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#" data-select2-id="select2-data-kt_modal_new_card_form">
													{/*begin::Input group*/}
													<div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
														{/*begin::Label*/}
														<label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
															<span className="required">Tool Name</span>
															<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Specify a card holder's name" aria-label="Specify a card holder's name"></i>
														</label>
														{/*end::Label*/}
														<input type="text" className="form-control form-control-solid" placeholder="" name="toolName" value={toolData.toolName} onChange = {handleChange} />
													<div className="fv-plugins-message-container invalid-feedback"></div></div>
													<div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
														{/*begin::Label*/}
														<label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
															<span className="required">Total Tool Quantity</span>
															<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Specify a card holder's name" aria-label="Specify a card holder's name"></i>
														</label>
														{/*end::Label*/}
														<input type="number" className="form-control form-control-solid" placeholder="" name="toolQuantity" value={toolData.toolQuantity} onChange = {handleChange} />
													<div className="fv-plugins-message-container invalid-feedback"></div></div>
													<div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
														{/*begin::Label*/}
														<label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
															<span className="required">Tools Within Warehouse</span>
															<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Specify a card holder's name" aria-label="Specify a card holder's name"></i>
														</label>
														{/*end::Label*/}
														<input type="number" className="form-control form-control-solid" placeholder="" name="toolsWithinWarehouse" value={toolData.toolsWithinWarehouse} onChange = {handleChange} />
													<div className="fv-plugins-message-container invalid-feedback"></div></div>
													{/*end::Input group*/}
													{/*begin::Input group*/}
													<div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
														{/*begin::Label*/}
														<label className="fs-6 fw-bold form-label mb-2">Tool Description</label>
														{/*end::Label*/}
														{/*begin::Input wrapper*/}
														<div className="position-relative">
															{/*begin::Input*/}
															<textarea rows="5" cols="50" className="form-control form-control-solid" name="toolDescription" id="toolDescription" value={toolData.toolDescription} onChange={handleChange}></textarea>
															
														</div></div>
													<div className="text-center pt-15">
														<button type="reset" id="kt_modal_new_card_cancel" className="btn btn-light me-3">Discard</button>
														<button type="submit" id="kt_modal_new_card_submit" onClick={handleSubmit} className="btn btn-beysix" disabled={!loading ? false : true}>
															{!loading ? <span className="indicator-label">Submit</span>
															: <span className="indicator-progress">Please wait...
															<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
														</button>
													</div>
													{/*end::Actions*/}
												<div></div></form>
												{/*end::Form*/}
											</div>
  )
}
