import React,  { useState, useEffect }  from 'react'
import { useSelector } from 'react-redux'
import api from '../../../store/api'
import parseDateTimeStamp from '../../Utilities/parseDateTimeStamp.'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import BrandTable from '../../Brand/BrandTable/BrandTable'

export default function AgencyBrands() {
    const { id } = useParams()
    const [brandsList, setBrandsList] = useState([])
    const [pageLoading, setPageLoading] = useState(false)
    const [resultsFound, setResultsFound] = useState(true)
    useEffect(() => {
        setPageLoading(true)
        let url = `/api/agencies/agency-brands/${id}`
        if (user.user_type == 'client') {
          url = `/api/agencies/agency-brands/${id}/${organization.id}`
        }
        api.get(url,
        {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
        .then((response) => {
            setBrandsList(response.data)
            setPageLoading(false)
            if (response.data.length === 0)
                setResultsFound(false)
        })
        .catch((error) => {
            setPageLoading(false)
            throw new Error('Error getting data.')
        })
    }, [])

    const {organization, token, user} = useSelector((state) => {
      return {
        'organization': state.loginSlice.organization, 
        'token': state.loginSlice.token,
        'user': state.loginSlice.user
      }
    }); //

  return (
    <div id="kt_content_container" className="container-xxl">
    <div className="card mb-5 mb-xl-8">
    <BrandTable brandData={brandsList} loading={pageLoading} resultsFound={resultsFound} />
    </div>
</div>

  )
}
