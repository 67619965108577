import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

export default function RoleComponent(props) {
  
  const [role, setRole] = useState({
        id: 0,
        name: '',
        permissions: []
    })
  
  let isUpdateModalOpen = false
  const handleUpdateModal = () => {
    if (!isUpdateModalOpen) {
        props.handleUpdateModal(true)
        isUpdateModalOpen = true
        props.setCurrRole(props.role)
    } else {
        props.handleUpdateModal(false)
        isUpdateModalOpen = false
        props.setCurrRole(props.role)
    }
  }
    
  useEffect(() => {
    if (typeof props.role.permissions === 'string')
        props.role.permissions = JSON.parse(props.role.permissions);
    setRole(props.role)
  }, [])
    
  return (   
    <div>
        <div className="card card-flush h-md-100" style={{minHeight: "445px"}}>
                {/*begin::Card header*/}
                <div className="card-header">
                    {/*begin::Card title*/}
                    <div className="card-title">
                        <h2>{role.name}</h2>
                    </div>
                    {/*end::Card title*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body pt-1">
                    {/*begin::Users*/}
                    <div className="fw-bolder text-gray-600 mb-5">Total users with this role: {role.user_count}</div>
                    {/*end::Users*/}
                    {/*begin::Permissions*/}
                    <div className="permissions-listing-div d-flex flex-column text-gray-600">
                        {role.permissions.length > 0 ?
                            role.permissions.map((Permission, index) => {
                                return (
                                    <div className="d-flex align-items-center py-2" key={index}>
                                        <span className="bullet bg-beysix me-3"></span>{Permission.name.replace(/_/g, '')}</div>
                                )
                            })
                        :   <div className="d-flex align-items-center py-2">
                            <span className="bullet bg-primary me-3"></span>This role hasn't been assigned any permissions.</div>
                        }
                        </div>
                    </div>
                    {/*end::Permissions*/}
                {/*end::Card body*/}
                {/*begin::Card footer*/}
                <div className="card-footer flex-wrap pt-0">
                    <Link to={'/user-management/role/' + role.id} className="btn btn-light btn-active-primary my-1 me-2">View Role</Link>
                    <button type="button" className="btn btn-light btn-active-light-primary my-1"  onClick={handleUpdateModal}>Edit Role</button>
                </div>
                {/*end::Card footer*/}                
            </div>
    </div>  
  )
}
