import React, {useEffect, useState} from 'react'
import api from '../../../store/api'
import { useSelector } from 'react-redux';
import ACtivationsTable from '../../Activations/ActivationsTable/ActivationsTable';
import NoResults from '../../Utilities/NoResults';
import { motion } from 'framer-motion'
import { useParams } from 'react-router-dom';

export default function BrandActivations() {
	const token = useSelector((state) => state.loginSlice.token);
  const [activationsList, setActivationsList] = useState([])
  const [resultsFound, setResultsFound] = useState(null)
  const [pageLoading, setPageLoading] = useState(false)
  const [ogActivations, setOgActivations] = useState([])
  
  const [subsequentLoading, setSubsequentLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const {id} = useParams()
  
  const getActivations = () => {
    setPageLoading(true)
    api.get(`/api/brands/brand-activations/${id}/`,
        {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
        .then((response) => {
          if (response.status === 200) {
              if (response.data.results.length > 0){
                    setActivationsList(response.data.results)
                    setOgActivations(response.data.results)
                    setCount(response.data.count)
                    setResultsFound(true)
                    setTotalPages(response.data.total_pages)
              } else {
                  setActivationsList(response.data.results)
                  setResultsFound(false)
              }
          setPageLoading(false)
          }
        })
          .catch((error) => {
              throw new Error(error) 
          })
  }

  useEffect(() => {
    getActivations()
  }, [page])
    const permissions = useSelector((state) => state.loginSlice.permissions);

  return (
    <>
    {!pageLoading && <motion.p 
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.3 }} style={{fontWeight: '600'}}>{count} Activations Total</motion.p>}
      <ACtivationsTable activateScrollPagination = {false} activationsList={activationsList} pageLoading={pageLoading} resultsFound={resultsFound}  setPage={setPage} page={page} subsequentLoading={subsequentLoading}/>
    {resultsFound === false && <NoResults />}
    </>
  )
}


