import React, {useCallback, useState, useEffect} from 'react';
import { useDropzone } from 'react-dropzone';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import api from '../../../store/api'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux';

export default function UploadActivationMedia({handleUpdateActivationMedia}) {
    const token = useSelector((state) => state.loginSlice.token);
    const [activationImages, setActivationImages] = useState([])
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    
    const {id} = useParams()
    

    const handleSubmit = (evt) => {
        evt.preventDefault()
        if (activationImages.length > 0) {
            setLoading(true)
            
            const formData = new FormData();
            activationImages.forEach((file, index) => {
                formData.append(`images[${index}]`, file);
            });
            
            api.post(`/api/activations/upload-media/${id}/`, formData,
              {headers: {
                      'Content-Type': 'multipart/form-data',
                      Authorization: `Token ${token}`,
                  },
              })
              .then((response) => {
                  if (response.status === 201) {
                        const data = response.data.media
                        setLoading(false)
                        if (data !== null || data !== undefined)
                            handleUpdateActivationMedia(response.data.message, data)
                      } 
                })
              .catch((error) => {
                setLoading(false)
                setRes({
                    message: error.response.data.error,
                    severity: "error",
                    show: true
                })
            })
        }
    }
    
    const onDrop = useCallback((acceptedFiles) => {
		
    const newImages = acceptedFiles
        .filter(file => !activationImages.some(img => img.name === file.name))
        .map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }));
        setActivationImages(prevImages => {
            const updatedImages = [...prevImages, ...newImages];
            return updatedImages;
        });
        
    }, [activationImages, setActivationImages]);

    const [loading, setLoading] = useState(false)

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop
    });

    const handleRemoveImage = (index) => {
        setActivationImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };
  return (
    <div className="w-100">
        <div className="fv-row mt-3" style={{
            maxWidth: '100%',
            justifyContent: 'center',
            display: 'flex'
        }}>
        {/*begin::Dropzone*/}
        <div {...getRootProps({ className: 'dropzone' })} >
            {/*begin::Message*/}
            <div className="dz-message needsclick">
                {/*begin::Icon*/}
                <i className="bi bi-file-earmark-arrow-up text-primary-beysix fs-3x"></i>
                {/*end::Icon*/}
                <input autoComplete="off" {...getInputProps()} />
                {/*begin::Info*/}
                <div className="ms-4" style={{display: 'flex', alignItems: 'center'}}>
                    <h3 className="fs-5 ftw-bold text-gray-900 mb-1">Drop files here or click to upload.</h3>
                </div>
                {/*end::Info*/}
            </div>
        </div>
        {/*end::Dropzone*/}
        </div>
        {activationImages.length > 0 && <div className="ms-4" style={{margin: "20px 10px"}}><h3 className="fs-6 ftw-bold text-gray-900 mb-1">{`You have selected ${activationImages.length} images`}</h3></div>}
        <div className='upload-images-wrapper row' style={{maxHeight: '350px', overflow: 'auto'}}>
            {activationImages.map((file, index) => (
            <div key={index} className='single-image-wrapper col-md-3'>
                <div className='uploaded-image-overlay'></div>
                <div className='delete-uploaded-image' style={{right: '22%'}} onClick={() => handleRemoveImage(index)}>
                <span className='bi bi-x fs-3'></span>
                </div>
                <img 
                    className = 'uploaded-image' 
                    src={file.preview} 
                    alt={file.name}
                    style={{cursor: 'pointer'}} 
                    onClick={() => {
                        setPhotoIndex(index);
                        setIsOpen(true);
                    }}
                />
            </div>
            ))}
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'right'}} className='mt-3'>    
            <button onClick={handleSubmit} disabled={loading ? true : false} id="kt_new_password_submit" className="btn btn-sm btn-beysix fwt-bolder">
                {!loading && <span className="indicator-label">Submit</span>}
                {loading && <span className="indicator-progress">Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
            </button>
        </div>
        {isOpen && (
            <Lightbox
                mainSrc={activationImages[photoIndex].preview}
                nextSrc={activationImages[(photoIndex + 1) % activationImages.length].preview}
                prevSrc={activationImages[(photoIndex + activationImages.length - 1) % activationImages.length].preview}
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                    setPhotoIndex((photoIndex + activationImages.length - 1) % activationImages.length)
                }
                onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex + 1) % activationImages.length)
            }
            />
        )}
    </div>
  )
}
