export default function parseTimestamp(timestamp) {
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    const formattedTime = `${hours < 10 ? '0' + hours : hours}.${minutes < 10 ? '0' + minutes : minutes}`;

    const convertFurther = (date) => {
        const months = {
            '01': 'Jan',
            '02': 'Feb',
            '03': 'Mar',
            '04': 'Apr',
            '05': 'May',
            '06': 'Jun',
            '07': 'Jul',
            '08': 'Aug',
            '09': 'Sep',
            '10': 'Oct',
            '11': 'Nov',
            '12': 'Dec'
        }
        const index = date.indexOf('/');
        let chars = date.split('')
        let _chars = chars.splice(index + 1, 2).join('')
        const _month = months[_chars]
        let firstPart = chars.splice(0,index + 1)
        return firstPart.join('').concat(_month).concat(chars.join(''))
    }

    
    return `${convertFurther(formattedDate)}`;
}