import React, { useState, useEffect } from 'react'
import api from '../../../store/api'
import {useSelector} from 'react-redux'

export default function UpdateRole(props) {

    const [role, setRole] = useState({
        id: 0,
        name: '',
        permissions: []
    })

    const [removed_permissions, setRemoved_permissions] = useState([])
    const [permissions, setPermissions] = useState([])
    const [groupedPermissions, setGroupedPermissions] = useState(props.groupedPermissions)
    const token = useSelector((state) => state.loginSlice.token);
    const [loading, setLoading] = useState(false)
    const [ogPermissions, setOgPermissons] = useState([])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRole((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleCheckboxChange = (name) => {
       const selectedPerms = [...permissions];
       const removedPermissions = [...removed_permissions];
       let found = false;
       for (let i = 0; i < selectedPerms.length; i++) {
            if (selectedPerms[i] === name) {
                selectedPerms.splice(i,1);
                if (removedPermissions.indexOf(name) === -1)
                    removedPermissions.push(name)
                found = true
                break
            }
       }
       
       if (found === false && selectedPerms.indexOf(name) === -1)
            selectedPerms.push(name)

       setRemoved_permissions(removedPermissions)
       setPermissions(selectedPerms)
    };


    const handleSubmit = async (evt) => {
        evt.preventDefault()
        setLoading(true)
        setRole((prevFormData) => ({
            ...prevFormData,
            permissions: permissions
        }));
        
        const response = await api.put(`/api/users/update-role/${role.id}/`, {
            'role_name': role.name,
            'removed_permissions': removed_permissions,
            'selected_permissions': permissions
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
        }) 

        if (response.status === 200) {
            const mes = response.data.message
            props.handleSuccess({
                message: mes,
                severity: "success",
                show: true
            })
            props.handleClose()
            setLoading(false)
        } else {
            const mes = response.data.error
            setRes({
                message: mes,
                severity: "error",
                show: true
            })
            setLoading(false)
        }
    }
    
    const handleToggleAll = (evt) => {
        const checked = evt.target.checked
        if (checked) {
            const allSelectedPermissions = []
            for (let key in groupedPermissions){
                for (let i = 0; i < groupedPermissions[key].length; i++) {
                    allSelectedPermissions.push(groupedPermissions[key][i].name)
                }
            }
            setPermissions(allSelectedPermissions)
        } else {
        setRemoved_permissions([...permissions])
        setPermissions([])
        }
    }

    const handleOgPermissions = () => {
        let perms = []
        for (let key in groupedPermissions) {
            const _g = groupedPermissions[key]
            for (let _p = 0; _p < _g.length; _p++){
                perms.push(_g[_p].name)
            }
        }
        setOgPermissons(perms)
    }

    useEffect(() => {
        handleOgPermissions()
    }, [])
    
    useEffect(() => {
        const permissionsArr = [];
        if (props.role.permissions.length > 0) {            
            for (let i = 0; i < props.role.permissions.length; i++) {
                if (props.role.permissions[i].name !== undefined) {
                    permissionsArr.push(props.role.permissions[i].name)
                }
            }
        }
        setPermissions(permissionsArr)
        setRole(props.role)
        setGroupedPermissions(props.groupedPermissions)
    }, [props.role, props.groupedPermissions])

  return (
        <form id="kt_modal_update_role_form" className="form fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit}>
                    {/*begin::Scroll*/}
                    <div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_update_role_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_update_role_header" data-kt-scroll-wrappers="#kt_modal_update_role_scroll" data-kt-scroll-offset="300px" style={{maxHeight: "476px"}}>
                        {/*begin::Input group*/}
                        <div className="fv-row mb-10 fv-plugins-icon-container">
                            {/*begin::Label*/}
                            <label className="fs-5 fwt-bolder form-label mb-2">
                                <span className="required">Role name</span>
                            </label>
                            {/*end::Label*/}
                            {/*begin::Input*/}
                            <input className="form-control form-control-solid"  name="name" onChange={handleInputChange} value={role.name} />
                            {/*end::Input*/}
                        <div className="fv-plugins-message-container invalid-feedback"></div></div>
                        {/*end::Input group*/}
                        {/*begin::Permissions*/}
                        <div className="fv-row">
                            {/*begin::Label*/}
                            <label className="fs-5 fwt-bolder form-label mb-2">Role Permissions</label>
                            {/*end::Label*/}
                            {/*begin::Table wrapper*/}
                            <div className="table-responsive">
                                {/*begin::Table*/}
                                <table className="align-middle table-row-dashed fs-6 gy-5">
                                    {/*begin::Table body*/}
                                    <tbody className="text-gray-600 fwt-bold">
                                        {/*begin::Table row*/}
                                        <tr>
                                            <td className="text-gray-800">Administrator Access
                                            <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Allows a full access to the system" aria-label="Allows a full access to the system"></i></td>
                                            <td>
                                                {/*begin::Checkbox*/}
                                                <label className="form-check form-check-sm form-check-custom form-check-solid me-9">
                                                    <input className="form-check-input" type="checkbox" checked={ogPermissions.length === permissions.length} id="kt_roles_select_all" onChange={handleToggleAll} />
                                                    <span className="form-check-label" htmlFor="kt_roles_select_all">Select all</span>
                                                </label>
                                                {/*end::Checkbox*/}
                                            </td>
                                        </tr>
                                        {/*end::Table row*/}
                                        {/*begin::Table row*/}
                                        {Object.keys(groupedPermissions).map((key, index) => (
                                        <tr key={index}>
                                            {/*begin::Label*/}
                                            <td className="text-gray-800" style={{display: "flex"}}>Manage {key}</td>
                                            {/*end::Label*/}
                                            {/*begin::Options*/}
                                            <td>
                                                {/*begin::Wrapper*/}
                                                <div className="d-flex" style={{flexWrap: "wrap"}}>
                                                    {/*begin::Checkbox*/}
                                                    {groupedPermissions[key].map((Permission, index) => (
                                                        <label style={{flexBasis: "20%", marginTop: "12px", marginRight: "19px"}} className="form-check form-check-sm form-check-custom form-check-solid" key={index}>
                                                            <input className="form-check-input" 
                                                            type="checkbox" 
                                                            value={Permission.name} 
                                                            name={Permission.codename} 
                                                            onChange={() => handleCheckboxChange(Permission.name)}
                                                            checked={permissions.includes(Permission.name)}
                                                            />
                                                            {Permission.name.toLowerCase().includes("view") ? (
                                                                <span className="form-check-label">View</span>
                                                            ) : Permission.name.toLowerCase().includes("add") ? (
                                                                <span className="form-check-label">Create</span>
                                                            ) : Permission.name.toLowerCase().includes("change") ? (
                                                                <span className="form-check-label">Edit</span>
                                                            ) : Permission.name.toLowerCase().includes("delete") ? (
                                                                <span className="form-check-label">Delete</span>
                                                            ) : <span className="form-check-label">{Permission.name}</span>}
                                                        </label>
                                                    ))}
                                                </div>
                                                {/*end::Wrapper*/}
                                            </td>
                                            {/*end::Options*/}
                                        </tr>))
                                        }
                                        {/*end::Table row*/}
                                    </tbody>
                                    {/*end::Table body*/}
                                </table>
                                {/*end::Table*/}
                            </div>
                            {/*end::Table wrapper*/}
                        </div>
                        {/*end::Permissions*/}
                    </div>
                    {/*end::Scroll*/}
                    {/*begin::Actions*/}
                    <div className="text-center pt-15">
                        <button type="reset" className="btn btn-light me-3" data-kt-roles-modal-action="cancel">Discard</button>
                        <button type="submit" className="btn btn-beysix" data-kt-roles-modal-action="submit">
                            { !loading ? <span className="indicator-label">Submit</span>
                              : <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
                        </button>
                    </div>
                <div></div></form>
  )
}
