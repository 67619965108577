import React, {useEffect, useState} from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Sample Data
const data = [
  { name: 'Jan', value: 30 },
  { name: 'Feb', value: 40 },
  { name: 'Mar', value: 20 },
  { name: 'Apr', value: 27 },
  { name: 'May', value: 50 },
  { name: 'Jun', value: 70 },
  { name: 'Jul', value: 60 },
];

const SimpleLineChart = ({ _data, _name = null, _value = null}) => {

  const [data, setData] = useState(false)
  useEffect(() => {
    setData(_data)
  }, [_data])

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
            dataKey={_name ? _name : "name"} 
            angle={-40}  // Rotate labels
            textAnchor="end"  // Align the labels to the end
            height={170} // Adjust height to avoid overlapping
          />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SimpleLineChart;
