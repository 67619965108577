import React from 'react'

export default function AssertiveModal({show, promptText = '', btnPrimary = {show: false, text: ''}, btnSecondary = {show: false, text: ''}, handleActionBtnClick}) {
    
    const handlePrimaryBtnClick = () => {
        handleActionBtnClick(true); 
    };

    const handleSecondaryBtnClick = () => {
        handleActionBtnClick(false); 
    };

   return (
    <div className={show ? "modal-backdrop fade show": "modal-backdrop"} aria-hidden="true" style={!show ? {display: 'none'} : null}>
        <div className="swal2-container swal2-center" style = {show ? {display: "grid"} : {display: "none"}}>
            <div aria-labelledby="swal2-title" aria-describedby="swal2-html-container" style={{display: "block"}} className="swal2-popup swal2-modal swal2-icon-warning swal2-show" tabIndex="-1" role="dialog" aria-live="assertive" aria-modal="true">
            <div className="swal2-icon swal2-warning swal2-icon-show" style={{ display: "flex"}}>
                <div className="swal2-icon-content">!</div>
            </div>
            <div className="swal2-html-container" id="swal2-html-container" style={{display: "block"}}>
                {promptText}</div>
            <div className="swal2-actions" style={{display: "flex"}}>
               {btnPrimary.show && <button type="button" close-attr = 'true' onClick={handlePrimaryBtnClick} className="swal2-confirm btn btn-beysix" aria-label="" style={{display: "inlineBlock"}}>{btnPrimary.text}</button>}
               {btnSecondary.show && <button type="button" close-attr = 'false' onClick={handleSecondaryBtnClick} className="swal2-cancel btn btn-active-light" aria-label="" style={{display: "inlineBlock"}}>{btnSecondary.text}</button>}
            </div>
        </div>
        </div>
    </div>
  )
}
