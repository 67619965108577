import React from 'react';
import HeaderBtn from '../LayoutComponents/HeaderBtn';
import MegaMenu from '../LayoutComponents/MegaMenu';
import DropDown from '../LayoutComponents/DropDown';
import ScaffoldDropDown from '../LayoutComponents/ScaffoldDropDown';
import SearchButton from '../LayoutComponents/SearchButton';
import ActivityLogButton from '../LayoutComponents/ActivityLogButton';
import NotificationsButton from '../LayoutComponents/NotificationsButton';
import ChatsButton from '../LayoutComponents/ChatsButton';
import UserProfileButton from '../LayoutComponents/UserProfileButton';
import Toolbar from '../Toolbar/Toolbar';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function Header() {

    const {organization, token, user} = useSelector((state) => {
        return {
            'organization': state.loginSlice.organization, 
            'token': state.loginSlice.token,
            'user': state.loginSlice.user
        }
    });

  return (
    <div id="kt_header" className="header align-items-stretch">
    <div className="container-fluid d-flex align-items-stretch justify-content-between">
    {user.user_type === 'client' && <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
        <Link to = {`/asset-dashboard`}>
        <div className="symbol symbol-60px me-3" style={{width: '60px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {organization.profile_image && organization.profile_image !== '' ? 
            <img src={organization.profile_image} className="h-100 align-self-end" alt={organization.name} style={{width: '100%', height: '100%', borderRadius: '5px'}} />
            :
            <span className="svg-icon svg-icon-3x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path opacity="0.3" d="M8.9 21L7.19999 22.6999C6.79999 23.0999 6.2 23.0999 5.8 22.6999L4.1 21H8.9ZM4 16.0999L2.3 17.8C1.9 18.2 1.9 18.7999 2.3 19.1999L4 20.9V16.0999ZM19.3 9.1999L15.8 5.6999C15.4 5.2999 14.8 5.2999 14.4 5.6999L9 11.0999V21L19.3 10.6999C19.7 10.2999 19.7 9.5999 19.3 9.1999Z" fill="black"></path><path d="M21 15V20C21 20.6 20.6 21 20 21H11.8L18.8 14H20C20.6 14 21 14.4 21 15ZM10 21V4C10 3.4 9.6 3 9 3H4C3.4 3 3 3.4 3 4V21C3 21.6 3.4 22 4 22H9C9.6 22 10 21.6 10 21ZM7.5 18.5C7.5 19.1 7.1 19.5 6.5 19.5C5.9 19.5 5.5 19.1 5.5 18.5C5.5 17.9 5.9 17.5 6.5 17.5C7.1 17.5 7.5 17.9 7.5 18.5Z" fill="black"></path></svg></span>
            }
        </div>
        </Link>
        <div className="fs-4 fw-bolder text-black">{organization.name}</div>
    </div>}
  <div className="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
    <div className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px" id="kt_aside_mobile_toggle">
        <span className="svg-icon svg-icon-2x mt-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black"></path>
                <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black"></path>
            </svg>
        </span>
    </div>
    </div>
    <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
        <a href="../../demo1/dist/index.html" className="d-lg-none">
            <img alt="Beysix Logo" src="/static/assets/media/logos/beysix-logo.png" className="h-60px logo" />
        </a>
    </div>

    <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
        <div className="d-flex align-items-stretch" id="kt_header_nav">
            <div className="header-menu align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_header_menu_mobile_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
            <div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fwt-bold my-5 my-lg-0 align-items-stretch" id="#kt_header_menu" data-kt-menu="true">
            {/* <HeaderBtn name="Profile" route="/profile" /> */}
            {/* <MegaMenu /> */}
            {/* <DropDown /> */}
            {/* <ScaffoldDropDown /> */}
            </div>
        </div>
    </div>
    </div>
    
    <div className="d-flex align-items-stretch flex-shrink-0">
    <div className="d-flex align-items-stretch flex-shrink-0">
        {/* <SearchButton />
        <ActivityLogButton />
        <NotificationsButton />
        <ChatsButton /> */}
        <UserProfileButton />
    </div>
    </div>
    </div>
    </div>
  )
}
