import React, {useState, useEffect} from 'react'
import WarehouseTable from '../WarehouseTable/WarehouseTable'
import api from '../../../store/api'
import { useSelector } from 'react-redux'
import NoResults from '../../Utilities/NoResults'
import { motion } from 'framer-motion'

export default function WarehouseList() {

    const [warehouses, setWarehouses] = useState([])
    const [ogWarehouses, setOgWarehouses] = useState([])
    const [pageLoading, setPageLoading] = useState(false)
    const token = useSelector((state) => state.loginSlice.token);
    const [count, setCount] = useState(0)

    useEffect(() => {
        setPageLoading(true)
        api.get('/api/warehouses/',
            {headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
            }
        )
        .then((response) => {
            if (response.status === 200) {
                if (response.data.results.length > 0) {
                    setWarehouses(response.data.results)
                    setCount(response.data.count)
                    setOgWarehouses(response.data.results)
                    setResultsFound(true)
                } else {
                    setResultsFound(false)
                }
                setPageLoading(false)
            }
        })
        .catch((error) => {
            setPageLoading(false)
            throw new Error(error)
        })
    }, [])

    const handleSearch = (evt) => {
        evt.preventDefault()
        const value = evt.target.value
        setSearchQuery(value)
        if (value.length > 2) {
            api.post(`/api/warehouses/search-warehouses/detailed/`, {searchQuery: value},
                {headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${token}`,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.results.length > 0){
                            setWarehouses(response.data.results)
                            setResultsFound(true)
                        } else {
                            setResultsFound(false)
                        }
                    }
                })
                .catch((error) => {
                    if (response.status === 404) {

                    } else {
                        throw new Error(error)
                    }
                })
        } else {
            setWarehouses(ogWarehouses)
            if (ogWarehouses.length > 0)
                setResultsFound(true)
        }
    }

    const [resultsFound, setResultsFound] = useState(null)
    const [searchQuery, setSearchQuery] = useState('')

  return (
    <>
        {!pageLoading && <motion.p 
                            initial={{ y: 100, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ duration: 0.3 }}style={{fontWeight: '600'}}>{count} Warehouses Total</motion.p>}
        <WarehouseTable warehouses={warehouses}  pageLoading={pageLoading} handleSearch={handleSearch} resultsFound={resultsFound} searchQuery={searchQuery}/>
        {(resultsFound === false && !pageLoading) && <NoResults />}
    </>
  )
}
