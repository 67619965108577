import React, { useState, useEffect } from 'react';
import api from '../../store/api';
import { useSelector, useDispatch } from 'react-redux';
import { resetLoginState, setRedirectPath } from '../../store/loginSlice';
import RedirectHandler from '../RedirectHandler/RedirectHandler'
import { NavLink } from 'react-router-dom';
import findSetting from '../Utilities/findSetting';
import { updateUserSettings } from '../../store/loginSlice';
import StaffAside from './StaffAside';
import ClientAside from './ClientAside';


export default function Aside() {

	const dispatch = useDispatch();
	const token = useSelector((state) => state.loginSlice.token);
	const permissions = useSelector((state) => state.loginSlice.permissions);
	const user = useSelector((state) => state.loginSlice.user);
	const settings = useSelector((state) => state.loginSlice.userSettings);
	const [asideSkin, setAsideSkin] = useState('aside-light')

	const handleAsideSkinToggle = (evt) => {
		const checked = evt.target.checked;
		let skin;
		if (checked) {
			skin = 'aside-dark';
			setAsideSkin(skin)
		} else {
			skin = 'aside-light'
			setAsideSkin(skin)
		}
		api.post('/api/users/set-user-setting/', {setting: "aside-theme", value: skin},
			{headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
		.then((response) => {
			dispatch(updateUserSettings(response.data.settings));
		})
		.catch((error) => {
			throw new Error(error)
		})
	}

	useEffect(() => {
		if (settings){
			const set = findSetting(settings, 'aside-theme')
			if (set !== undefined)
				setAsideSkin(set)
		}
	}, [settings])

  return (
				<div id="kt_aside" className={`aside ${asideSkin} aside-hoverable`} data-kt-drawer="true" data-kt-drawer-name="aside" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_mobile_toggle">
					<div className="aside-logo flex-column-auto" id="kt_aside_logo">
						<a href="../../demo1/dist/index.html">
							<img alt="Beysix Logo" src="/static/assets/media/logos/beysix-logo.png" className="h-60px logo" />
						</a>
						<div className='d-flex'>
						<label style={{width: "35px"}} className="form-check form-switch form-check-custom form-check-solid pulse pulse-success" htmlFor="kt_user_menu_dark_mode_toggle">
							<input className="form-check-input w-30px h-20px dark-aside-toggle" type="checkbox" checked={asideSkin === 'aside-dark'} name="mode" onChange={handleAsideSkinToggle} />
						</label>
						<div id="kt_aside_toggle" className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="aside-minimize">
							<span className="svg-icon svg-icon-1 rotate-180">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path opacity="0.5" d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z" fill="black" />
									<path d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z" fill="black" />
								</svg>
							</span>
						</div>
						</div>
					</div>
					<div className="aside-menu flex-column-fluid">
					{user.user_type === 'staff' ? <StaffAside />
					: user.user_type === 'client' ? <ClientAside /> : null}
					<div className='pattern-bg-image'></div>
					</div>
                    {/* <div className="aside-footer flex-column-auto pt-5 pb-7 px-3" id="kt_aside_footer">
                    <a  className="btn btn-custom btn-beysix w-100" onClick={handleLogout}>
                        <span className="btn-label">Sign Out Of Your Account</span>
                        <span className="svg-icon btn-icon svg-icon-2">
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect opacity="0.3" width="12" height="2" rx="1" transform="matrix(-1 0 0 1 15.5 11)" fill="black"/>
						<path d="M13.6313 11.6927L11.8756 10.2297C11.4054 9.83785 11.3732 9.12683 11.806 8.69401C12.1957 8.3043 12.8216 8.28591 13.2336 8.65206L16.1592 11.2526C16.6067 11.6504 16.6067 12.3496 16.1592 12.7474L13.2336 15.3479C12.8216 15.7141 12.1957 15.6957 11.806 15.306C11.3732 14.8732 11.4054 14.1621 11.8756 13.7703L13.6313 12.3073C13.8232 12.1474 13.8232 11.8526 13.6313 11.6927Z" fill="black"/>
						<path d="M8 5V6C8 6.55228 8.44772 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 10.4477 5 11 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H11C10.4477 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H10C8.89543 3 8 3.89543 8 5Z" fill="#C4C4C4"/>
						</svg>


                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM15 17C15 16.4 14.6 16 14 16H8C7.4 16 7 16.4 7 17C7 17.6 7.4 18 8 18H14C14.6 18 15 17.6 15 17ZM17 12C17 11.4 16.6 11 16 11H8C7.4 11 7 11.4 7 12C7 12.6 7.4 13 8 13H16C16.6 13 17 12.6 17 12ZM17 7C17 6.4 16.6 6 16 6H8C7.4 6 7 6.4 7 7C7 7.6 7.4 8 8 8H16C16.6 8 17 7.6 17 7Z" fill="black" />
                                <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
                            </svg>
                        </span>
                    </a>
                </div> */}
				<RedirectHandler />
                </div>
  )
}
