import React, {useState, useEffect} from 'react'
import api from '../../../store/api'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import FeedbackModal from '../../Utilities/FeedbackModal'
import BulkAssetSearchComponent from '../../Utilities/BulkAssetSearchComponent'
import truncateText from '../../Utilities/truncateText'

export default function EditWarehouseZone({setData, zoneId, index}) {

    const [zoneData, setZoneData] = useState({
        zoneName: '',
        zoneCapacity: '',
        zoneDescription: '',
        newAssets: [],
        removedAssets: [],
        assets: []
    })
    const { id } = useParams()
    const token = useSelector((state) => state.loginSlice.token);
    const handleChange = (evt) => {
        evt.preventDefault()
        const target = evt.target;
        const name = target.name;
        const value = target.value
        setZoneData({...zoneData, [name]:value})
    }

    const [selectedAssets, setSelectedAssets] = useState([])

    const [errors, setErrors] = useState({
        zoneNameError: '',
        zoneCapacityError: '',
        zoneDescriptionError: '',
        zoneAssetError: 0
    })

    const handleValidate = () => {
        if (zoneData.zoneName === '') {
            setErrors({...errors, zoneNameError: 'Zone name cannot be empty.'})
            return false
        }
        return true
    }

    const getZoneData = () => {
        api.get(`/api/warehouses/zones/${id}/${zoneId}/`,
        {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
        .then((response) => {
            const zone = response.data
            setZoneData({
                zoneName: zone.zone_name,
                zoneCapacity: zone.zone_capacity,
                zoneDescription: zone.zone_description,
                newAssets: [],
                removedAssets: [],
                assets: zone.selected_assets.map(asset => asset.id)
            })
            setSelectedAssets(zone.selected_assets)
            setSearchQuery(zone.asset_name)
        })
        .catch((error) => {
            throw new Error('Error getting data.')
        })
    }

    const handleRemoveAsset = (indexToRemove, assetId) => {
		setSelectedAssets(prevAssets => prevAssets.filter((_, index) => index !== indexToRemove));
		const _assetIds = zoneData.assets
        let i = _assetIds.indexOf(assetId)
        if (i !== -1) {
            const removedAsset = _assetIds.splice(i, 1)
            const _removedAssets = zoneData.removedAssets
            _removedAssets.push(removedAsset[0]) 
            setZoneData({...zoneData, removedAssets: _removedAssets})
        }
    }

    const [searchQuery, setSearchQuery] = useState('')

    useEffect(() => {
        if (zoneId === null) {
            setZoneData({
                zoneName: '',
                zoneCapacity: '',
                zoneDescription: '',
                zoneAsset: 0
            })
        } else {
            getZoneData()
        }
    }, [zoneId])

    const handleSubmit = () => {
        if (handleValidate())
            setLoading(true)
            api.put(`/api/warehouses/zones/${id}/${zoneId}/`, zoneData,
                        {headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${token}`,
                },
            })
            .then((response) => {
                setLoading(false)
                setData(response.data.data, response.data.message, index)
                setErrors({
                    zoneNameError: '',
                    zoneCapacityError: '',
                    zoneDescriptionError: '',
                    zoneAssetError: 0
                })
                setZoneData({
                    zoneName: zone.zone_name,
                    zoneCapacity: zone.zone_capacity,
                    zoneDescription: zone.zone_description,
                    zoneAsset: 0
                })
                setSearchQuery(zone.asset_name)
            })
            .catch((error) => {
                setLoading(false)
                setRes({
                    message: error.response.data.error,
                    severity: "error",
                    show: true
                })
            })
    }

    const [loading, setLoading] = useState(false)
    const [successfullSubmission, setsuccessfullSubmission] = useState(false)
    const [res, setRes] = useState({
        message: "",
        severity: "success",
        show: false
    })

  return (
    <>
        <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
            setRes({
                message: "",
                severity: "success",
                show: false
            })
        }}/>
        <div className='row'>
            <div className="mb-2 col-md-12">
                <label for="" className="form-label">Zone Name</label>
                <input className="form-control form-control-solid" name='zoneName' type="text" value={zoneData.zoneName} onChange={handleChange} />
                <div className="fv-plugins-message-container invalid-feedback">{errors.zoneNameError}</div>
            </div>
            {/* <div className="mb-2 col-md-6">
                <label for="" className="form-label">Zone Capacity</label>
                <input className="form-control form-control-solid" name='zoneCapacity' type="number" min="1" value={zoneData.zoneCapacity} onChange={handleChange} />
                <div className="fv-plugins-message-container invalid-feedback"></div>
            </div> */}
            <div className="mb-2 col-md-12">
                <BulkAssetSearchComponent returnResults = {(asset) => {
                            const data = zoneData
                            const assets = data.newAssets
                            assets.push(asset.id)
                            setZoneData(data)
                            setSelectedAssets([...selectedAssets, asset])
                        }}
                successfullSubmission={successfullSubmission} />
            </div>
            <div style={{maxHeight: '180px', overflow: 'auto', paddingBottom: '15px'}}>
                {selectedAssets.map((asset, index) => { 
                    return (<div className="alert alert-beysix d-flex align-items-center mb-2"><div className="d-flex">
                        <div className="d-flex">
                            <div className="asset-item-image-wrapper">
                                {asset.first_image &&  asset.first_image !== '' ? 
                                <img className="asset-item-image" src={asset.first_image.image_url} /> :
                                <div className='item-initial'>
                                    <span>{asset.asset_name[0]}</span>
                                </div>
                                }
                            </div>
                            <div className="d-flex flex-column" style={{justifyContent: 'center', maxWidth: '75%', textAlign: 'justify'}}>
                                <h5 className="mb-1">{asset.asset_name}</h5><span>{truncateText(asset.asset_description, 50)}</span>
                            </div>
                        </div>
                        <div className="cancel-item-btn" onClick={() => handleRemoveAsset(index, asset.id)}>
                            <span className="bi bi-x fs-3 cancel-icon"></span>
                        </div>
                    </div>
                </div>)})}
            </div>
            <div className="fv-row fv-plugins-icon-container col-md-12">
            <label className="mb-7 d-flex align-items-center fs-6 fw-bold form-label mb-2">Zone Description</label>
                <textarea name="zoneDescription" rows="5" cols="30" className='form-control form-control-solid' value={zoneData.zoneDescription} onChange={handleChange}></textarea>
            </div>
        </div>
        <div style={{marginTop: '30px', display: 'flex', justifyContent: 'end'}}>
        <button type="button" onClick={handleSubmit} className="btn btn-beysix me-3" disabled={loading ? true : false}>
        {!loading ? <span className="indicator-label">Submit
        {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
        <span className="svg-icon svg-icon-3 ms-2 me-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect>
                <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path>
            </svg>
        </span>
        {/*end::Svg Icon*/}</span> : 
        <span className="indicator-progress">Please wait...
        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
        </button>
        </div>
    </>
  )
}
