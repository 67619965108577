import React from 'react'
import UserBRow from './UserBRow';

const UserTBody = ({ userList, user_stat, token, actions, checkbox, roles, _selected, handleSelectChange, clients }) => {
    
  
    return (
      <>
      {userList.length > 0 ?
      <tbody className="text-gray-600 fwt-bold">
        {userList.map((User, index) => {
          return (
            <UserBRow 
                roles={roles} 
                clients={clients}
                token={token} 
                user={User} 
                key={index} 
                index={index} 
                user_stat={user_stat} 
                actions={actions} 
                checkbox={checkbox}
                selected={_selected.includes(User.id)} 
                handleSelectChange={handleSelectChange}
              />
          );
        })}
      </tbody> :
        <p style={{
          display: "table-caption",
          margin: "30px"
      }}>No users where found</p>
      }
      </>
    );
  };
  
  export default UserTBody;
