import React, {useState, useEffect} from 'react'
import MainModal from '../../Modals/MainModal'
import CreateWarehouseZone from '../../WarehouseZones/CreateWarehouseZone/CreateWarehouseZone'
import api from '../../../store/api'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import parseDateTimestamp from '../../Utilities/parseDateTimeStamp.'
import { Link } from 'react-router-dom'
import CreateOneWarehouseZone from '../../WarehouseZones/CreateOneWarehouseZone/CreateOneWarehouseZone'
import FeedbackModal from '../../Utilities/FeedbackModal'
import EditWarehouseZone from '../../WarehouseZones/EditWarehouseZone/EditWarehouseZone'
import Table from '../../WarehouseZones/Table/Table'
import AssertiveModal from '../../Utilities/AssertiveModal'

export default function WarehouseZones({active}) {
  
	const permissions = useSelector((state) => state.loginSlice.permissions);
  const [auWZonesModal, setAuWZonesModal] = useState(false)
  const [zones, setZones] = useState([])
  const [createZoneModal, setCreateZoneModal] = useState(false)
  const [detailId, setDetailId] = useState(0)
  const token = useSelector((state) => state.loginSlice.token);
  const { id } = useParams()
    
  const getZones = () => {
      api.get(`/api/warehouses/zones/${id}/`,
      {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
      .then((response) => {
        setZones(response.data)
      })
      .catch((error) => {
          throw new Error('Error getting data.')
      })
  }
  useEffect(() => {
    getZones()
  }, [active])

  const [res, setRes] = useState({
    message: "",
    severity: "success",
    show: false
  })

  const [editIndex, setEditIndex] = useState(null)
  const [editId, setEditId] = useState(null)
  const [editZoneModal, setEditZoneModal] = useState(false)
  const [detailedModal, setDetailedModal] = useState(false)
  const [zoneName, setZoneName] = useState('')

  const handleUpdateState = (index) => {
    const zoneData = zones
    zoneData[index].zone_occupied = !zoneData[index].zone_occupied
    setZones(zoneData)
  }

  const updateOccupiedStatus = (status, zoneId, index) => {
    if (status === true){
      api.post(`/api/warehouses/zones/toggle-occupy-status/${zoneId}/`, {},
        {headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
      })
      .then((response) => {
        setShowAssertiveModal({
          show: false,
          promptText: '',
          btnPrimary: {
            show: false,
            text: ''
          },
          btnSecondary: {
            show: false,
            text: ''
          },
          actionBtnFn: null
        })

        setRes({
          message: response.data.message,
          severity: "success",
          show: true
        })

        handleUpdateState(index)
      })
      .catch((error) => {
        console.error(error)
      })
    } else {
      setShowAssertiveModal({
        show: false,
        promptText: '',
        btnPrimary: {
          show: false,
          text: ''
        },
        btnSecondary: {
          show: false,
          text: ''
        },
        actionBtnFn: null
      })
    }
  }

  const [showAssertiveModal, setShowAssertiveModal] = useState(
    {
      show: false,
      promptText: '',
      btnPrimary: {
        show: false,
        text: ''
      },
      btnSecondary: {
        show: false,
        text: ''
      },
      actionBtnFn: null
    }
  )

  const [index, setIndex] = useState(0)
  const [occupied, setOccupied] = useState(false)
  const handleOccupyBtn = (zone, index) => {
    setShowAssertiveModal({
        show: true,
        promptText: zone.zone_occupied ? 
            `Do you want to mark ${zone.zone_name} unoccupied?` : 
            `Do you want to mark ${zone.zone_name} occupied?`,
        btnPrimary: {
            show: true,
            text: 'Yes, Go Ahead!'
        },
        btnSecondary: {
            show: true,
            text: 'No, Cancel.'
        },
        actionBtnFn: (status) => updateOccupiedStatus(status, zone.id, index)
    });
};


  return (
    <>
    <AssertiveModal
					show = {showAssertiveModal.show}
					promptText = {showAssertiveModal.promptText}
					btnPrimary = {{show: showAssertiveModal.btnPrimary.show, text: showAssertiveModal.btnPrimary.text}}
					btnSecondary = {{show: showAssertiveModal.btnSecondary.show, text: showAssertiveModal.btnSecondary.text}}
					handleActionBtnClick = {showAssertiveModal.actionBtnFn}
    />
    <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
            setRes({
                message: "",
                severity: "success",
                show: false
            })
      }}/>
    <MainModal 
        handleClose={() => setAuWZonesModal(false)} 
        show={auWZonesModal}
        modalTitle='Auto create warehouse zones'>
        <CreateWarehouseZone setData = {
          (data, message) => {
            let _zoneData = zones
            _zoneData = [..._zoneData, ...data]
            setZones(_zoneData)
            setAuWZonesModal(false)
            setRes({
              message: message,
              severity: "success",
              show: true
            })
          }} />
    </MainModal>
    <MainModal 
        handleClose={() => setCreateZoneModal(false)} 
        show={createZoneModal}
        modalTitle='Create warehouse zone'>
        <CreateOneWarehouseZone setData = {
          (data, message) => {
              const _zoneData = zones
              _zoneData.push(data)
              setZones(_zoneData)
              setCreateZoneModal(false)
              setRes({
                message: message,
                severity: "success",
                show: true
              })
          }}/>
    </MainModal>
    <MainModal 
        handleClose={() => {
          setEditZoneModal(false)
          setEditId(null)
          setEditIndex(null)
        }} 
        show={editZoneModal}
        modalTitle='Edit warehouse zone'>
          <EditWarehouseZone setData = {(data, message, index) => {
           const _zoneData = [...zones]; 
           _zoneData[index] = data; 
           setZones(_zoneData);
           setEditZoneModal(false);
           setEditId(null)
           setEditIndex(null)
           setRes({
             message: message,
             severity: "success",
             show: true
           });
          }} index={editIndex} zoneId={editId} />
    </MainModal>
    <MainModal 
        handleClose={() => {
          setDetailedModal(false)
        }} 
        show={detailedModal}
        modalTitle={`${zoneName} Details`}
        noPadding={true}
        width='80vw' >
         <Table id = {detailId} occupied={occupied} handleOccupyBtn={handleOccupyBtn} index={index} zoneName={zoneName} handleUpdateState={handleUpdateState} />
    </MainModal>
    {active &&
      <>
      <div className="card mb-5 mb-xl-8">
      {/*begin::Header*/}
      {permissions['add_warehousezone'] && <div className="card-header border-0 pt-5" style={{justifyContent: 'end'}}>
        <div className="warehouse-zone-create-btns" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="" data-bs-original-title="Click to add a user">
          <a onClick={() => setAuWZonesModal(true)} className="btn btn-sm btn-beysix me-2">Auto Create Zones</a>
          <a className="btn btn-sm btn-light btn-active-primary" onClick={() => setCreateZoneModal(true)}>
          {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
          <span className="svg-icon svg-icon-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="black"></rect>
              <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="black"></rect>
            </svg>
          </span>Create One Zone</a>
        </div>
      </div>}
      {/*end::Header*/}
      {/*begin::Body*/}
      <div className="card-body py-3">
        {/*begin::Table container*/}
        <div className="table-responsive">
          {/*begin::Table*/}
          <table className="table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            {/*begin::Table head*/}
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="min-w-150px">Zone Name</th>
                <th className="min-w-140px">Asset</th>
                <th className="min-w-120px">Project</th>
                <th className="min-w-120px">Brand</th>
                <th className="min-w-120px">Client</th>
                <th className="min-w-120px">Created at</th>
                <th className="min-w-120px">Created by</th>
                <th className="min-w-120px">Zone Capacity</th>
                <th className="min-w-100px text-end"></th>
              </tr>
            </thead>
            {/*end::Table head*/}
            {/*begin::Table body*/}
            <tbody>
              {zones.map((zone, index) => 
              <tr key={index}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                    <span className="svg-icon svg-icon-muted svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.3" d="M18.0624 15.3454L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3454C4.56242 13.6454 3.76242 11.4452 4.06242 8.94525C4.56242 5.34525 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24525 19.9624 9.94525C20.0624 12.0452 19.2624 13.9454 18.0624 15.3454ZM13.0624 10.0453C13.0624 9.44534 12.6624 9.04534 12.0624 9.04534C11.4624 9.04534 11.0624 9.44534 11.0624 10.0453V13.0453H13.0624V10.0453Z" fill="black"/>
                    <path d="M12.6624 5.54531C12.2624 5.24531 11.7624 5.24531 11.4624 5.54531L8.06241 8.04531V12.0453C8.06241 12.6453 8.46241 13.0453 9.06241 13.0453H11.0624V10.0453C11.0624 9.44531 11.4624 9.04531 12.0624 9.04531C12.6624 9.04531 13.0624 9.44531 13.0624 10.0453V13.0453H15.0624C15.6624 13.0453 16.0624 12.6453 16.0624 12.0453V8.04531L12.6624 5.54531Z" fill="black"/>
                    </svg></span>
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                      <a  className="text-dark fw-bolder text-hover-primary fs-6">{zone.zone_name}</a>
                      {!zone.zone_occupied ? <span className="mt-2 fw-bold badge badge-success badge-sm d-block text-white">Unoccupied</span>: 
                      <span className="mt-2 fw-bold badge badge-danger badge-sm d-block text-white">occupied</span>}
                    </div>
                  </div>
                </td>
                <td>
                  <a  className="text-dark fw-bolder text-hover-primary d-block fs-6">{zone.asset_count} {zone.asset_count === 1 ? 'Asset' : 'Assets'}</a>
                </td>
                <td>
                  <a  className="text-dark fw-bolder text-hover-primary d-block fs-6">{zone.project_count} {zone.project_count === 1 ? 'Project' : 'Projects'}</a>
                </td>
                <td>
                  <a  className="text-dark fw-bolder text-hover-primary d-block fs-6">{zone.brand_count} {zone.brand_count === 1 ? 'Brand' : 'Brands'}</a>
                </td>
                <td>
                  <a  className="text-dark fw-bolder text-hover-primary d-block fs-6">{zone.client_count} {zone.client_count === 1 ? 'Client' : 'Clients'}</a>
                </td>
                <td>
                  <a className="text-dark fw-bolder d-block fs-6">{parseDateTimestamp(zone.created_at)}</a>
                </td>
                <td>
                  <Link to={`/user-management/users/user-profile/${zone.created_by}`} className="text-dark text-hover-primary fw-bolder d-block fs-6">{zone.created_by_name}</Link>
                </td>
                <td>
                  <a className="text-dark fw-bolder d-block fs-6">{zone.zone_capacity ? zone.zone_capacity.concat(' ft²'): 'not added'}</a>
                </td>
                <td>
                  <div className="d-flex justify-content-end flex-shrink-0">
                  <a onClick={() => {
                      setDetailId(zone.id)
                      setZoneName(zone.zone_name)
                      setDetailedModal(true)
                      setIndex(index)
                      setOccupied(zone.zone_occupied)
                    }} title='View Zone Details' className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                      {/*begin::Svg Icon | path: icons/duotune/art/art005.svg*/}
                      <span className="svg-icon svg-icon-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="black"/>
                            <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="black"/>
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </a>
                    <a onClick={() => handleOccupyBtn(zone, index)} title={zone.zone_occupied ? 'Mark as unoccupied' : 'Mark as fully occupied'} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                      <span className="svg-icon svg-icon-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z" fill="black"></path>
                          <path opacity="0.3" d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z" fill="black"></path>
                        </svg>
                      </span>
                    </a>
                    <a onClick={() => {
                      setEditId(zone.id)
                      setEditIndex(index)
                      setEditZoneModal(true)
                    }} title='Edit' className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                      {/*begin::Svg Icon | path: icons/duotune/art/art005.svg*/}
                      <span className="svg-icon svg-icon-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="black"></path>
                          <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="black"></path>
                        </svg>
                      </span>
                      {/*end::Svg Icon*/}
                    </a>
                    </div>
                </td>
              </tr>)}
            </tbody>
            {/*end::Table body*/}
          </table>
          {/*end::Table*/}
        </div>
        {/*end::Table container*/}
      </div>
      {/*begin::Body*/}
    </div>
    </>
    }
    </>
  )
}
