import React, { useEffect, useState } from 'react'
import api from '../../../store/api'
import {useSelector} from 'react-redux'

export default function AddRole(props) {
    const [groupedPermissions, setGroupedPermissions] = useState(props.groupedPermissions)
    const [newRoleData, setNewRoleData] = useState({
        role_name: '',
        selectedPermissions: []
    })
    const [errors, setErrors] = useState({
        role_name: '',
        selectedPermissions: ''
    })
    const [loading, setLoading] = useState(false)
    const token = useSelector((state) => state.loginSlice.token);
    
    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        if (event.target.name === 'role_name' && errors.role_name !== '') {
            setErrors({...errors, role_name: ''})
        }
        setNewRoleData((prevFormData) => {
          if (type === 'checkbox') {
            return {
              ...prevFormData,
              selectedPermissions: checked
                ? [...prevFormData.selectedPermissions, value]
                : prevFormData.selectedPermissions.filter((item) => item !== value),
            };
          } else {
            return {
              ...prevFormData,
              [name]: value,
            };
          }
        });
    };


    const validateForm = () => {
        let errors = {}
        if (newRoleData.role_name.length === 0) {
            errors.role_name = 'You must input the role name to proceed.'
        } if (newRoleData.selectedPermissions.length === 0) {
            errors.selectedPermissions = 'You must add atleast one permission to proceed.'
            props.returnResponse({
                "severity": "error",
                "message": errors.selectedPermissions
            })
        }
        return errors
    }

    const submitNewRoleForm = async (event) => {
        event.preventDefault();
        setLoading(true)
        const validationErrors = validateForm(newRoleData);
        if (Object.keys(validationErrors).length === 0) {
            const response = await api.post('/api/users/create-role/', newRoleData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
            }) 
            
            if (response.status === 200) {
                setNewRoleData({
                    role_name: '',
                    selectedPermissions: []
                })
                const newRole = response.data.role;
                props.addRole(newRole)
                props.handleClose()
                props.handleSuccess({
                    message: response.data.message,
                    severity: "success",
                    show: true
                })
                setLoading(false)
            } else {
                props.returnResponse({
                    "severity": "error",
                    "message": response.data.error
                })
                setLoading(false)
            }
        }
        else {
            setErrors(validationErrors)
            setLoading(false)
        }
    }

    const handleToggleAll = (evt) => {
        const checked = evt.target.checked
        if (checked) {
            const allSelectedPermissions = []
            for (let key in groupedPermissions){
                for (let i = 0; i < groupedPermissions[key].length; i++) {
                    allSelectedPermissions.push(groupedPermissions[key][i].name)
                }
            }
            setNewRoleData({...newRoleData, selectedPermissions: allSelectedPermissions})
        } else 
        setNewRoleData({...newRoleData, selectedPermissions: []})
    }

    useEffect(() => {
        setGroupedPermissions(props.groupedPermissions)
    }, [props.groupedPermissions])

  return (
    
                <form id="kt_modal_add_role_form" className="form fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={submitNewRoleForm}>
                    {/*begin::Scroll*/}
                    <div className="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_role_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_role_header" data-kt-scroll-wrappers="#kt_modal_add_role_scroll" data-kt-scroll-offset="300px" style={{maxHeight: "476px"}}>
                        {/*begin::Input group*/}
                        <div className="fv-row mb-10 fv-plugins-icon-container">
                            {/*begin::Label*/}
                            <label className="fs-5 fwt-bolder form-label mb-2">
                                <span className="required">Role name</span>
                            </label>
                            {/*end::Label*/}
                            {/*begin::Input*/}
                            <input className="form-control form-control-solid" placeholder="Enter a role name" name="role_name" onChange={handleInputChange} />
                            {errors.role_name && <div className="text-danger">{errors.role_name}</div>}
                        <div className="fv-plugins-message-container invalid-feedback"></div></div>
                        {/*end::Input group*/}
                        {/*begin::Permissions*/}
                        <div className="fv-row">
                            {/*begin::Label*/}
                            <label className="fs-5 fwt-bolder form-label mb-2">Role Permissions</label>
                            {/*end::Label*/}
                            {/*begin::Table wrapper*/}
                            <div className="table-responsive">
                                {/*begin::Table*/}
                                <table className="align-middle table-row-dashed fs-6 gy-5">
                                    {/*begin::Table body*/}
                                    <tbody className="text-gray-600 fwt-bold">
                                        <tr>
                                            <td className="text-gray-800">Administrator Access
                                            <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Allows a full access to the system" aria-label="Allows a full access to the system"></i></td>
                                            <td>
                                                {/*begin::Checkbox*/}
                                                <label className="form-check form-check-custom form-check-solid me-9">
                                                    <input className="form-check-input" type="checkbox" value="" id="kt_roles_select_all" style={{width: "20px", height: "20px"}} onChange={handleToggleAll} />
                                                    <span className="form-check-label" htmlFor="kt_roles_select_all">Select all</span>
                                                </label>
                                                {/*end::Checkbox*/}
                                            </td>
                                        </tr>
                                        {Object.keys(groupedPermissions).map((key) => (
                                        <tr key={key}>
                                            {/*begin::Label*/}
                                            <td className="text-gray-800" style={{display: "flex"}}>Manage {key}</td>
                                            {/*end::Label*/}
                                            {/*begin::Options*/}
                                            <td>
                                                {/*begin::Wrapper*/}
                                                <div className="d-flex" style={{flexWrap: "wrap"}}>
                                                    {/*begin::Checkbox*/}
                                                    {groupedPermissions[key].map((Permission) => (
                                                        <label style={{flexBasis: "20%", marginTop: "12px", marginRight: "19px"}} className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20" key={Permission.id}>
                                                            <input className="form-check-input" type="checkbox" value={Permission.name} checked={newRoleData.selectedPermissions.includes(Permission.name)} name={Permission.codename} onChange={handleInputChange}/>
                                                            {Permission.name.toLowerCase().includes("view") ? (
                                                                <span className="form-check-label">View</span>
                                                            ) : Permission.name.toLowerCase().includes("add") ? (
                                                                <span className="form-check-label">Create</span>
                                                            ) : Permission.name.toLowerCase().includes("change") ? (
                                                                <span className="form-check-label">Edit</span>
                                                            ) : Permission.name.toLowerCase().includes("delete") ? (
                                                                <span className="form-check-label">Delete</span>
                                                            ) : <span className="form-check-label">{Permission.name}</span>}
                                                        </label>
                                                    ))}
                                                </div>
                                                {/*end::Wrapper*/}
                                            </td>
                                            {/*end::Options*/}
                                        </tr>))
                                    }
                                    </tbody>
                                    {/*end::Table body*/}
                                </table>
                                {/*end::Table*/}
                            </div>
                            {/*end::Table wrapper*/}
                        </div>
                        {/*end::Permissions*/}
                    </div>
                    {/*end::Scroll*/}
                    {/*begin::Actions*/}
                    <div className="text-center pt-15">
                        <button type="reset" className="btn btn-light me-3" data-kt-roles-modal-action="cancel">Discard</button>
                        <button type="submit" className="btn btn-beysix" data-kt-roles-modal-action="submit">
                            {!loading ? <span className="indicator-label">Submit</span> :
                            <span className="indicator-progress">Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
                        </button>
                    </div>
                    {/*end::Actions*/}
                <div></div></form>
  )
}
