import React, {useState, useEffect} from 'react'
import api from '../../../store/api'
import { useSelector } from 'react-redux'
import AssetsTable from '../AssetsTable/AssetsTable'
import { motion } from 'framer-motion'

export default function AssetList() {

  const [assets, setAssets] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [pageLoading, setPageLoading] = useState(false)
  const [resultsFound, setResultsFound] = useState(true)
  const [count, setCount] = useState(0)
  const [ogAssets, setOgAssets] = useState([])
  const [subsequentLoading, setSubsequentLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(1)

  const {organization, token, user} = useSelector((state) => {
    return {
      'organization': state.loginSlice.organization, 
      'token': state.loginSlice.token,
      'user': state.loginSlice.user
    }
  }); //

  const getAssets = () => {
    if (page === 1)
      setPageLoading(true)
    else
      setSubsequentLoading(true)
    
    
    if (page >= 1 && page <= totalPages) {
      let url = `/api/assets/?page=${page}`
      if (user.user_type === 'client') {
        url = `/api/assets/oganization-assets/${organization.id}/?page=${page}`
      }
      api.get(url, {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
      .then((response) => {
        if (response.status === 200) {
            if (response.data.results.length > 0){
                if (page > 1){
                  setAssets([...assets, ...response.data.results])
                  setSubsequentLoading(false)
                }
                else {
                  setAssets(response.data.results)
                  setOgAssets(response.data.results)
                  setCount(response.data.count)
                  setTotalPages(response.data.total_pages)
                }
            } else {
                setAssets(response.data.results)
                setResultsFound(false)
            }
        setPageLoading(false)
        }
      })
      .catch((error) => {
          throw new Error(error) 
      })
    }
  }

  const handleSearch = (evt) => {
    evt.preventDefault()
    const value = evt.target.value
    setSearchQuery(value)

    let url = `/api/assets/detailed-search-asset/`
    if (user.user_type === 'client') {
      url = `/api/assets/detailed-search-asset/${organization.id}/`
    }

    if (value.length > 2) {
        setPageLoading(true)
        api.post(url, {searchQuery: value},
            {headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.results.length > 0){
                        setAssets(response.data.results)
                        setResultsFound(true)
                    } else {
                        setAssets(response.data.results)
                        setResultsFound(false)
                    }
                    setPageLoading(false)
                }
            })
            .catch((error) => {
                if (response.status === 404) {

                } else {
                    throw new Error(error)
                }
                setPageLoading(true)
            })
        } else {
            setAssets(ogAssets)
            if (ogAssets.length > 0)
                setResultsFound(true)
        }
    }

  const [page, setPage] = useState(1)

  useEffect(() => {
    getAssets()
  }, [page])

    // const permissions = useSelector((state) => state.loginSlice.permissions);

  return (
    <>
    {!pageLoading && <motion.p 
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.3 }}style={{fontWeight: '600'}}>{count} Assets Total</motion.p>}
    <div className="card mb-5 mb-xl-8">
              {/*begin::Header*/}
      <div className="card-header border-0 pt-5">
      <h3 className="card-title align-items-start flex-column">
          <div className="d-flex align-items-center position-relative my-1">
              <span className="svg-icon svg-icon-1 position-absolute ms-6">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect><path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path></svg>
              </span>
              <input type="text" className="form-control form-control-solid w-250px ps-14" onChange={handleSearch} value={searchQuery} placeholder="Search Asset" />
            </div>
        </h3>
      </div>
      {/*end::Header*/}
      {/*begin::Body*/}
      <div className="card-body py-3">
        {/*begin::Table container*/}
        <AssetsTable searchQuery={searchQuery} assetData={assets} loading={pageLoading} resultsFound={resultsFound} setPage={setPage} page={page} subsequentLoading={subsequentLoading} />
        {/*end::Table container*/}
      </div>
      {/*begin::Body*/}
    </div>
    </>
  )
}
