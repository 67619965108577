import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import api from '../../../store/api'

export default function SuccessfullAccountSetup() {

  const { id } = useParams()
  const navigate = useNavigate()
  const [email, setEmail] = useState("")

  useEffect(() => {
    api.get(`/api/users/successful-account-setup/${id}/`)
    .then((response) => {
      setEmail(response.data.email)
      return;
    })
    .catch((error) => {
        const err = error.response
        if (err.status === 404)  {
            navigate(err.data.url);
            return;
        }
        throw new Error(err.data.error)
    })
  }, [])

  return (
        <div className="pt-lg-10 mb-10" style={{width: "30vw"}}>
            {/*begin::Logo*/}
            <h1 className="fwt-bolder fs-2qx text-gray-800 mb-7 text-center">Welcome to Beysix Studio</h1>
            {/*end::Logo*/}
            {/*begin::Message*/}
            <div className="fwt-bold fs-5 text-muted mb-15">Your account has been successfully setup within the system using the email ({email}). Use the password that you set up just now to login to your account.</div>
            {/*end::Message*/}
            {/*begin::Action*/}
            <div className="text-center">
                <Link to='/auth/login' className="btn btn-lg btn-beysix fwt-bolder">Go to Login</Link>
            </div>
            {/*end::Action*/}
        </div>
  )
}
