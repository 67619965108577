import React, {useState} from 'react'
import api from '../../../store/api';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import FeedbackModal from '../../Utilities/FeedbackModal';

export default function CreateWarehouseZone({setData}) {
    
	const token = useSelector((state) => state.loginSlice.token);
    const {id} = useParams()
    const handleRowAlphabetChange = (e) => {
        const inputValue = e.target.value;
        if (/^[A-Za-z]?$/.test(inputValue)) {
            setWhzData({...whzData, rowAlphabet: inputValue});
        }
    };

    const handleZoneChange = (evt) => {
        const target = evt.target;
        const name = target.name;
        const value = parseInt(target.value);
    
        if (name === 'endNumber') {
            if (value < 2) {
                setWhzData({...whzData, [name]: 2});
            } else {
                setWhzData({...whzData, [name]: value});
            }
        } else {
            if (value < 1) {
                setWhzData({...whzData, [name]: 1});
            } else {
                setWhzData({...whzData, [name]: value});
            }
        }
    }    

    const [whzData, setWhzData] = useState({
        rowAlphabet: '',
        beginNumber: null,
        endNumber: null
    })

    const [loading, setLoading] = useState(false)

    const handleSubmit = () => {
        if (whzData.endNumber < whzData.beginNumber) {
            setError('Zone begin number cannot be greater than zone end number.')
            return;
        }
        if (whzData.rowAlphabet !== '' && (whzData.beginNumber !== null || whzData.beginNumber !== 0)
             && (whzData.endNumber !== null || whzData.endNumber !== 0)) {
            setLoading(true)
            api.post(`/api/warehouses/autocreate-zones/${id}/`, whzData,
                    {headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
            })
            .then((response) => {
                setLoading(false)
                setData(response.data.data, response.data.message)
                setWhzData({
                    rowAlphabet: '',
                    beginNumber: '',
                    endNumber: ''
                })
                setError(null)
            })
            .catch((error) => {
                setLoading(false)
                setRes({
                    message: error.response.data.error,
                    severity: "error",
                    show: true
                })
            })
        } else {
            setError('You must submit all the details')
        }
    }

    const [error, setError] = useState(null)
    const [res, setRes] = useState({
        message: "",
        severity: "success",
        show: false
    })

  return (
    <div>
        <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
            setRes({
                message: "",
                severity: "success",
                show: false
            })
        }}/>
        <div className="mb-10">
            <label for="" className="form-label">Row Alphabet</label>
            <input className="form-control form-control-solid" id="kt_inputmask_2" name='rowAlphabet' type="text" value={whzData.rowAlphabet} onChange={handleRowAlphabetChange}/>
            <div className="form-text">Row Alphabet format:
            <code>A or B or C etc...</code></div>
        </div>
        <div className="mb-10">
            <label for="" className="form-label">Zone Begin Number</label>
            <input className="form-control form-control-solid" id="kt_inputmask_2" name='beginNumber' min='1' type="number" value={whzData.beginNumber} onChange={handleZoneChange}/>
            <div className="form-text">Zone Begin format:
            <code>1 or 2 or 3 etc...</code></div>
        </div>
        <div className="mb-10">
            <label for="" className="form-label">Zone End Number</label>
            <input className="form-control form-control-solid" id="kt_inputmask_2" name='endNumber' min='2' type="number" value={whzData.endNumber} onChange={handleZoneChange}/>
            {error ? <div className="fv-plugins-message-container invalid-feedback">{error}</div> : <div className="form-text">Zone End format:
            <code>20 or 10 or 5 etc...</code></div>}
        </div>
        <div style={{marginTop: '30px', display: 'flex', justifyContent: 'end'}}>
            <button type="button" onClick={handleSubmit} className="btn btn-beysix me-3" disabled={loading ? true : false}>
            {!loading ? <span className="indicator-label">Submit
            {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
            <span className="svg-icon svg-icon-3 ms-2 me-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect>
                    <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path>
                </svg>
            </span>
            {/*end::Svg Icon*/}</span> : 
            <span className="indicator-progress">Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
            </button>
        </div>
    </div>
  )
}
