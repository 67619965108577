import React from 'react'

export default function ConfirmationModal({handleConfirmationCloseClick, confirmationShow, confirmationDetails}) {
  return (
    <div className={confirmationShow ? "modal-backdrop fade show": "modal-backdrop"} aria-hidden="true" style={!confirmationShow ? {display: 'none'} : null}>
        <div className="swal2-container swal2-center" style = {confirmationShow ? {display: "grid"} : {display: "none"}}>
            <div aria-labelledby="swal2-title" aria-describedby="swal2-html-container" style={{display: "block"}} className="swal2-popup swal2-modal swal2-icon-warning swal2-show" tabIndex="-1" role="dialog" aria-live="assertive" aria-modal="true">
            <div className="swal2-icon swal2-warning swal2-icon-show" style={{ display: "flex"}}>
                <div className="swal2-icon-content">!</div>
            </div>
            <div className="swal2-html-container" id="swal2-html-container" style={{display: "block"}}>
                {!confirmationDetails ? "Are you sure you would like to close?" : confirmationDetails.promptText}</div>
            <div className="swal2-actions" style={{display: "flex"}}>
                {!confirmationDetails ? <button type="button" close-attr = 'true' onClick={handleConfirmationCloseClick} className="swal2-confirm btn btn-beysix" aria-label="" style={{display: "inlineBlock"}}>Yes, close it!</button> : null}
                <button type="button" close-attr = 'false' onClick={handleConfirmationCloseClick} className="swal2-cancel btn btn-active-light" aria-label="" style={{display: "inlineBlock"}}>{!confirmationDetails ? "No, return" : confirmationDetails.btnText}</button></div>
        </div>
        </div>
    </div>
  )
}
