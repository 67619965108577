import React, {useState} from 'react'
import api from '../../../store/api'
import FeedbackModal from '../../Utilities/FeedbackModal'
import { Link, useNavigate } from 'react-router-dom'

export default function ForgotPassword() {

    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState(false)
    const [email, setEmail] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [res, setRes] = useState({
        message: "",
        severity: "success",
        show: false
    })
    const navigate = useNavigate()

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setErrors(true)
            return false;
        }
        setErrors(false)
        return true
    }

    const handleChange = (evt) => {
        const value = evt.target.value
        setEmail(value)
        if (validateEmail(value) === false) {
            setErrorMessage(`${value} is not a valid email address`)
            evt.target.classList.add('input-is-invalid')
        } else {
            setErrorMessage(``)
            evt.target.classList.remove('input-is-invalid')
        }
        
        if (value.length === 0 ) {
            setErrorMessage('Email address is required')
        } else {
            if (validateEmail(value) === true) {
                setErrorMessage(``)
                evt.target.classList.remove('input-is-invalid')
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (validateEmail(email)) {
            setLoading(true)
            await api.post('/api/users/forgot-password/', {
                'email': email
            })
            .then((response) => {
                console.log(response)
                if (response.status === 200)
                    navigate(response.data.url)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error)
                const err = error.response.data
                setRes({
                    message: err.error,
                    severity: "error",
                    show: true
                })
                setLoading(false)
            })
        }
    }

return (
    <div className="w-lg-500px bg-body rounded shadow-sm-sm p-10 p-lg-15 mx-auto">
        <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
            setRes({
                message: "",
                severity: "success",
                show: false
            })
        }}/>
        {/*begin::Form*/}
        <form className="form w-100" noValidate id="kt_password_reset_form" onSubmit={handleSubmit}>
            {/*begin::Heading*/}
            <div className="text-center mb-10">
                {/*begin::Title*/}
                <h1 className="text-dark mb-3">Forgot Password ?</h1>
                {/*end::Title*/}
                {/*begin::Link*/}
                <div className="text-gray-400 fwt-bold fs-5">Enter your email to reset your password.</div>
                {/*end::Link*/}
            </div>
            {/*begin::Heading*/}
            {/*begin::Input group*/}
            <div className="fv-row mb-10">
                <label className="form-label fwt-bolder text-gray-900 fs-6">Email</label>
                <input className="form-control form-control-solid" type="email" placeholder="" name="email" autoComplete="off" onChange={handleChange}/>
                {errors && <div className="fv-plugins-message-container invalid-feedback"><div data-field="email" data-validator="emailAddress">{errorMessage}</div></div>}
            </div>
            {/*end::Input group*/}
            {/*begin::Actions*/}
            <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                <button type="submit" disabled={loading ? true : false} id="kt_password_reset_submit" className="btn btn-lg btn-beysix fwt-bolder me-4">
                    {!loading ? <span className="indicator-label">Submit</span> :
                    <span className="indicator-progress">Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
                </button>
                <Link to="/auth/login" className="btn btn-lg btn-light-primary fwt-bolder">Cancel</Link>
            </div>
            {/*end::Actions*/}
        </form>
        {/*end::Form*/}
    </div>
  )
}
