import React, {useEffect, useState} from 'react'

export default function ProfileImageInput({image, setImage, edit = false, SuccessfullSumbission = false}) {

    const [displayImage, setDisplayImage] = useState('https://i0.wp.com/sunrisedaycamp.org/wp-content/uploads/2020/10/placeholder.png?ssl=1')

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setDisplayImage(e.target.result);
            };
            reader.readAsDataURL(file);
            setImage(file);
        }
    };

    const handleImageRemove = () => {
        setDisplayImage('https://i0.wp.com/sunrisedaycamp.org/wp-content/uploads/2020/10/placeholder.png?ssl=1');
        setImage(null);
    };

    
    useEffect(() => {
        handleImageRemove()
     }, [SuccessfullSumbission])

    useEffect(() => {
        if (image && image !== '')
            setDisplayImage(image)
    }, [image])


  return (
    <div className="py-3">
    <div className="rounded border" style={{ maxHeight: 'fit-content', maxWidth: 'fit-content' }}>
        {/*begin::Image input*/}
        <div className="image-input image-input-empty" style={{ backgroundImage: `url(${displayImage})` }}>
            {/*begin::Preview existing avatar*/}
            <div className="image-input-wrapper w-125px h-125px"></div>
            {/*end::Preview existing avatar*/}
            {/*begin::Edit*/}
            <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" data-bs-dismiss="click" title="" data-bs-original-title="Change avatar">
                <i className="bi bi-pencil-fill fs-7"></i>
                {/*begin::Inputs*/}
                <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={handleImageChange} />
                <input type="hidden" name="avatar_remove" />
                {/*end::Inputs*/}
            </label>
            {/*end::Edit*/}
            {/*begin::Cancel*/}
            {image && <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow rm-img-btn" onClick={handleImageRemove}>
                <i className="bi bi-x fs-2"></i>
            </span>}
            {/*end::Cancel*/}
        </div>
        {/*end::Image input*/}
    </div>
</div>
  )
}
