import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux';
import api from '../../store/api';
import FeedbackModal from '../Utilities/FeedbackModal';

export default function EditTool({handleCloseModal, primaryKey, toolData, setToolData}) {
    const token = useSelector((state) => state.loginSlice.token);
    const [editToolData, setEditToolData] = useState({
        toolDescription: '',
        toolName: '',
        toolQuantity: '',
        toolsWithinWarehouse: ''
    })

    const handleChange = (evt) => {
        evt.preventDefault()
        const name = evt.target.name
        const value = evt.target.value;
        setEditToolData({...editToolData, [name]: value})
    }

    const [loading, setLoading] = useState(false)
    const [res, setRes] = useState({
        message: "",
        severity: "success",
        show: false
    })

    const handleSubmit = (evt) => {
        evt.preventDefault()
        if (editToolData.toolName.length > 0) {
            setLoading(true)
            api.put(`/api/tools/detail-tool/${primaryKey}/`, editToolData,
              {headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Token ${token}`,
                  },
              })
              .then((response) => {
                  if (response.status === 200) {
                        setLoading(false)
                        setRes({
                            message: response.data.message,
                            severity: "success",
                            show: true
                        })
                        setEditToolData({
                            toolDescription: '',
                            toolName: ''
                        })
                        const _toolData = toolData
                        for (let i = 0; i < _toolData.length; i++) {
                            if (_toolData[i].id === primaryKey) {
                                _toolData[i] = response.data.data
                            }
                        }
                        setToolData(_toolData)
                        setTimeout(() => {
                            handleCloseModal();
                        }, 1000);
                      } 
                })
              .catch((error) => {
                setRes({
                    message: error.response.data.error,
                    severity: "error",
                    show: true
                })
              })
        }
    }

    const getToolData = () => {
        if (primaryKey) {
            api.get(`/api/tools/detail-tool/${primaryKey}/`,
            {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
            .then((response) => {
                setEditToolData({
                    toolName: response.data.data.tool_name,
                    toolDescription: response.data.data.tool_description,
                    toolQuantity: response.data.data.quantity,
                    toolsWithinWarehouse: response.data.data.inside_warehouse
                })
            })
            .catch((error) => {
                throw new Error('Error getting data.')
            })
        }
    }

    useEffect(() => {
        getToolData()
    }, [primaryKey])

  return (
    <div className="modal-body scroll-y mx-5 mx-xl-15" data-select2-id="select2-data-78-jwvd">
        <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
			setRes({
				message: "",
				severity: "success",
				show: false
			})
		}}/>
        {/*begin::Form*/}
        <form id="kt_modal_new_card_form" className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#" data-select2-id="select2-data-kt_modal_new_card_form">
            {/*begin::Input group*/}
            <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                {/*begin::Label*/}
                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                    <span className="required">Tool Name</span>
                    <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Specify a card holder's name" aria-label="Specify a card holder's name"></i>
                </label>
                {/*end::Label*/}
                <input type="text" className="form-control form-control-solid" placeholder="" name="toolName" value={editToolData.toolName} onChange = {handleChange} />
            <div className="fv-plugins-message-container invalid-feedback"></div></div>
            <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                {/*begin::Label*/}
                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                    <span className="required">Total Tool Quantity</span>
                    <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Specify a card holder's name" aria-label="Specify a card holder's name"></i>
                </label>
                {/*end::Label*/}
                <input type="number" className="form-control form-control-solid" placeholder="" name="toolQuantity" value={editToolData.toolQuantity} onChange = {handleChange} />
            <div className="fv-plugins-message-container invalid-feedback"></div></div>
            <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                {/*begin::Label*/}
                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                    <span className="required">Tools Within Warehouse</span>
                    <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Specify a card holder's name" aria-label="Specify a card holder's name"></i>
                </label>
                {/*end::Label*/}
                <input type="number" className="form-control form-control-solid" placeholder="" name="toolsWithinWarehouse" value={editToolData.toolsWithinWarehouse} onChange = {handleChange} />
            <div className="fv-plugins-message-container invalid-feedback"></div></div>
            {/*end::Input group*/}
            {/*begin::Input group*/}
            <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                {/*begin::Label*/}
                <label className="fs-6 fw-bold form-label mb-2">Tool Description</label>
                {/*end::Label*/}
                {/*begin::Input wrapper*/}
                <div className="position-relative">
                    {/*begin::Input*/}
                    <textarea rows="5" cols="50" className="form-control form-control-solid" name="toolDescription" id="toolDescription" value={editToolData.toolDescription} onChange={handleChange}></textarea>
                    
                </div></div>
            {/*end::Input group*/}
            {/*begin::Input group*/}
            
            {/*end::Input group*/}
            {/*begin::Input group*/}
            
            {/*end::Input group*/}
            {/*begin::Actions*/}
            <div className="text-center pt-15">
                <button type="reset" id="kt_modal_new_card_cancel" className="btn btn-light me-3">Discard</button>
                <button type="submit" id="kt_modal_new_card_submit" onClick={handleSubmit} className="btn btn-beysix" disabled={!loading ? false : true}>
                    {!loading ? <span className="indicator-label">Submit</span>
                    : <span className="indicator-progress">Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
                </button>
            </div>
            {/*end::Actions*/}
        <div></div></form>
        {/*end::Form*/}
    </div>
  )
}
