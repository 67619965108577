import React, {useState, useEffect, useRef} from 'react'
import deleteLeafletAttribution from '../../Utilities/deleteLeafletAttribution';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import 'leaflet-search/dist/leaflet-search.min.css';
import 'leaflet-search';
import ProgressChart from '../../Utilities/ProgressChart';


export default function ActivationOverview({activationData}) {

    const mapRef = useRef(null)
    const marker = useRef(null)
    const initCoordinates = [-1.2460227009959508, 36.68168221244762];
    const [markerPosition, setMarkerPosition] = useState(null)

    const onMapReady = (map) => {
        map = map.current
        if (map){
            map.invalidateSize()
        }
    };

    const handleSetMarkerPosition = (coordinates) => {
        const { lat, long } = coordinates
        if ((lat && long) && mapRef.current) {
            setMarkerPosition({lat: parseFloat(lat), lng: parseFloat(long)})
            const marker = L.marker([51.505, -0.09], { icon: customIcon }).addTo(mapRef.current);
            marker.setLatLng([lat, long]);
            const newLatLng = marker._latlng;
            mapRef.current.flyTo(newLatLng, 14);
        }
    }

    const customIcon = L.icon({
        iconUrl: '/static/assets/media/illustrations/beysix-1/location-pin.png',
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        popupAnchor: [0, -38]
    });

    useEffect(() => {
        handleSetMarkerPosition({lat: activationData.activationLocationLatitude , long: activationData.activationLocationLongitude})
        onMapReady(mapRef)
        deleteLeafletAttribution()
    }, [activationData])

  return (
    <div className='row'>
        <div className='col-md-6'>
        <div className="card card-flush">
                <div className="card-body d-flex flex-column" style={{padding: "0px"}}>
                    <MapContainer
                            ref={mapRef}
                            center={initCoordinates}
                            zoom={14}
                            style={{ height: "400px", width: "100%" }}
                        >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {markerPosition && (
                            <Marker position={markerPosition} icon={customIcon} ref={marker}>
                                <Popup>
                                    {activationData.activationName.concat(' Activation')}<br /> {markerPosition.lat.toFixed(4)}, {markerPosition.lng.toFixed(4)}
                                </Popup>
                            </Marker>
                        )}
                    </MapContainer>
                    </div>
                </div>
            </div>
            <div className='col-md-6'>
                {/* <ProgressChart /> */}
            </div>
        </div>
  )
}
