import React, {useState, useEffect, useRef} from 'react'
import AssertiveModal from '../AssertiveModal'
import FeedbackModal from '../FeedbackModal'
import api from '../../../store/api'
import ReasonModal from '../ReasonModal'
import MainModal from '../../Modals/MainModal'
import RolesInputList from '../../RolesInputList/RolesInputList'
import { useSelector } from 'react-redux'

export default function UserTableActionsSubmenu({token, user, handleSuccess, roles, _clients}) {

  const [reasonShow, setReasonShow] = useState(false)
  const handleCloseClick = () => {
    setReasonShow(false)
  }
  const [res, setRes] = useState({
    message: "",
    severity: "success",
    show: false
  })
  
  const [clients, setClients] = useState([])
	const sub_menu_ref = useRef(null)
  const [editDetails, setEditDetails] = useState({userId: 0, isEditting: false})
  const [rolesEditUpdate, setRolesEditUpdate] = useState(false)
  const permissions = useSelector((state) => state.loginSlice.permissions);
  const [loading, setLoading] = useState(false)
  const [rolesModalOpen, setRolesModalOpen] = useState(false)
  const [showAssertiveModal, setShowAssertiveModal] = useState({
    show: false,
    promptText: '',
    btnPrimary: {show: false, text: ''},
    btnSecondary: {show: false, text: ''}
  })
  const [activityUpdate, setActivityUpdate] = useState(false)
  const [approvalUpdate, setApprovalUpdate] = useState(false)
  const [user_id, setUser_id] = useState(0)

  useEffect(() => {
    setUser_id(user.id)
  }, [user])

  useEffect(() => {
    setClients(_clients)
  }, [_clients])

  const handleSubmit = (url, update, param = null) => {
    setLoading(true)
    let data;
    if (user.is_client)
      data = {id: user_id, update: update, param: param, client: client}
    else
      data = {id: user_id, update: update, param: param}
    api.put(`/api/users/${url}/`, data , {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    })

    .then((response) => {
      if (response.status == 200) {
        setShowAssertiveModal({
          show: false,
          promptText: '',
          btnPrimary: {show: false, text: ''},
          btnSecondary: {show: false, text: ''}
        })
        handleSuccess(response.data.user)
        setRes({
          message: response.data.message,
          severity: "success",
          show: true
        })
        setReasonShow(false)
        setActivityUpdate(false)
        setApprovalUpdate(false)
        setRolesModalOpen(false)
        setLoading(false)
      }
    })

    .catch((error) => {
      setRes({
        message: error.response.data.error,
        severity: "error",
        show: true
      })
      setLoading(false)
    })
  }

  const handleUpdate = (status) => {
		if (status === true) {
        if (approvalUpdate) {
          if (!user.is_approved) {
            setShowAssertiveModal({
              show: false,
              promptText: '',
              btnPrimary: {show: false, text: ''},
              btnSecondary: {show: false, text: ''}
            })
            setRolesModalOpen(true)
          }
          else {
            handleSubmit('update-user-approval', !user.is_approved, [])
          }
        } else if (rolesEditUpdate) {
          setShowAssertiveModal({
            show: false,
            promptText: '',
            btnPrimary: {show: false, text: ''},
            btnSecondary: {show: false, text: ''}
          })
          setRolesModalOpen(true)
        } else if (activityUpdate){
          setReasonShow(true)
        }

    } else {
      setActivityUpdate(false)
      setApprovalUpdate(false)
      setRolesEditUpdate(false)
			setShowAssertiveModal({
				show: false,
				promptText: '',
				btnPrimary: {show: false, text: ''},
				btnSecondary: {show: false, text: ''}
			})
		}
	}

  const handleActivityAssertiveModal = (evt) => {
		evt.preventDefault()
    setActivityUpdate(true)
		setShowAssertiveModal({
			show: true,
			promptText: `Are you sure you want to ${user.is_active ? 'deactivate' : 'activate'} ${user.profile ? user.profile.first_name : ''}'s account?`,
			btnPrimary: {show: true, text: `Yes, ${user.is_active ? 'deactivate' : 'activate'} it`},
			btnSecondary: {show: true, text: 'No, Take me back'}
		})
	}

  const handleApprovalAssertiveModal = (evt) => {
		evt.preventDefault()
    setApprovalUpdate(true)
		setShowAssertiveModal({
			show: true,
			promptText: `Are you sure you want to ${user.is_approved ? 'unapprove' : 'approve'} ${user.profile ? user.profile.first_name : ''}'s account?`,
			btnPrimary: {show: true, text: `Yes, ${user.is_approved ? 'unapprove' : 'approve'} it`},
			btnSecondary: {show: true, text: 'No, Take me back'}
		})
	}

  const handleEditRolesAssertiveModal = (evt) => {
		evt.preventDefault()
    setRolesEditUpdate(true)
    setEditDetails({userId: user.id, isEditting: true})
		setShowAssertiveModal({
			show: true,
			promptText: `Are you sure you want to edit ${user.profile.first_name}'s roles?`,
			btnPrimary: {show: true, text: `Yes, proceed`},
			btnSecondary: {show: true, text: 'No, Take me back'}
		})
	}


  const handleClick = (e) => {
    e.preventDefault();
    const target = e.target;
    const rect = target.getBoundingClientRect();
    const subMenu = target.nextSibling;

    const dimensions = {
      height: Math.round(rect.height),
      y: Math.round(rect.top),
      width: Math.round(rect.width),
      w: Math.round(window.innerWidth - rect.right)
    };

    const { y, height } = dimensions;
    let dimension3d = `translate3d(-${Math.round(target.clientWidth / 2)}px, ${y + height}px, 0px)`;

    showSubmenu(target, subMenu, dimension3d);

    const updateSubMenuPosition = () => {
        const scrollY = window.scrollY;
        const submenuHeight = subMenu.clientHeight;
        dimension3d = `translate3d(-${Math.round(target.clientWidth / 2)}px, ${(y - scrollY) + height}px, 0px)`;
        updateSubMenuOnScroll(subMenu, dimension3d)
        if (((y - scrollY) + height + submenuHeight) > screenHeight) {
          dimension3d = `translate3d(-${Math.round(target.clientWidth / 2)}px, ${y - submenuHeight}px, 0px)`;
          updateSubMenuOnScroll(subMenu, dimension3d)
        }
    };

    window.addEventListener('scroll', updateSubMenuPosition);

    const screenHeight = window.innerHeight;
    const submenuHeight = subMenu.clientHeight;

    if ((y + height + submenuHeight) > screenHeight) {
      dimension3d = `translate3d(-${Math.round(target.clientWidth / 2)}px, ${y - submenuHeight}px, 0px)`;
      updateSubMenuOnScroll(subMenu, dimension3d)
    }
};

  const updateSubMenuOnScroll = (subMenu, transformDimensions) => {
    subMenu.style.transform = transformDimensions
  }

  const subMenuQuery = (subMenu, transformDimensions) => {
    subMenu.classList.add('show')
    subMenu.style.zIndex = '105'
    subMenu.style.position = 'fixed'
    subMenu.style.inset = '0px 0px auto auto'
    subMenu.style.margin = '0px'
    subMenu.style.transform = transformDimensions
    document.addEventListener('click', closeSubMenu);
  }

  const showSubmenu = (target, subMenu, transformDimensions) => {
    const open = false
    if (target) {
      target.classList.add('btn-active-light-primary')
      target.classList.add('menu-dropdown')
      target.classList.add('show')
    }
    if (subMenu) {
      subMenuQuery(subMenu, transformDimensions)
    } else {
      subMenu = target.nextSibling;
      subMenuQuery(subMenu, transformDimensions)
    }
  }

  const closeSubMenu = (e) => {
    const subMenu = document.querySelector(`#idTableDropDown${user.id}`);
    const btn = subMenu.previousSibling;
    if (subMenu && !btn.contains(e.target)) {
        subMenu.classList.remove('show');
        btn.classList.remove('btn-active-light-primary')
        btn.classList.remove('menu-dropdown')
        btn.classList.remove('show')
        document.removeEventListener('click', closeSubMenu);
    }
  };

  const handleErrorEditOfRoles = (errorMessage) => {
    setRes({
      message: errorMessage,
      severity: "error",
      show: true
  })
  }

  const handleSuccessEditOfRoles = (successMessage) => {
    setRolesModalOpen(false);
    setEditDetails({isEditting: false, userId: 0})
    setRes({
      message: successMessage,
      severity: "success",
      show: true
    })
  }

  const [client, setClient] = useState('0')

  return (
    <td className="text-end">
      <AssertiveModal
					show = {showAssertiveModal.show}
					promptText = {showAssertiveModal.promptText}
					btnPrimary = {{show: showAssertiveModal.btnPrimary.show, text: showAssertiveModal.btnPrimary.text}}
					btnSecondary = {{show: showAssertiveModal.btnSecondary.show, text: showAssertiveModal.btnSecondary.text}}
					handleActionBtnClick = {handleUpdate}
      />
      <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
          setRes({
              message: "",
              severity: "success",
              show: false
          })
      }}/>
      <MainModal  handleClose={() => {
        setRolesModalOpen(false); 
        setEditDetails({isEditting: false, userId: 0})
        }} show={rolesModalOpen} modalTitle={user.profile && (editDetails.isEditting ? `Edit ${user.profile.first_name}'s roles.` : `Assign Role To ${user.profile.first_name}.`)}>
        <>
            <RolesInputList client={client} user={user} clients={clients} setClient={(client) => 
              setClient(client)
            } handleSuccessEditOfRoles={handleSuccessEditOfRoles} handleErrorEditOfRoles={handleErrorEditOfRoles} edit={editDetails.isEditting} userId={editDetails.userId} roles={roles} submit={(roles) =>  
            (roles.length > 0) ? 
              parseInt(client) === 0 && user.is_client ?
              setRes({ message: "You must assign the user to a client.", severity: "error", show: true }) :
              handleSubmit('update-user-approval', !user.is_approved, roles) :
              parseInt(client) === 0 && user.is_client ?
              setRes({ message: "You must assign this user atleast one role and assign to a client to proceed.", severity: "error", show: true }) :
              setRes({ message: "You must assign this user atleast one role to proceed.", severity: "error", show: true })
            } loading={loading} />
        </>
      </MainModal>
      <ReasonModal handleConfirmationCloseClick={handleCloseClick} reasonShow = {reasonShow} ReasonDetails = {{promptText: `Provide a reason as to why you need to ${user.is_active ? 'deactivate' : 'activate'} ${user.profile && user.profile.first_name}'s account?`, btnText: `Submit Reason`}} handleSuccess = {(reason_for_action) => handleSubmit('update-user-activity', !user.is_active, reason_for_action) } />
      <a  className="btn btn-light btn-active-light-primary btn-sm" onClick={handleClick}>Actions
        {/*begin::Svg Icon | path: icons/duotune/arrows/arr072.svg*/}
        <span className="svg-icon svg-icon-5 m-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
          </svg>
        </span>
        {/*end::Svg Icon*/}</a>
        {/*begin::Menu*/}
        <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fwt-bold fs-7 w-125px py-4" ref={sub_menu_ref} id = {`idTableDropDown${user.id}`}>
          {/*begin::Menu item*/}
          {user.is_approved ?
          permissions["can_unapprove_user"] &&
          <div className="menu-item px-3">
            <a  onClick={handleApprovalAssertiveModal} className="menu-link px-3">Unapprove</a>
          </div> :
          permissions["can_approve_user"] &&
          <div className="menu-item px-3">
            <a  onClick={handleApprovalAssertiveModal} className="menu-link px-3">Approve</a>
          </div>
          }
          {/*end::Menu item*/}
          {/*begin::Menu item*/}
          {user.is_active ?
          permissions["can_deactivate_user"] &&
          <div className="menu-item px-3">
            <a  onClick={handleActivityAssertiveModal} className="menu-link px-3">Deactivate</a>
          </div> :
          permissions["can_activate_user"] &&
          <div className="menu-item px-3">
            <a  onClick={handleActivityAssertiveModal} className="menu-link px-3">Activate</a>
          </div>
          }
          {(user.is_approved && permissions["can_approve_user"]) &&
          <div className="menu-item px-3">
            <a  onClick={handleEditRolesAssertiveModal} className="menu-link px-3">Edit Roles</a>
          </div>
          }
        </div>
    </td>
  )
}
