import React from 'react'
import getRandomHexColor from '../../Utilities/getRandomHexColor'
import { Link } from 'react-router-dom'

export default function ClientMembersBody({members}) {
  return (
    <div className= 'client-members'>
        {members.map((member, index) => {
            const {randomColor, darkerShade, brighterShade} = getRandomHexColor()
            return (
            <Link to={`/user-management/users/user-profile/${member.user_id}`}>
                <div className="alert alert-beysix d-flex align-items-center mb-2 individual-client-member" style={{background: brighterShade, borderColor: 'transparent'}}>
                    <div className="d-flex" key={index}>
                        <div className="d-flex">
                        {member && member.user_photo ?
                            <div className='asset-item-image-wrapper'>
                                <img className='asset-item-image' src={member.user_photo && member.user_photo} alt={member.first_name} />
                            </div> :
                            <div className='item-initial'>
                                {member && member.first_name ? <span style={{color: darkerShade}}>{member.first_name[0]}{member.last_name[0]}</span>: A}
                            </div>}
                            <div className="d-flex flex-column" style={{justifyContent: 'center', maxWidth: '75%', textAlign: 'justify'}}>
                                {member && member.first_name ? <h6 className="mb-1">{member.first_name} {member.last_name}</h6> : <h6 className="mb-1">No name</h6>}
                                <span>{member.email}</span>
                            </div>
                        </div>
                    </div>
            </div>
            </Link>)})}
    </div>
  )
}
