import React, {useState, useEffect, useRef} from 'react'
import { useDispatch } from 'react-redux';
import { login } from '../../../store/loginSlice'; 
import { Link } from 'react-router-dom';
import FeedbackModal from '../../Utilities/FeedbackModal';
import { useSelector } from 'react-redux'
import validateEmail from '../../Utilities/validateEmail';

export default function Login() {
    const dispatch = useDispatch();
    const [loginFormData, setLoginFormData] = useState({ 
        email: '',
        password: '', 
    });

    const loginState = useSelector((state) => state.loginSlice);

    const [loading, setLoading] = useState(false)
    const [emailError, setEmailError] = useState({error: false, message: ''})
    const [passwordError, setPasswordError] = useState({error: false, message: ''})

    const [res, setRes] = useState({
        message: "",
        severity: "success",
        show: false
    })
    

    const handleFormInputChange = (e) => {
        setLoginFormData({
            ...loginFormData,
            [e.target.name]: e.target.value.trim(),
        });
        if (e.target.value.trim().length > 0 && e.target.name === 'email') {
            username.current.classList.remove('input-is-invalid')
            setEmailError({error: false, message: ''})
        }
        if (e.target.value.trim().length > 0 && e.target.name === 'password') {
            password.current.classList.remove('input-is-invalid')
            setPasswordError({error: false, message: ''})
        }
    }

    const validateFields = () => {
        if (!loginFormData.email && !loginFormData.password) {
            username.current.classList.add('input-is-invalid')
            setEmailError({error: true, message: 'Email is required.'})
            password.current.classList.add('input-is-invalid')
            setPasswordError({error: true, message: 'Password is required.'})
            return false;
        } else {
            username.current.classList.remove('input-is-invalid')
            setEmailError({error: false, message: ''})
            password.current.classList.remove('input-is-invalid')
            setPasswordError({error: false, message: ''})
        }
        if (!validateEmail(loginFormData.email) && !loginFormData.password) {
            username.current.classList.add('input-is-invalid')
            setEmailError({error: true, message: `${loginFormData.email} is not a valid email address.`})
            password.current.classList.add('input-is-invalid')
            setPasswordError({error: true, message: 'Password is required.'})
            return false;
        } else {
            username.current.classList.remove('input-is-invalid')
            setEmailError({error: false, message: ''})
            password.current.classList.remove('input-is-invalid')
            setPasswordError({error: false, message: ''})
        }
        if (!loginFormData.email){
            username.current.classList.add('input-is-invalid')
            setEmailError({error: true, message: 'Email is required.'})
            return false;
        } else {
            username.current.classList.remove('input-is-invalid')
            setEmailError({error: false, message: ''})
        }
        if (!validateEmail(loginFormData.email)){
            username.current.classList.add('input-is-invalid')
            setEmailError({error: true, message: `${loginFormData.email} is not a valid email address.`})
            return false;
        } else {
            username.current.classList.remove('input-is-invalid')
            setEmailError({error: false, message: ''})
        }
        if (!loginFormData.password){
            password.current.classList.add('input-is-invalid')
            setPasswordError({error: true, message: 'Password is required.'})
            return false;
        } else {
            password.current.classList.remove('input-is-invalid')
            setPasswordError({error: false, message: ''})
        }
        return true
    }

    const handleSubmit = async (e) => {
        e.preventDefault();        
        setLoading(true)
        if (validateFields()) { 
            const response = await dispatch(login(loginFormData));
            const st = loginState
            if (st.isAuthenticated === false && st.error !== null) {
                setRes({
                    message: response.payload.error,
                    severity: "error",
                    show: true
                })
            }
            setLoading(false)
        } 
        else 
            setLoading(false)
    }

    const username = useRef(null)
    const password = useRef(null)

  return (
      <div className="w-lg-500px bg-body rounded shadow-sm-sm p-10 p-lg-15 mx-auto" style={{overflow: "hidden", position: "relative"}}>
                {/*begin::Form*/}
                <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
                    setRes({
                        message: "",
                        severity: "success",
                        show: false
                    })
                }}/>
                <form className="form w-100" noValidate id="kt_sign_in_form" onSubmit={handleSubmit}>
                    {/*begin::Heading*/}
                    <div className="text-center mb-10">
                        {/*begin::Title*/}
                        <h1 className="text-dark mb-3">Sign In to Beysix</h1>
                        {/*end::Title*/}
                        {/*begin::Link*/}
                        <div className="text-gray-400 fwt-bold fs-5">New Here?
                        <Link to='/auth/sign-up' className="link-primary  m-2">Create an Account</Link></div>
                        {/*end::Link*/}
                    </div>
                    {/*begin::Heading*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-10">
                        {/*begin::Label*/}
                        <label className="form-label fs-6  text-dark">Email</label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input ref = {username} className="form-control form-control-lg form-control-solid" type="text" name="email" autoComplete="off" onChange={handleFormInputChange} />
                        {emailError.error && <div className="fv-plugins-message-container invalid-feedback"><div data-field="email" data-validator="notEmpty">{emailError.message}</div></div>}
                        {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-10">
                        {/*begin::Wrapper*/}
                        <div className="d-flex flex-stack mb-2">
                            {/*begin::Label*/}
                            <label className="form-label  text-dark fs-6 mb-0">Password</label>
                            {/*end::Label*/}
                            {/*begin::Link*/}
                            <Link to='/auth/forgot-password' className="link-primary fs-6 ">Forgot Password?</Link>
                            {/*end::Link*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Input*/}
                        <input ref = {password} className="form-control form-control-lg form-control-solid" type="password" name="password" autoComplete="off" onChange={handleFormInputChange} />
                        {passwordError.error && <div className="fv-plugins-message-container invalid-feedback"><div data-field="password" data-validator="notEmpty">{passwordError.message}</div></div>}
                        {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Actions*/}
                    <div className="text-center">
                        {/*begin::Submit button*/}
                        <button type="submit" disabled={loading ? true : false} id="kt_sign_in_submit" className="btn btn-lg w-100 mb-3 btn-beysix">
                            {!loading ? 
                                <span className="indicator-label">Continue</span>
                                :
                                <span style = {{display: "block"}} className="indicator-progress">Please wait...<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            }
                        </button>
                        {/*end::Submit button*/}
                        {/*begin::Separator*/}
                        {/*<div className="text-center text-muted text-uppercase  mb-3">or</div>
                        end::Separator
                        begin::Google link
                        <a  className="btn btn-flex flex-center btn-light btn-lg w-100 mb-3">
                        <img alt="Logo" src="/static/assets/media/svg/brand-logos/google-icon.svg" className="h-20px me-3" />Continue with Google</a>
                        {/*end::Google link
                        {/*begin::Google link
                        <a  className="btn btn-flex flex-center btn-light btn-lg w-100 mb-3">
                        <img alt="Logo" src="/static/assets/media/svg/brand-logos/facebook-4.svg" className="h-20px me-3" />Continue with Facebook</a>
                        {/*end::Google link
                        {/*begin::Google link
                        <a  className="btn btn-flex flex-center btn-light btn-lg w-100">
                        <img alt="Logo" src="/static/assets/media/svg/brand-logos/apple-black.svg" className="h-20px me-3" />Continue with Apple</a>
                        {/*end::Google link*/}
                    </div>
                    {/*end::Actions*/}
                </form>
                {/*end::Form*/}
            </div>
  )
}
