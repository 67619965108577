import React, {useState} from 'react'
import ToolsAndAccessoriesMultipleSearchComponent from '../../Utilities/ToolsAndAccessoriesMultipleSearchComponent'
import { useSelector } from 'react-redux'
import api from '../../../store/api'
import { useParams } from 'react-router'

export default function ConfigureAccessories({handleUpdateAccessories}) {

    const [accessoriesData, setAccessoriesData] = useState([])
    
    const {id} = useParams()

    const token = useSelector((state) => state.loginSlice.token);

    const [successfullSubmission, setSuccessfullSubmission] = useState(false)

    const [loading, setLoading] = useState(false)

    const handleSubmit = (evt) => {
        evt.preventDefault()
        // setLoading(true)
        if (accessoriesData.length > 0) {
            const data = {accessories: accessoriesData}
            api.post(`/api/activations/configure-tools-and-accessories/${id}/accessories/`, data,
              {headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Token ${token}`,
                  },
              })
              .then((response) => {
                  if (response.status === 201) {
                        const data = response.data.accessories_data
                        setLoading(false)
                        if (data !== null || data !== undefined)
                            handleUpdateAccessories(data, response.data.message)
                      } 
                })
              .catch((error) => {
                setLoading(false)
            })
        }
    }

    const removeAccessory = (id) => {
        const updatedAccessoriesData = accessoriesData.filter(accessory => accessory.id !== id);
        setAccessoriesData(updatedAccessoriesData);
    }

    return (
        <div className="w-100">
        <div className="d-flex flex-column mt-7 fv-row col-md-12">
            <div>
                <ToolsAndAccessoriesMultipleSearchComponent returnResults = {(result) => {
                    if (result) {
                        const id = result.id
                        const exists = accessoriesData.some(accessory => accessory.id === id);
                        if (!exists) {
                            setAccessoriesData([...accessoriesData, {'accessory_name': result.Accessory_name, 'id': id}])
                        }
                    }
                }} left={"75%"} clause={'accessories'} successfullSubmission={successfullSubmission} inputName={'Add Activation Accessories'} selectedTools={null} selectedAccessories={accessoriesData.map(accessory => accessory.id)} /> 
                <div className='row'>
                    {accessoriesData.length > 0 &&
                    <div className='col-md-12'>
                        <div style={{maxHeight: '400px', overflow: 'auto', marginTop: '15px', border: '1px solid #fcc282', padding: '10px', borderRadius: '10px'}}>
                            <p>Accessories</p>
                            <ul>
                            {accessoriesData.map((accessory, index) => (
                                <li 
                                    key={index} 
                                    style={{
                                        display: 'flex', 
                                        justifyContent: 'space-between', 
                                        alignItems: 'center', 
                                        fontSize: '15px', 
                                        marginRight: '15px', 
                                        marginTop: '10px'
                                    }} 
                                    className='badge badge-light-primary'
                                >
                                    <span>{accessory.accessory_name}</span>
                                    <div 
                                        style={{
                                            display: 'flex', 
                                            justifyContent: 'space-between', 
                                            alignItems: 'center', 
                                            fontSize: '15px', 
                                            marginRight: '15px', 
                                            marginTop: '10px'
                                        }}
                                    >
                                        <label htmlFor={`quantity-${index}`}>Quantity:</label>
                                        <input 
                                            type="number" 
                                            id={`quantity-${index}`} 
                                            name="quantity" 
                                            onChange={(e) => {
                                                const newQuantity = parseInt(e.target.value, 10);
                                                setAccessoriesData(prevAccessoriesData =>
                                                    prevAccessoriesData.map((t, i) =>
                                                        i === index ? { ...t, quantity: newQuantity } : t
                                                    )
                                                );
                                            }} 
                                            className="narrow-input" 
                                            min="1" 
                                            value={accessory.quantity ? accessory.quantity : 1}
                                        />
                                        <span 
                                            onClick={() => removeAccessory(accessory.id)} 
                                            className='bi bi-x fs-3' 
                                            style={{ cursor: 'pointer' }}
                                        ></span>
                                    </div>
                                </li>
                            ))}

                            </ul>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'right'}} className='mt-5'>    
            <button onClick={handleSubmit} disabled={loading ? true : false} id="kt_new_password_submit" className="btn btn-sm btn-beysix fwt-bolder">
                {!loading && <span className="indicator-label">Submit</span>}
                {loading && <span className="indicator-progress">Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
            </button>
        </div>
    </div>
    )
}
