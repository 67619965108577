import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from '../components/Layout/Layout';
import { useNavigate } from 'react-router-dom';
import isUrlAllowed from '../components/Utilities/isUrlAllowed';
import withAuthorization from '../components/withAuthorization/withAuthorization';

const PrivateRoutes = ({ routes, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.loginSlice.isAuthenticated);
  const history = useNavigate();
  const allowedUrls = [
                      '/auth/login', '/auth/sign-up', 
                      '/auth/account-approval', '/auth/verify-email',
                      '/auth/account-deactivation', '/auth/404-page-not-found', 
                      '/auth/forgot-password', '/auth/reset-password', '/auth/reset-password-success',
                      '/auth/forgot-password-email', '/auth/verify-email/action', '/auth/successful-account-setup/:id'
                    ]; 

  
  if (!isAuthenticated) {
    if (isUrlAllowed(location.pathname, allowedUrls)) {
      return <Navigate to={location.pathname} />
    }
    else {
      return <Navigate to="/auth/login" />
    } 
  }

  return (
    <Routes>
      {routes.map(({ path, component: Component, permission: Permission }, index) => (
        <Route key={index} path={path} element={
        <Layout>
          {React.createElement(withAuthorization(Component, Permission))}
        </Layout>} />
      ))}
    </Routes>
  );
};

export default PrivateRoutes;