import React, {useRef, useState} from 'react'
import UserMenu from './UserMenu'
import {useSelector} from 'react-redux'

export default function UserProfileButton() {
  
  const profile = useSelector((state) => state.loginSlice.userProfile);
  const [menuOpen, setMenuOpen] = useState(false)

  

  return (
    <div className="d-flex align-items-center ms-1 ms-lg-3" onClick={() => setMenuOpen(!menuOpen)} id="trigger_open_user_submenu">
    <div className="cursor-pointer symbol symbol-30px symbol-md-50px symbol-circle" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
        {profile.profile_photo_path !== '' && profile.profile_photo_path ? <img src={profile.profile_photo_path} style = {{objectFit: 'cover'}} alt={`${profile.first_name} ${profile.last_name} profile picture`} />
        : 
          <span className="svg-icon svg-icon-muted svg-icon-3hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z" fill="black"/>
            <path d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z" fill="black"/>
            </svg>
          </span>
        }
    </div>
    <UserMenu open = {menuOpen} handleClose={() => setMenuOpen(false)} profile={profile} />
    </div>
  )
}
