import React, {useState, useEffect} from 'react'
import api from '../../../store/api'
import {useSelector} from 'react-redux'
import ClientOrganizationsListTable from '../ClientOrganizationsTable/ClientOrganizationsTable';
import { motion } from 'framer-motion';
import FeedbackModal from '../../Utilities/FeedbackModal';
import { Link } from 'react-router-dom';

export default function ClientOrganizationsList() {
  
  const permissions = useSelector((state) => state.loginSlice.permissions);
  const token = useSelector((state) => state.loginSlice.token);
  const [clientOrganizations, setClientOrganizations] = useState([])
  const [ogClientOrganizations, setOgClientOrganizations] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [res, setRes] = useState({
    message: "",
    severity: "success",
    show: false
  })
  const [pageLoading, setPageLoading] = useState(false)
  const [count, setCount] = useState(0)

  const handleSearch = (evt) => {
    evt.preventDefault()
    const value = evt.target.value
    setSearchQuery(value)
    if (value.length > 2) {
      setPageLoading(true)
        api.post(`/api/clients/search-clients/`, {searchQuery: value},
            {headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.results.length > 0){
                        setClientOrganizations(response.data.results)
                        setResultsFound(true)
                    } else {
                        setResultsFound(false)
                    }
                  setPageLoading(false)
                }
            })
            .catch((error) => {
                if (response.status === 404) {

                } else {
                    throw new Error(error)
                }
                setPageLoading(true)
            })
      } else {
          setClientOrganizations(ogClientOrganizations)
          if (ogClientOrganizations.length > 0)
              setResultsFound(true)
      }
  }

  const [resultsFound, setResultsFound] = useState(true)


  const getClientOrganizations = () => {
    setPageLoading(true)
    api.get('/api/clients/clients', {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
    .then((response) => {
      const data = response.data.results
      const count = response.data.count
      setClientOrganizations(data)
      setOgClientOrganizations(data)
      setCount(count)
      setPageLoading(false)
      if (data.length > 0)
        setResultsFound(true)
    })
    .catch((error) => {
      setRes({
        message: error.response.data.error,
        severity: "error",
        show: true
      })
      setPageLoading(false)
    })
  }

  useEffect(() => {
    getClientOrganizations()
  }, [])

  return (
    <>
    {!pageLoading && <motion.p 
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.3 }}style={{fontWeight: '600'}}>{count} Clients Total</motion.p>}
      <div className="card mb-5 mb-xl-8">
      <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
        setRes({
          message: "",
          severity: "success",
          show: false
        })
      }}/>
      {/*begin::Header*/}
      <div className="card-header border-0 pt-5">
      <h3 className="card-title align-items-start flex-column">
        <div className="d-flex align-items-center position-relative my-1">
          <span className="svg-icon svg-icon-1 position-absolute ms-6">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect><path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path></svg>
          </span>
          <input onChange={handleSearch} 
            type="text" 
            className="form-control form-control-solid w-250px ps-14" 
            placeholder="Search client" />
          </div>
        </h3>
        <div className="card-toolbar">
        {permissions['add_agency'] && <Link className="btn btn-sm btn-light-primary" to="/client-management/create-client-organization">
          <span className="svg-icon svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="black"></rect><rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="black"></rect></svg></span>Create Client</Link>}
          </div>
      </div>
      {/*end::Header*/}
      {/*begin::Body*/}
      <div className="card-body py-3">
        {/*begin::Table container*/}
        <ClientOrganizationsListTable clientData={clientOrganizations}  resultsFound={resultsFound} loading={pageLoading} />
      </div>
      {/*begin::Body*/}
    </div>
    </>
  )
}
