import React, {useEffect, useState, useRef} from 'react'
import { useSelector } from 'react-redux';
import api from '../../../store/api';
import getAssetSearchData from '../../Utilities/getAssetSearchData';
import { useParams } from 'react-router';
import { motion } from 'framer-motion';
import SkeletonLoader from '../../Assets/SkeletonLoader/SkeletonLoader';
import { Link } from 'react-router-dom';
import convertCm from '../../Utilities/convertCm';
import parseDateTimestamp from '../../Utilities/parseDateTimeStamp.'
import NoResults from '../../Utilities/NoResults';
import AssetsTable from '../../Assets/AssetsTable/AssetsTable'

export default function AgencyAssets() {

  const [assets, setAssets] = useState([])
  const [pageLoading, setPageLoading] = useState(false)
  const [resultsFound, setResultsFound] = useState(true)
  const {organization, token, user} = useSelector((state) => {
    return {
      'organization': state.loginSlice.organization, 
      'token': state.loginSlice.token,
      'user': state.loginSlice.user
    }
  }); //
  const { id } = useParams()
  const tableRef = useRef(null)


  const getAssets = () => {
      setPageLoading(true)
      let url = `/api/agencies/agency-assets/${id}`
      if (user.user_type === 'client') {
        url = `/api/agencies/agency-assets/${id}/${organization.id}`
      }
      api.get(url,
      {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
      .then((response) => {
        if (response.data.length === 0) {
          setResultsFound(false)
        }
        setAssets(response.data)
        setOriginalAssets(response.data)
        setPageLoading(false)
      })
      .catch((error) => {
          throw new Error(error) 
      })
  }

  useEffect(() => {
    getAssets()
  }, [])

  const [searchQuery, setSearchQuery] = useState('')
  const [originalAssets, setOriginalAssets] = useState([])
  const handleSearchChange = async (e) => {
    const keyword = e.target.value;
    if (keyword.length === 0) {
      setAssets(originalAssets)
    } else {
      setSearchQuery(keyword)
      const assets = await getAssetSearchData(keyword, `/api/agencies/agency-assets/search/${id}/`, token)
      setAssets(assets)
    }
  }

  return (
    <>
      <AssetsTable assetData={assets}  loading={pageLoading} searchQuery={searchQuery} handleSearchChange={handleSearchChange} resultsFound={resultsFound}/>
    </>
  )
}
