import React, {useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import loadRootScripts from '../../Utilities/loadRootScripts';

export default function Root(props) {

    const location = useLocation()
    const { pathname, search, hash } = location;

    useEffect(() => {
        const scripts = loadRootScripts();

        // Cleanup function
        return () => {
        for (let key in scripts)
            document.body.removeChild(scripts[key]); 
        };
    }, [])


  return (
    <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed" style={ !pathname.includes('/auth/verify-email') && 
                                                                                                                                                        !pathname.includes('/auth/account-deactivation') && 
                                                                                                                                                        !pathname.includes('/auth/404-page-not-found') && 
                                                                                                                                                        !pathname.includes('/auth/reset-password-success') &&
                                                                                                                                                        !pathname.includes('/auth/forgot-password-email') &&
                                                                                                                                                        !pathname.includes('/auth/verify-email/action') ? 
                                                                                                                                                        {backgroundImage: "url(/static/assets/media/illustrations/beysix-1/asset-1.png)", minHeight: "100vh"} : 
                                                                                                                                                        {minHeight: "100vh"}}>
        <div style={{backgroundImage: "url(/static/assets/media/logos/beysix-icon.png)", width: "100%", height: "100%", position: "fixed", backgroundRepeat: "no-repeat", left: "83%", top: "-33%", zIndex: -5, opacity: 0.75}}></div>
        {/*begin::Content*/}
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
            {/*begin::Logo*/}
            <a href="/auth/login" className="mb-6">
                <img alt="Logo" src="/static/assets/media/logos/beysix-logo.png" className="h-100px" />
            </a>
            {props.children}
        <div className="d-flex flex-center flex-column-auto p-10">
            {/*begin::Links*/}
            <div className="d-flex align-items-center fw-bold fs-6">
                {/* <a href="https://beysix.co.ke" target="_blank" className="text-muted text-hover-primary px-2">About</a> */}
            </div>
            {/*end::Links*/}
        </div>
        {/*end::Footer*/}
    </div>
    </div>
  )
}
