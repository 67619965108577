import React from 'react'
import { useSelector } from 'react-redux';

export default function TableHead(props) {
    
  const permissions = useSelector((state) => state.loginSlice.permissions);

  return (
    <thead>
    <tr className="text-start text-muted fwt-bolder fs-7 text-uppercase gs-0">
        {/* {props.checkbox ? 
            <th className="w-10px pe-2">
            <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                <input className="form-check-input" type="checkbox" checked={props.allSelected} onChange={props.toggleSelectAll} />
            </div>
        </th> : null} */}
        {props.columns.map((column, index) => (
            <th key={index} className={`min-w-125px ${column.key === 'actions' ? 'text-end' : ''}`}>
                {column}
            </th>
        ))}
        {(props.actions && (permissions["can_approve_user"] || 
          permissions["can_unapprove_user"] || 
          permissions["can_activate_user"] || 
          permissions["can_deactivate_user"])) ? <th className="text-end min-w-100px">Actions</th> : null}                                                     
        </tr> 
    </thead>
  )
}
