import React, {useEffect, useState} from 'react'
import getRandomHexColor from '../../Utilities/getRandomHexColor'
import { Link } from 'react-router-dom'

export default function ShowModalData({data, url}) {

    const [items, setItems] = useState([])

    useEffect(() => {
        setItems(data)
    }, [data])

  return (
    <div className= 'client-members'>
        {items.map((item, index) => {
            const {randomColor, darkerShade, brighterShade} = getRandomHexColor()
            return (
            <Link to={url.concat(item.id)}>
                <div className="alert alert-beysix d-flex align-items-center mb-2 individual-client-member" style={{background: brighterShade, borderColor: 'transparent'}}>
                    <div className="d-flex" key={index}>
                        <div className="d-flex">
                        {item && item.photo ?
                            <div className='asset-item-image-wrapper'>
                                <img className='asset-item-image' src={item.photo && item.photo} alt={item.name} />
                            </div> :
                            <div className='item-initial'>
                                {item && item.name ? <span style={{color: darkerShade}}>{item.name[0]}{item.name[item.name.length - 1]}</span>: A}
                            </div>}
                            <div className="d-flex flex-column" style={{justifyContent: 'center', maxWidth: '75%', textAlign: 'justify'}}>
                                {item && item.name ? <h6 className="mb-1">{item.name}</h6> : <h6 className="mb-1">No name</h6>}
                            </div>
                        </div>
                    </div>
            </div>
            </Link>)})}
    </div>
  )
}
