function darkenHexColor(color) {
    const darkenFactor = 0.8;
    const rgb = parseInt(color.slice(1), 16)
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;
    const darkenedR = Math.floor(r * darkenFactor);
    const darkenedG = Math.floor(g * darkenFactor);
    const darkenedB = Math.floor(b * darkenFactor);
    return `#${(darkenedR << 16 | darkenedG << 8 | darkenedB).toString(16)}`
}

function hexToRGBA(hex, opacity) {
    hex = hex.replace('#', '');
    const red = parseInt(hex.substring(0, 2), 16);
    const green = parseInt(hex.substring(2, 4), 16);
    const blue = parseInt(hex.substring(4, 6), 16);
    return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
}


function brightenHexColor(color) {
    const brightenFactor = 2.8;
    const rgb = parseInt(color.slice(1), 16)
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;
    const brightenedR = Math.min(Math.floor(r * brightenFactor), 255);
    const brightenedG = Math.min(Math.floor(g * brightenFactor), 255);
    const brightenedB = Math.min(Math.floor(b * brightenFactor), 255);
    return `#${(brightenedR << 16 | brightenedG << 8 | brightenedB).toString(16)}`
}

export default function getRandomHexColor() {
    const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16)
    const darkerShade = darkenHexColor(randomColor);
    const bright = brightenHexColor(randomColor);
    const opacity = 0.05;
    const brighterShade = hexToRGBA(bright, opacity);
    return {randomColor, darkerShade, brighterShade}
}