import React, {useState, useEffect} from 'react'
import api from '../../../store/api'
import { useSelector } from 'react-redux';
import getRandomHexColor from '../../Utilities/getRandomHexColor';
import FeedbackModal from '../../Utilities/FeedbackModal';
import ProfileImageInput from '../../Utilities/ProfileImageInput';

export default function CreateClientOrganization() {
  const token = useSelector((state) => state.loginSlice.token);
  const [searchQuery, setSearchQuery] = useState("")
  const [searchResults, setSearchResults] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const [userSearching, setUserSearching] = useState(false)
  const [resultsFound, setResultsFound] = useState(false)
  
  const handleSearch = (evt) => {
    evt.preventDefault()
    const query = evt.target.value;
        setSearchQuery(query)
        if (query.length >= 2) {
            setUserSearching(true)
            setIsSearching(true)
            api.post(`/api/users/search-user/clients/`, {query: query},
            {headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.results.length > 0){
                        setResultsFound(true)
                        setSearchResults(response.data.results)
                        setIsSearching(false)
                    } else {
                        setResultsFound(false)
                        setSearchResults(response.data.results)
                        setIsSearching(false)
                    }
                }
            })
            .catch((error) => {
                if (response.status === 404) {

                } else {
                    throw new Error(error)
                }
            })
        } else if (query.length === 0) {
            setResultsFound(false)
            setIsSearching(false)
            setUserSearching(false)
        }
  }

  const handleCancelSearch = (evt) => {
      evt.preventDefault()
      setResultsFound(false)
      setIsSearching(false)
      setUserSearching(false)
      setSearchResults([])
      setResultsFound(false)
      setSearchQuery("")
  }

  const [clientData, setClientData] = useState(
    {
      clientOrganizationName: '',
      clientPhysicalAddress: '',
      clientDescription: '',
      clientManagerId: '',
      clientImage: null
    }
  )

  const handleManagerClick = (id, name) => {
      setClientData({...clientData, clientManagerId: id})
      setResultsFound(false)
      setIsSearching(false)
      setUserSearching(false)
      setSearchResults([])
      setResultsFound(false)
      setSearchQuery(name)
  }
  
  const [res, setRes] = useState({
    message: "",
    severity: "success",
    show: false
  })

  const handleChange = (evt) => {
    evt.preventDefault()
    if (evt.target)
      setClientData({...clientData, [evt.target.name]: evt.target.value})
  }

  const [errors, setErrors] = useState(
    {
      clientOrganizationNameError: '',
      clientPhysicalAddressError: '',
      clientDescriptionError: ''
    }
  )

  const handleValidate = (data) => {
    if (data.clientOrganizationName.length <= 0) {
      setErrors({...errors, clientOrganizationNameError: 'The name of the client organization cannot be empty.'})
      return false
    } else {
      setErrors({...errors, clientOrganizationNameError: ''})
    }

    return true
  }

  const [SuccessfullSumbission, setSuccessfullSumbission] = useState(false)

  const handleSubmit = (evt) => {
    evt.preventDefault()
    if (handleValidate(clientData)) {
      
      const formData = new FormData()
      formData.append('clientOrganizationName', clientData.clientOrganizationName)
      formData.append('clientPhysicalAddress', clientData.clientPhysicalAddress)
      formData.append('clientDescription', clientData.clientDescription)
      formData.append('clientManagerId', clientData.clientManagerId)
      formData.append('clientImage', clientData.clientImage)

      api.post('/api/clients/clients', formData,
			{headers: {'Content-Type': 'multipart/form-data', Authorization: `Token ${token}`}})

      .then((response) => {
        const message = response.data.message
        setRes({
          message: message,
          severity: "success",
          show: true
        })
        setClientData({
          clientOrganizationName: '',
          clientPhysicalAddress: '',
          clientDescription: '',
          clientManagerId: '',
          clientImage: null
        })
        setErrors(
          {
            clientOrganizationNameError: '',
            clientPhysicalAddressError: '',
            clientDescriptionError: ''
          }
        )
        setSuccessfullSumbission(true)
        setResultsFound(false)
        setIsSearching(false)
        setUserSearching(false)
        setSearchResults([])
        setResultsFound(false)
        setSearchQuery("")
      })

      .catch((error) => {
        setRes({
          message: error.response.data.error,
          severity: "error",
          show: true
        })
      })
    }
  }

  return (
    <>
    <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
			setRes({
				message: "",
				severity: "success",
				show: false
			})
		}}/>
      <form className="form">
									{/*begin::Card*/}
									<div className="card">
										{/*begin::Card header*/}
										<div className="card-header">
											{/*begin::Card header*/}
											<div className="card-title fs-4 fwt-bolder">Create Client Organization</div>
											{/*end::Card header*/}
										</div>
										{/*end::Card header*/}
										{/*begin::Card body*/}
										<div className="card-body" style={{maxWidth: "80%", margin: "0 auto", minWidth: "70%"}}>
                    <div className="py-10 px-lg-17">
													{/*begin::Scroll*/}
													<div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px" style={{maxHeight: "476px"}}>
														<div className="row mb-3">
															{/*begin::Col*/}
                              <div className="col-md-12 fv-row fv-plugins-icon-container mb-4">
                                  <div className="col-md-6 fv-row fv-plugins-icon-container">
                                    {/*begin::Label*/}
                                    <label className="required fs-6 fwt-bold mb-2">Client Organization Image</label>
                                    {/*end::Label*/}
                                    {/*begin::Input*/}
                                    <ProfileImageInput setImage={(image) => {
                                      setClientData({...clientData, clientImage: image})
                                      }} edit={false} image={clientData.clientImage} 
                                      SuccessfullSumbission={SuccessfullSumbission} />
                                    </div>
                                </div>
															<div className="col-md-6 fv-row fv-plugins-icon-container">
																{/*begin::Label*/}
																<label className="required fs-6 fwt-bold mb-2">Client Organization Name</label>
																{/*end::Label*/}
																{/*begin::Input*/}
																<input type="text" name="clientOrganizationName" className="form-control form-control-solid" placeholder="" value={clientData.clientOrganizationName} onChange={handleChange} />
																<div className="fv-plugins-message-container invalid-feedback"><div data-field="account_name" data-validator="notEmpty">{errors.clientOrganizationNameError}</div></div>{
                                /*end::Input*/}
															<div className="fv-plugins-message-container invalid-feedback"></div></div>
															{/*end::Col*/}
															{/*begin::Col*/}
															<div className="col-md-6 fv-row fv-plugins-icon-container">
																{/*end::Label*/}
																<label className="required fs-6 fwt-bold mb-2">Physical Address/Location</label>
																{/*end::Label*/}
																{/*end::Input*/}
																<input type="text" name="clientPhysicalAddress" className="form-control form-control-solid" placeholder="" value={clientData.clientPhysicalAddress} onChange={handleChange} />
																{/*end::Input*/}
															<div className="fv-plugins-message-container invalid-feedback"></div></div>
															{/*end::Col*/}
														</div>
														{/*end::Input group*/}
														{/*begin::Input group*/}
													  <div className="fv-plugins-message-container invalid-feedback"></div></div>
														<div className="fv-row mb-10 fv-plugins-icon-container">
                            {/*begin::Label*/}
                            <label className="form-label required">Client Organization Manager</label>
                            {/*end::Label*/}
                            <div>
                            <div>
                                {/*begin::Search*/}
                                <div id="kt_docs_search_handler_basic" data-kt-search-keypress="true" data-kt-search-min-length="2" data-kt-search-enter="true" data-kt-search-layout="inline" data-kt-search="true" className="">
                                    <input type="hidden" />
                                    <div style={{position: 'relative'}}>
                                    <span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500" style={{position: "absolute", left: "2%", top: '25%'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                                            <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path>
                                        </svg>
                                    </span>
                                        <input type="text" autoComplete='off' className="form-control form-control-lg form-control-solid px-15" name="search" value={searchQuery} placeholder="Search by username, full name or email..." onChange={handleSearch} />
                                        {isSearching ? <span className="lh-0 me-5" data-kt-search-element="spinner" style={{position: "absolute", top: "35%", left: "94%"}}>
                                            <span className="spinner-border h-15px w-15px align-middle text-gray-400"></span>
                                        </span> :
                                        searchQuery.length > 0 && 
                                        <span className="btn btn-flush btn-active-color-primary end-0 lh-0" style={{position: "absolute", top: "25%", left: "93%"}} data-kt-search-element="clear" onClick={handleCancelSearch}>
                                            <span className="svg-icon svg-icon-2 svg-icon-lg-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                                                </svg>
                                            </span>
                                        </span>}
                                {userSearching && 
                                <div className="quick-search-dropdown" style={{position: "absolute"}}>
                                    {searchResults.length > 0 && resultsFound ? <div data-kt-search-element="results" className="">
                                    {searchResults.map((result, index) => {
                                    const { randomColor, darkerShade, brighterShade } = getRandomHexColor();
                                    return (
                                    <div className="d-flex align-items-center p-3 rounded-3 border-hover border border-dashed border-gray-300 cursor-pointer mb-1"key = {index} onClick={() => handleManagerClick(result.id, result.profile && `${result.profile.first_name} ${result.profile.last_name}`)}>
                                        <div className="symbol symbol-35px symbol-circle me-5">
                                            {result.profile && (result.profile.profile_photo_path !== "" && result.profile.profile_photo_path !== null) ?
                                            <img alt="Pic" src={result.profile.profile_photo_path} style={{objectFit: "cover"}}/> :
                                            result.profile && <div className="symbol-label fs-5" style={{ background: brighterShade, color: darkerShade }}>{result.profile.first_name[0]}{result.profile.last_name[0]}</div>}
                                        </div>
                                        <div className="fw-bold">
                                            <span className="fs-6 text-gray-800 me-2">{result.profile && `${result.profile.first_name} ${result.profile.last_name}`}</span>
                                            <span className="badge badge-light">{result.profile && result.profile.occupation}</span>
                                        </div>
                                    </div>)})}
                                    </div> :
                                    <div data-kt-search-element="empty" className="text-center">
                                        {/*begin::Message*/}
                                        <div className="fwt-bold py-0 mb-10">
                                            <div className="text-gray-600 fs-3 mb-2">No users found</div>
                                            <div className="text-gray-400 fs-6">Try to search by username, full name or email...</div>
                                        </div>
                                        {/*end::Message*/}
                                        {/*begin::Illustration*/}
                                        <div className="text-center px-4">
                                            <img className="mw-100 mh-200px" alt="" src="assets/media/illustrations/alert.png" />
                                        </div>
                                        {/*end::Illustration*/}
                                    </div>}
                                    {/*end::Empty*/}
                                </div>}
                                {/*end::Wrapper*/}
                                            </div>
                                            {/*end::Search*/}
											</div>
										</div>
                                        </div>
														<div className="fv-plugins-message-container invalid-feedback"></div></div>
                            <div className="d-flex flex-column mb-3 fv-row fv-plugins-icon-container">
															{/*begin::Label*/}
															<label className="required fs-6 fwt-bold mb-2">Description</label>
															{/*end::Label*/}
															{/*begin::Input*/}
                              <textarea className="form-control form-control-solid" name="clientDescription" rows="7" cols="30" onChange={handleChange} value={clientData.clientDescription}></textarea>
															{/*end::Input*/}
														<div className="fv-plugins-message-container invalid-feedback"></div></div>
													</div>
													{/*end::Scroll*/}
												</div>
                      {/*begin::Card footer*/}
                      <div className="card-footer d-flex justify-content-end py-6">
                        <button type="submit" className="btn btn-beysix" onClick={handleSubmit}>Create Client</button>
                      </div>
                      {/*end::Card footer*/}
                    </div>
								</form>
    </>
  )
}
