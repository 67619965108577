import React, {useState, useEffect, useRef} from 'react'
import api from '../../store/api'
import {useSelector} from 'react-redux'
import LoaderGrid from '../Utilities/LoaderGrid/LoaderGrid'


export default function UpdateUserEmailModalBody({user_email, user_id, handleSuccessfullChange, handleErrors, handleChangeStep}) {

    const [loading, setLoading] = useState(false)
    const [ogEmailAddress, setOgEmailAddress] = useState(user_email)
    const [email, setEmail] = useState(user_email)
    const [message, setMessage] = useState('')
    const [error, setError] = useState(false)
    const [successResponseMessage, setSuccessResponseMessage] = useState('')
    const [pendingEmailChangeRequest, setPendingEmailChangeRequest] = useState(false)
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [otpLoading, setOtpLoading] = useState(false)
    const [showOtpInput, setShowOtpInput] = useState(true)
    const token = useSelector((state) => state.loginSlice.token)
    const emailInput = useRef(null)
    const otpInput = useRef(null)

    const inputs = Array.from({ length: 6 }, (_, i) => i);

    const handleOTPChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        if (value !== '' && index < 5) {
            document.getElementById(`otpInput${index + 1}`).focus();
        }
    };

    const handleOTPPaste = (e, index) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text');
        const pastedValues = pastedData.split('').slice(0, 6);
        const newOtp = [...otp];
        pastedValues.forEach((value, i) => {
            const newIndex = index + i;
            if (newIndex <= 5) {
                newOtp[newIndex] = value;
            }
        });
        setOtp(newOtp);
    };

    useEffect(() => {
        setEmail(user_email)
        setOgEmailAddress(user_email)
        checkForPendingEmailChangeRequests()
        handleOTPSubmit()
    }, [user_email, otp])

    const checkForPendingEmailChangeRequests = () => {
        api.get(`/api/users/check-pending-email-change-requests/${user_id}/`,  {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
        })
        .then((response) => {
            if (response.status === 200) {
                const data = response.data
                if (data.pending_email_change_request) {
                    setPendingEmailChangeRequest(true)
                    setShowOtpInput(data.show_otp_input)
                    setSuccessResponseMessage(data.message)
                    handleChangeStep(true)
                }
            }
        })
        .catch((error) => {
            const err = error.response.data.error
            handleErrors(err)
            setOtpLoading(false)
        })
    }

    const handleSubmit = (evt) => {
        evt.preventDefault()
        console.log(email)
        if (email !== ogEmailAddress) {
            api.put(`/api/users/change-user-email/${user_id}/`, {
                'newEmail': email}, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${token}`,
                    },
            })
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data
                    if (data.change_step) {
                        setSuccessResponseMessage(data.message)
                        if (emailInput.current) {
                            setPendingEmailChangeRequest(true)
                        }
                        handleChangeStep(true)
                    } else {
                        handleSuccessfullChange(data.message)
                    }
                }
            })
            .catch((error) => {
                const err = error.response.data.error
                handleErrors(err)
                setOtpLoading(false)
            })
        } else {
            setError(true)
            setMessage('The email address you have entered is similar to the one that already exists.')
        }
    }

    const validateEmailAddress = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const deactivateEmailChangeRequest = () => {
        api.put(`/api/users/abandon-user-email-change-request/${user_id}/`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
        })
        .then((response) => {
            if (response.status === 200) {
                const data = response.data
                handleSuccessfullChange(data.message)
                setPendingEmailChangeRequest(data.pending_email_change_request)
                handleChangeStep(false)
            }
        })
        .catch((error) => {
                handleErrors(error.response.data.error)
        })
    }

    const handleOTPSubmit = () => {
        const filledInputs = otp.filter(value => value !== '').length;
        if (filledInputs === 6) {
            setOtpLoading(true)
            api.post('/api/users/verify-email-change-otp/', {
                'otp': otp, 'user_id': user_id}, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${token}`,
                    },
            })
            .then((response) => {
                if (response.status === 200) {
                    const updatedUser = response.data.user
                    const message = response.data.message
                    setPendingEmailChangeRequest(response.data.pending_email_change_request)
                    setOtp(['', '', '', '', '', ''])
                    handleSuccessfullChange(message, updatedUser)
                    setOtpLoading(false)
                }
            })
            .catch((error) => {
                const err = error.response.data.error
                handleErrors(err)
                setOtp(['', '', '', '', '', ''])
                setOtpLoading(false)
            })
        }
    }

  return (
    <div>
    <form id="kt_modal_update_email_form" className={!pendingEmailChangeRequest ? "form element-0" : "form element-0 hidden"} onSubmit={handleSubmit} ref={emailInput}>
        <div className="notice d-flex bg-light-primary rounded border-beysix border border-dashed mb-9 p-6">
            <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                    <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black" />
                    <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black" />
                </svg>
            </span>
            <div className="d-flex flex-stack flex-grow-1">
                <div className="fwt-bold">
                    <div className="fs-6 text-gray-700">Please note that a valid email address is required to complete the email verification.</div>
                </div>
            </div>
        </div>
        <div className="fv-row mb-7">
            <label className="fs-6 fwt-bold form-label mb-2">
                <span className="required">Email Address</span>
            </label>
            <input className="form-control form-control-solid" name="email" value={email} onChange={(evt) => {
                                                                                            setEmail(evt.target.value)
                                                                                            if (!validateEmailAddress(evt.target.value) && evt.target.value !== '') {
                                                                                                setError(true)
                                                                                                setMessage(`${evt.target.value} is not a valid email address`)
                                                                                            } else {
                                                                                                setError(false)
                                                                                            }
                                                                                            }} />
            {error && <div className="fv-plugins-message-container invalid-feedback"><div data-field="email" data-validator="emailAddress">{message}</div></div>}
        </div>
        <div className="text-center pt-15">
            <button type="reset" disabled={loading ? true : false } className="btn btn-light me-3" data-kt-users-modal-action="cancel">Discard</button>
            <button type="submit" disabled={loading ? true : false } className="btn btn-beysix" data-kt-users-modal-action="submit">
                {!loading ? <span className="indicator-label">Submit</span> :
                <span className="indicator-progress">Please wait... 
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                }
            </button>
        </div>
    </form>
    <form className={pendingEmailChangeRequest ? "form w-100 mb-10" :  "form w-100 mb-10 element-0 hidden"} novalidate="novalidate" id="kt_sing_in_two_steps_form" ref={otpInput}>
        <div className="notice d-flex bg-light-success rounded border-success border border-dashed mb-9 p-6">
            <span className="svg-icon svg-icon-2tx svg-icon-success me-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                    <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black" />
                    <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black" />
                </svg>
            </span>
            <div className="d-flex flex-stack flex-grow-1">
                <div className="fwt-bold">
                    <div className="fs-6 text-gray-700">{successResponseMessage}</div>
                </div>
            </div>
        </div>

        {showOtpInput ? !otpLoading ? <div className="mb-10 px-md-10">
            {/*begin::Label*/}
            <div className="fw-bolder text-start text-dark fs-6 mb-1 ms-1">Type your 6 digit one time pin (OTP)</div>
            {/*end::Label*/}
            {/*begin::Input group*/}
            <div className="d-flex flex-wrap flex-stack">
            {inputs.map(index => (
                <input
                    key={index}
                    id={`otpInput${index}`}
                    type="text"
                    maxLength="1"
                    className="form-control form-control-solid h-60px w-60px fs-2qx text-center border-beysix border-hover mx-1 my-2"
                    value={otp[index]}
                    onChange={(e) => handleOTPChange(index, e.target.value)}
                    onPaste={(e) => handleOTPPaste(e, index)}
                />
            ))}
            </div>
            <div className="text-gray-600 fs-6 text-center"> Changed Your Mind? <a onClick={deactivateEmailChangeRequest} className="me-1">Abandon Email Change Request</a></div>
        </div>: <LoaderGrid /> : null}
        </form> 
    </div>
  )
}
