import React, {useEffect, useState, useRef} from 'react'
import { useNavigate, useParams } from 'react-router'
import api from '../../../store/api'
import { useSelector } from 'react-redux'
import MainModal from '../../Modals/MainModal'
import { useSpring, animated } from "react-spring";
import Modal from "react-modal";
import FeedbackModal from '../../Utilities/FeedbackModal'
import { Link } from 'react-router-dom'

export default function Dispatch() {
    const { id } = useParams()
    const [submitLoading, setSubmitLoading] = useState(false)
    const [dispatchData, setDispatchData] = useState({
      activation: 0,
      activation_data: {name: "", id: 0, status: ''},
      created_at: "",
      created_by: 0,
      dispatch_checklist: [],
      id: 0,
      updated_at: "",
      updated_by: 0,
      activation_crew_data: [],
      item_serial_conditions: []
    })
    const [checklist, setChecklist] = useState([])
    const [conditions, setConditions] = useState([])
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [mainImageIndex, setMainImageIndex] = useState(0);
    const vehiclesDivRef = useRef(null)
    const [crew, setCrew] = useState([])
    const navigate = useNavigate(null)

    const [errors, setErrors] = useState({})
    const [vehicles, setVehicles] = useState([
      { driverFullName: '', driverPhoneNumber: '', vehicleNumberPlate: '' },
    ]);
    const fileInputRef = useRef(null);
    const addFilesInputRef = useRef(null);
    const [selectedImages, setSelectedImages] = useState([]);
    const [imagesArray, setImagesArray] = useState([])
    const [selectedConditions, setSelectedConditions] = useState([]);
    const [res, setRes] = useState({
        message: '',
        severity: "success",
        show: false
    })
    const [dispatchComment, setDispatchComment] = useState('')

    // Handle thumbnail click to change main image
    const handleThumbnailClick = (index) => {
      setMainImageIndex(index);
    };

    const [activeAssetImage, setActiveAssetImage] = useState(null)

    // Animation for modal using react-spring
    const modalSpring = useSpring({
      opacity: modalIsOpen ? 1 : 0,
      transform: modalIsOpen ? `translateY(0)` : `translateY(100%)`,
    });

    // Handle modal close
    const closeModal = () => {
      setModalIsOpen(false);
    };

    const {organization, token} = useSelector((state) => {
      return {
        'organization': state.loginSlice.organization, 
        'token': state.loginSlice.token
      }
    });

    useEffect(() => {
        api.get(`/api/activations/dispatch-checklist-data/${id}/`, {
            headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}
          })
          .then((response) => {
            const data = response.data
            setDispatchData(data.data)
            setChecklist(data.data.dispatch_checklist)
            setCrew(data.data.activation_crew_data)
            setConditions(data.data.item_serial_conditions)
          })
          .catch((error) => {
            throw new Error(error)
          })
    }, [])

      // Function to add a new vehicle to the array
      const handleAddAnotherVehicle = () => {
        setVehicles([
          ...vehicles,
          { driverFullName: '', driverPhoneNumber: '', vehicleNumberPlate: '' },
        ]);
      };

      // Handle input change for a specific vehicle
      const handleVehicleChange = (index, field, value) => {
        const updatedVehicles = vehicles.map((vehicle, i) =>
          i === index ? { ...vehicle, [field]: value } : vehicle
        );
        setVehicles(updatedVehicles);
      };

    
    const handleClick = (evt, id) => {
      const foundItem = imagesArray.find(item => item.assetId === id);
      if (foundItem) {
        setSelectedImages(foundItem.images);
        setMainImageIndex(0); // Set the first image as the main image
        setModalIsOpen(true);
      } else {
        fileInputRef.current.click();
      }
      setActiveAssetImage(id)
    };
    
    
    const handleAddFilesClick = () => {
        addFilesInputRef.current.click();
    };

    const setUpImages = () => {
      setImagesArray((prevImagesArray) => {
        // Check if assetId already exists in imagesArray
        const assetExists = prevImagesArray.find((item) => item.assetId === activeAssetImage);
    
        if (assetExists) {
          // If assetId exists, update the existing object with new images (or handle as needed)
          return prevImagesArray.map((item) =>
            item.assetId === activeAssetImage
              ? { ...item, images: selectedImages } // Update the images for the existing assetId
              : item
          );
        } else {
          // If assetId doesn't exist, add a new object
          return [...prevImagesArray, { assetId: activeAssetImage, images: selectedImages }];
        }
      });
    
      closeModal();
      setSelectedImages([]);
    };

    // Handle file selection and create image objects
    const handleFileChange = (event) => {
      const files = Array.from(event.target.files);
      const imageObjects = files.map((file) => ({
        image: URL.createObjectURL(file),
        comment: "", 
        file: file
      }));

      setSelectedImages(imageObjects);
      setMainImageIndex(0); // Set the first image as the main image
      setModalIsOpen(true);
    };


    // Handle file selection and create image objects
    const handleAddMoreFilesChange = (event) => {
      const files = Array.from(event.target.files);
      const imageObjects = files.map((file) => ({
        image: URL.createObjectURL(file),
        comment: "", 
        file: file
      }));

      setSelectedImages([...selectedImages, ...imageObjects]);
    };

    const validateConditionSelectInputs = () => {
      let status = true
      const conditionSelect = document.querySelectorAll('.condition-select')
      if (conditionSelect.length > 0) {
        for (let i  = 0; i < conditionSelect.length; i++) {
          if (parseInt(conditionSelect[i].value) === 0) {
            setRes({
              message: "You haven't selected conditions for all items.",
              severity: "error",
              show: true
            })
            conditionSelect[i].classList.add('input-is-invalid')
            status = false
          }
        }
      }
      return status
    }

    const validateVehicles = () => {
      for (let i = 0; i < vehicles.length; i++) {
        for (let key in vehicles[i]) {
          if (vehicles[i][key] === '') {
            setRes({
              message: "Transportation details have not been configured correctly.",
              severity: "error",
              show: true
            })
            return false
          }
        }
      }
      return true
    }

    const handleDispatch = (evt) => {
      evt.preventDefault()
      const formData = new FormData()
      formData.append('logistics', JSON.stringify(vehicles));
      formData.append('comment', JSON.stringify(dispatchComment));
      formData.append('itemConditions', JSON.stringify(selectedConditions));
      formData.append('imagesArray', JSON.stringify(imagesArray));
      
      // Add any file objects separately
      imagesArray.forEach((asset, assetIndex) => {
        asset.images.forEach((imageObject, imageIndex) => {
          formData.append(`file_${assetIndex}_${imageIndex}`, imageObject.file);
        });
      });

      const _validateConditionSelectInputs = validateConditionSelectInputs()
      const _validateVehicles = validateVehicles()

      if (_validateConditionSelectInputs && _validateVehicles) {
          setSubmitLoading(true)
          api.post(`/api/activations/handle-dispatch/${id}/`, formData,
            {headers: {
                    'Content-Type': 'multi-part/formdata',
                    Authorization: `Token ${token}`,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    // navigate(`/activation-management/detailed-activation/${id}?dispatched=true`)
                    setSubmitLoading(false)
                } 
              })
            .catch((error) => {
              setRes({
                message: error.response.data.error,
                severity: "error",
                show: true
              })
              setSubmitLoading(false)
          })
        }
    }

     // Handle updating comment for the main image
    const handleCommentChange = (newComment) => {
      setSelectedImages((prevImages) =>
        prevImages.map((imgObj, index) =>
          index === mainImageIndex ? { ...imgObj, comment: newComment } : imgObj
        )
      );
    };

    const handleChangeCondition = (evt, id) => {
      const value = evt.target.value;
      
      // Update the selected condition for the specific item ID
      setSelectedConditions(prevConditions => {
        // Check if the item already exists in the array
        const existingConditionIndex = prevConditions.findIndex(item => item.itemSerialId === id);

        // If it exists, update the existing condition, otherwise add a new one
        if (existingConditionIndex !== -1) {
          const updatedConditions = [...prevConditions];
          updatedConditions[existingConditionIndex] = { itemSerialId: id, condition: value };
          return updatedConditions;
        } else {
          return [...prevConditions, { itemSerialId: id, condition: value }];
        }
      });
    };

    const handleDeleteImage = (index) => {
      const updatedImages = selectedImages.filter((_, i) => i !== index);
      setSelectedImages(updatedImages);
  
      // Adjust mainImageIndex if necessary
      if (index === mainImageIndex) {
        setMainImageIndex(0); // Set to first image or adjust as needed
      } else if (index < mainImageIndex) {
        setMainImageIndex(mainImageIndex - 1);
      }

      if (updatedImages.length === 0) {
        closeModal()
      }
    };
  
    
  return (<>
  {/* {(dispatchData.activation_data.status !== 'dispatched') ? */}
    <div class="cont">
      <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
        setRes({
          message: "",
          severity: "success",
          show: false
        })
      }}/>
  <div class="bask-disp">
    <div class="bask-head">
      <h3>Dispatch for {dispatchData.activation_data.name}</h3>
    </div>
    <div class="bask-body">
      {checklist.map((asset) => <><div class="baski">
        <div class="baski-ihld">
          <img src={asset.asset_image} alt={asset.asset_name} />
        </div>
        <div class="baski-txt">
          <h5>{asset.asset_name}</h5>
          <div class="exdsp">
            <p class='text-muted'>{asset.asset_description}</p>
          </div>
          <div class="baski-tags" style={{justifyContent: 'space-between'}}>
              <div class="baski-tag">{asset.asset_project}</div>
              <a onClick={(evt) => handleClick(evt, asset.asset_id)} class="d-flex align-items-center text-dark text-hover-primary me-3 mb-2" style={{fontWeight: 500}}>
                  <span class="svg-icon svg-icon-2x me-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path opacity="0.3" d="M22 5V19C22 19.6 21.6 20 21 20H19.5L11.9 12.4C11.5 12 10.9 12 10.5 12.4L3 20C2.5 20 2 19.5 2 19V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5ZM7.5 7C6.7 7 6 7.7 6 8.5C6 9.3 6.7 10 7.5 10C8.3 10 9 9.3 9 8.5C9 7.7 8.3 7 7.5 7Z" fill="black"/>
                  <path d="M19.1 10C18.7 9.60001 18.1 9.60001 17.7 10L10.7 17H2V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V12.9L19.1 10Z" fill="black"/>
                  </svg>
                  </span>
                  Upload Images
              </a>

                {/* Hidden file input */}
              <input
                  type="file"
                  ref={fileInputRef}
                  multiple
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />

                {/* Modal with React Spring animation */}
              
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Image Preview Modal"
                className="custom-modal"
                overlayClassName="custom-modal-overlay"
              >
                <animated.div style={modalSpring}>
                  <div>
                    <div id="kt_modal_update_user_header" className='d-flex align-items-center' style={{padding: '10px 40px', justifyContent: 'space-between'}}>
                      <h5 class="fwt-bolder text-dark">Upload Images</h5>
                      <div class="btn btn-icon btn-sm btn-active-icon-primary" onClick={closeModal}>
                        <span class="svg-icon svg-icon-1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect><rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect></svg>
                        </span>
                      </div>
                    </div>

                    {/* Main Image Preview */}
                    {selectedImages[mainImageIndex]?.image && (
                      <div className="main-image-container">
                        <img
                          src={selectedImages[mainImageIndex].image}
                          alt="Main Preview"
                          className="main-image"
                        />
                        <div className="delete-image-overlay">
                          <div className="delete-icon" onClick={() => handleDeleteImage(mainImageIndex)}>
                            <i className='bi bi-x'></i>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Thumbnails */}
                    <div className="thumbnail-section">
                      {selectedImages.map((imgObj, index) => (
                        <img
                          key={index}
                          src={imgObj.image}
                          alt={`Thumbnail ${index}`}
                          className={`thumbnail ${index === mainImageIndex ? "active" : ""}`}
                          onClick={() => handleThumbnailClick(index)}
                        />
                      ))}
                      <div>
                        {/* Hidden file input */}
                        <input
                          type="file"
                          ref={addFilesInputRef}
                          onChange={handleAddMoreFilesChange}
                          style={{ display: 'none' }} 
                          multiple 
                        />

                        {/* Button styled with SVG */}
                        <span
                          className="svg-icon svg-icon-dark svg-icon-4hx"
                          onClick={handleAddFilesClick} // Trigger the file input click
                          style={{ cursor: 'pointer' }} // Change cursor to pointer
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                              opacity="0.3"
                              d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 13H13V10C13 9.4 12.6 9 12 9C11.4 9 11 9.4 11 10V13H8C7.4 13 7 13.4 7 14C7 14.6 7.4 15 8 15H11V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18V15H16C16.6 15 17 14.6 17 14C17 13.4 16.6 13 16 13Z"
                              fill="black"
                            />
                            <path
                              d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>

                    {/* Single Comment Box for Selected Image */}
                    <div className='row'>
                      <div className="comment-box col-md-11">
                        <textarea
                          placeholder="Add a comment or description"
                          value={selectedImages[mainImageIndex]?.comment || ""}
                          className="form-control form-control-solid"
                          onChange={(e) => handleCommentChange(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="comment-box col-md-1">
                      <div class="add-item-btn-wrapper">
                          <span class="add-item-btn" onClick={setUpImages}><i class="fas fa-check" style={{fontSize: '21px'}}></i></span>
                      </div>
                      </div>
                    </div>
                  </div>
                </animated.div>
              </Modal>

          </div>
        </div>
      </div>
          {asset.item_serials.map((item) => <div class="baski">
              <div class="baski-ihld" style={{marginLeft: '60px', borderLeft: '5px solid #fa890f', width: '90px'}}>
                <img src={item.serial_image} alt={item.serial_name} style={{
                  width: '70px',
                  height: '70px'
              }}/>
              </div>
              <div class="baski-txt">
                <h5>{item.serial_name}</h5>
                <div class="exdsp">
                  <p class='text-muted'>{item.serial_no}</p>
                </div>
                <div class="d-flex" style={{justifyContent: 'space-between', alignItems: 'center', marginRight: '10px'}}>
                <span class="required" style={{color: '#000', fontWeight: 500}}>Condition:</span>
                <select id="dropdown" name="brandClient" onChange={(evt) => handleChangeCondition(evt, item.serial_id)} class="form-control form-control-solid condition-select" style={{maxWidth: '65%'}}>
                    <option value="0">-- Select Condition --</option>
                    {conditions.map((condition) => <option value={condition.id}>{`${condition.value} - ${condition.condition_name}`}</option>)}
                </select>
                </div>
              </div>
        </div>)}
      </>)}
    </div>
  </div>
  <div class="card-disp">
    <div style={{borderTop:'5px solid white', borderBottom: '5px solid white', paddingBottom: '20px'}}>
    <span style={{fontSize: '14px', fontWeight: 600, paddingLeft: '20px', display: 'block', marginTop: '15px'}}>Transportation details </span>
    <div
      style={{
        maxHeight: '400px',
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: '0px 20px',
        marginTop: '15px',
      }}
      ref={vehiclesDivRef}
    >
      {/* Render each vehicle form */}
      {vehicles.map((vehicle, index) => (
        <div className="col-md-12" key={index}>
          <div className="mt-3">
            {vehicles.length > 1 && <span className='badge badge-dark'>Vehicle {index + 1}</span>}
            <div className="row">
              <div className="d-flex flex-column mt-7 fv-row col-md-6">
                <label className="d-flex align-items-center fs-6 fwt-bold form-label mb-2">
                  <span className="required">Driver Full Name</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    title="Name of the driver"
                  ></i>
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  className="form-control form-control-solid"
                  placeholder=""
                  name="driverFullName"
                  value={vehicle.driverFullName}
                  onChange={(e) =>
                    handleVehicleChange(index, 'driverFullName', e.target.value)
                  }
                />
              </div>

              <div className="d-flex flex-column mt-7 fv-row col-md-6">
                <label className="d-flex align-items-center fs-6 fwt-bold form-label mb-2">
                  <span className="required">Driver Phone Number</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    title="Driver phone number"
                  ></i>
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  className="form-control form-control-solid"
                  placeholder=""
                  name="driverPhoneNumber"
                  value={vehicle.driverPhoneNumber}
                  onChange={(e) =>
                    handleVehicleChange(index, 'driverPhoneNumber', e.target.value)
                  }
                />
              </div>

              <div className="d-flex flex-column mt-7 fv-row col-md-6">
                <label className="d-flex align-items-center fs-6 fwt-bold form-label mb-2">
                  <span className="required">Vehicle Number Plate</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    title="Vehicle number plate"
                  ></i>
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  className="form-control form-control-solid"
                  placeholder=""
                  name="vehicleNumberPlate"
                  value={vehicle.vehicleNumberPlate}
                  onChange={(e) =>
                    handleVehicleChange(index, 'vehicleNumberPlate', e.target.value)
                  }
                />
              </div>
            </div>
            {(vehicles.length > 1 && index + 1 !== vehicles.length) && <hr />}
          </div>
        </div>
      ))}

      {/* Add Another Vehicle Button */}
      <div className="mt-3 text-center">
        <button onClick={handleAddAnotherVehicle} className="btn btn-light">
          Add Another Vehicle
        </button>
      </div>
    </div>
    </div>
    <div class="card-input-area">
      <div class="fv-row col-md-12">
        <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">Description/Comment</label>
        <textarea name="assetDescription" rows="5" cols="30" value={dispatchComment} class="form-control form-control-solid" onChange={(evt) => setDispatchComment(evt.target.value)}>
        </textarea>
      </div>
    </div>
    <div class="card-next-aera">
      <div style={{maxHeight: '300px', overflow: 'auto', minHeight: '300px'}}>
      <span style={{fontSize: '14px', fontWeight: 600, display: 'block', marginBottom: '15px'}}>Assigned crew members</span>
      {crew.length > 0 ? crew.map((user) => <div class="crew-selection d-flex justify-content-between mt-2">
          <div class="d-flex">
              <div class="item-initial" style={{width: '40px', height: '40px'}}><span style={{fontSize: '25px'}}>{user.profile && `${user.profile.first_name[0]}`}</span></div>
              <div class="d-flex flex-column" style={{justifyContent: 'center', maxWidth: '75%', textAlign: 'justify'}}>
                  <h5 class="mb-1" style={{textTransform: 'capitalize'}}>{user.profile && `${user.profile.first_name} ${user.profile.last_name}`}</h5>
              </div>
          </div>
          <div style={{position: 'relative', display: 'flex', alignItems: 'center'}}>
              {user.is_team_leader && <div class="crew-action-icon" title={user.profile && `${user.profile.first_name} is a team leader`}><span class="bi bi-star-fill fs-3 cancel-icon"></span></div>}
              {user.is_senior_team_leader && <div class="crew-action-icon"><span class="bi bi-shield-fill fs-5 cancel-icon" title={user.profile && `${user.profile.first_name} is a senior team leader`}></span></div>}
          </div>
          </div>) : <div className='text-center'><p>Crew members have not been configured.</p></div>}
        </div>
    <div class="d-flex justify-content-end">
      <button type="button" onClick={handleDispatch} className="btn btn-sm btn-beysix me-3" disabled={submitLoading ? true : false}>
        {!submitLoading ? <span className="indicator-label">Dispatch
        {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
        <span className="svg-icon svg-icon-3 ms-2 me-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect>
                <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path>
            </svg>
        </span>
        {/*end::Svg Icon*/}</span> : 
        <span className="indicator-progress">Please wait...
        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
    </button>
      </div>
    </div>
  </div>
</div> 
{/* : <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
  <div class="alert alert-dismissible d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10" style={{
            width: '50vw',
            borderRadius: '10px',
            background: '#ffe1ea'
        }}>
        <span class="svg-icon svg-icon-5tx svg-icon-danger mb-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"></rect>
                <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"></rect>
                <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"></rect>
            </svg>
        </span>
        <div class="text-center text-dark">
            <h1 class="fw-bolder mb-4">Operation Denied</h1>
            <div class="separator separator-dashed border-danger opacity-25 mb-4"></div>
            <div class="mb-9" style={{fontSize: '15px'}}>You are seeing this page because {dispatchData.activation_data.name} has already been dispatched.
            <br/> If you believe this is an error, please contact your administrator or try creating another checklist for this activation in order to dispatch.
            </div>
            <div class="d-flex flex-center flex-wrap">
                <Link to={`/activation-management/detailed-activation/${id}`} class="btn btn-danger m-2">Take Me Back</Link>
            </div>
        </div>
    </div>
</div> } */}
</>
  )
}
