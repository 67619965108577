import * as Sentry from "@sentry/react";
import React from "react";
import { Provider } from "react-redux";
import {store, persistor} from './store/index'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoutes from "./routes/PublicRoutes";
import RedirectHandler from "./components/RedirectHandler/RedirectHandler";
import { PersistGate } from 'redux-persist/integration/react';
import publicRoutes from "./routes/pubRoutes";
import privateRoutes from "./routes/priRoutes";

const App = () => (
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
          <RedirectHandler />
          <PublicRoutes routes={publicRoutes}/>
          <PrivateRoutes routes={privateRoutes} />
      </Router>
    </PersistGate>
    </Provider>
  </Sentry.ErrorBoundary>
);

export default App;