import React, {useEffect, useState} from 'react'
import AssetsTable from '../Assets/AssetsTable/AssetsTable'
import { useParams } from 'react-router'
import api from '../../store/api'
import { useSelector } from 'react-redux'

export default function ClientAssets() {

  const [assets, setAssets] = useState([])
  const { id } = useParams()
  const token = useSelector((state) => state.loginSlice.token);
  const [loading, setLoading] = useState(true)
  const [resultsFound, setResultsFound] = useState(false)
	
  useState(() => {
    api.get(`/api/assets/client-assets/${id}`,
    {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
    .then((response) => {
        setAssets(response.data)
        setLoading(false)
        if (response.data.length > 0) {
            setResultsFound(true)
        } else {
            setResultsFound(false)
        }
    })
    .catch((error) => {
        setLoading(false)
        setResultsFound(false)
        throw new Error(error) 
    })
  }, [id])

  return (
    <div className='card'>
        <AssetsTable assetData={assets} loading={loading} resultsFound = {resultsFound} searchQuery={''} />
    </div>
  )
}
