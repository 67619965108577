export default function loadRootScripts() {
    const script_paths = [
      '/static/assets/js/components/util.js',
      '/static/assets/js/components/blockui.js',  
      '/static/assets/js/components/event-handler.js',  
      '/static/assets/js/components/password-meter.js',  
      '/static/assets/js/components/stepper.js',
      '/static/assets/js/components/cookie.js',   
      '/static/assets/js/components/feedback.js',       
      '/static/assets/js/components/scroll.js',          
      '/static/assets/js/components/sticky.js',
      '/static/assets/js/components/dialer.js',   
      '/static/assets/js/components/image-input.js',    
      '/static/assets/js/components/scrolltop.js',       
      '/static/assets/js/components/swapper.js',
      '/static/assets/js/components/drawer.js',   
      '/static/assets/js/components/menu.js',           
      '/static/assets/js/components/search.js',          
      '/static/assets/js/components/toggle.js',
      '/static/assets/js/components/event-handler.js',
    //   '/static/assets/js/layout/app.js',
      '/static/assets/plugins/global/plugins.bundle.js',
    //   '/static/assets/js/scripts.bundle.js',
      '/static/assets/js/custom/widgets.js',
      // '/static/assets/js/custom/apps/chat/chat.js',
      // '/static/assets/js/custom/modals/create-app.js',
      // '/static/assets/js/custom/modals/upgrade-plan.js'

      //More Stuff Coming In... (Custom)
      // '/static/assets/js/custom/intro.js',
      // '/static/assets/js/custom/landing.js',
      // '/static/assets/js/custom/widgets.js',
      

      //More Stuff Coming In... (Layout)
    //   '/static/assets/js/layout/aside.js',
    //   '/static/assets/js/layout/explore.js',
    //   '/static/assets/js/layout/toolbar.js',
      
      // '/static/assets/js/layout/search.js',
    ]

    const scripts_arr = []

    for (let i = 0; i < script_paths.length; i++) {
      const script = document.createElement('script');
      script.src = script_paths[i];
      script.defer = true;
      document.body.appendChild(script);
      scripts_arr.push(script)
    }    

    return scripts_arr
  };