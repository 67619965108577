import React, {useState, useEffect} from 'react'
import api from '../../../store/api'
import { useNavigate, useParams } from 'react-router'

export default function AccountDeactivation() {

  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    api.get(`/api/users/account-deactivation/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
    .then((response) => {
    })
    .catch((error) => {
      if (error.response.status === 404) {
        navigate(error.response.data.url)
      }
    })
  }, [])

  return (
    <div className="pt-lg-10 mb-10 text-center">
        {/*begin::Logo*/}
        <h1 className="fwt-bolder fs-3 text-gray-800 mb-7">Your account is deactivated</h1>
        {/*end::Logo*/}
        {/*begin::Message*/}
        <div className="fwt-bold fs-5 text-muted mb-15">Please contact an administrator to understand why your account was deactivated.</div>
        {/*end::Message*/}
        {/*begin::Action*/}
        <div className="text-center">
            <a href="/auth/login" className="btn btn-lg btn-beysix fwt-bolder">Go to login</a>
        </div>
        <div  style={{marginTop: "70px",
            backgroundImage: "url('/static/assets/media/illustrations/beysix-1/lock.svg')",
            width: "70vw",
            height: "40vh",
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
        }}></div>
        {/*end::Action*/}
    </div>
  )
}
