import { getSuggestedQuery } from '@testing-library/react'
import React, {useState, useEffect, useRef} from 'react'

export default function FeedbackModal(props) {

    const [show, setShow] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [message, setMessage] = useState('');
    const modalRef = useRef(null);

    useEffect(() => {
        setSeverity(props.severity);
        setMessage(props.message);
        setShow(props.show);
        
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleHide();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, [props.show, props.severity, props.message]);

    const handleShow = () => {
        setShow(true)
    }

    const handleHide = () => {
        setShow(false)
        if (props.onClose)
            props.onClose()
    }

  return (
    <div>
    {(show) &&
    <div className= "swal2-container swal2-center swal2-backdrop-show">
    <div aria-labelledby="swal2-title" aria-describedby="swal2-html-container" className="swal2-popup swal2-modal swal2-icon-error swal2-show" tabIndex="-1" role="dialog" aria-live="assertive" aria-modal="true" style={show ? {display: "flex", flexDirection: "column"} : {display: "none"}} ref={modalRef}>
        {severity === 'error' ? 
        <div className={"swal2-icon swal2-error swal2-icon-show"} style={{display: "flex"}}><span className="swal2-x-mark">
        <span className="swal2-x-mark-line-left"></span>
        <span className="swal2-x-mark-line-right"></span>
      </span>
    </div> :
    <div className="swal2-icon swal2-success swal2-icon-show" style={{ display: "flex" }}>
        <span className="swal2-success-circular-line-left"></span>
        <span className="swal2-success-line-tip"></span>
        <span className="swal2-success-line-long"></span>
        <span className="swal2-success-ring"></span>
        <span className="swal2-success-fix"></span>
    </div> }
    <div className="swal2-success-circular-line-left"></div>      <span className="swal2-success-line-tip"></span> <span className="swal2-success-line-long"></span>      <div className="swal2-success-ring"></div> <div className="swal2-success-fix"></div>      <div className="swal2-success-circular-line-right"></div>
    <div className="swal2-success-circular-line-left"></div>      <span className="swal2-success-line-tip"></span> <span className="swal2-success-line-long"></span>      <div className="swal2-success-ring"></div> <div className="swal2-success-fix"></div>      <div className="swal2-success-circular-line-right"></div>  
    <div className="swal2-html-container" id="swal2-html-container" style={{display: "block"}}>{message}</div>
    <div className="swal2-actions" style={{display: "flex"}}>
        <div className="swal2-loader"></div>
        <button type="button" onClick={handleHide} className="swal2-confirm btn btn-light" aria-label="" style={{display: "inline-block"}}>{ severity === 'error' ? "Ok, got it!" : "Proceed"}</button>
    </div>
    </div>
    </div>}
    </div>
  )
}
