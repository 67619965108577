import React, {useEffect, useState} from 'react'
import api from '../../../store/api';
import { useParams, Link } from 'react-router-dom'
import {useSelector} from 'react-redux'
import getRandomHexColor from '../../Utilities/getRandomHexColor';
import UpdateRole from '../Roles/UpdateRole';
import FeedbackModal from '../../Utilities/FeedbackModal';
import MainModal from '../../Modals/MainModal';
import parseTimestamp from '../../Utilities/parseTimeStamp';
import AssertiveModal from '../../Utilities/AssertiveModal';

export default function UserRoles() {
    const [role, setRole] = useState({
        id : 0,
        name: "",
        permissions: [],
        user_count: 0,
        user_set: []
    })
    const [groupedPermissions, setGroupedPermissions] = useState({})
    const { id } = useParams()
    const token = useSelector((state) => state.loginSlice.token);
	const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
    const [res, setRes] = useState({
		message: "",
		severity: "success",
		show: false
	})
	
	const [showAssertiveModal, setShowAssertiveModal] = useState({
		show: false,
		promptText: '',
		btnPrimary: {show: false, text: ''},
		btnSecondary: {show: false, text: ''}
	})
	
	
    const getUserRoles = async () => {
        const response = await api.get(`/api/users/get-role/${id}/`, 
            {
                headers: {
					'Content-Type': 'application/json',
					 Authorization: `Token ${token}`,
				},
            }
        );
		if (response.status === 200) {
			const role = response.data.role;
			setRole(role);
			setOriginalUsers(role.user_set)
		} else {
			setRes({
				message: "There was an error fetching the role's (permissions).",
				severity: "error",
				show: true
			})
		}
    }

	const [currentUser, setCurrentUser] = useState(0)
	
	const handleRemoveUserFromRoleAssertiveModal = (evt) => {
		evt.preventDefault()
		const target = evt.target
		const id = target.getAttribute('id');
		setCurrentUser(id)
		setShowAssertiveModal({
			show: true,
			promptText: `Are you sure you want to remove the user from this role?`,
			btnPrimary: {show: true, text: `Yes, remove user from role`},
			btnSecondary: {show: true, text: 'No, Take me back'}
		})
	}

    const getGroupedPermissions = async () => {
        const response = await api.get('/api/users/get-grouped-permissions/',
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Token ${token}`,
				},
			});
        setGroupedPermissions(response.data['grouped_permissions']) 
    }

    useEffect(() => {
        getUserRoles()
        getGroupedPermissions()
    }, [])

	const handleRemoveUserFromRole = (status) => {
		if (status) {
			api.post(`/api/users/remove-user-from-role/${parseInt(id)}/`, 
			{
				'group': id, 
				'user': currentUser
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Token ${token}`,
				},
			})
			.then((response) => {
				if (response.status === 200) {
					setRes({
						message: response.data.message,
						severity: "success",
						show: true
					})
					setShowAssertiveModal({
						show: false,
						promptText: '',
						btnPrimary: {show: false, text: ''},
						btnSecondary: {show: false, text: ''}
					})
					getUserRoles()
        			getGroupedPermissions()
				}
			})
			.catch((error) => {
				setRes({
					message: error.response.data.error,
					severity: "error",
					show: true
				})
				setShowAssertiveModal({
					show: false,
					promptText: '',
					btnPrimary: {show: false, text: ''},
					btnSecondary: {show: false, text: ''}
				})
			})
		} else {
			setShowAssertiveModal({
				show: false,
				promptText: '',
				btnPrimary: {show: false, text: ''},
				btnSecondary: {show: false, text: ''}
			})
		}
	}

	const [originalUsers, setOriginalUsers] = useState([])
	const [searchData, setSearchData] = useState({isSearching: false, resultsFound: false})
	const handleUserSearch = (evt) => {
		const value = evt.target.value.toLowerCase();
		const users = role.user_set
		const searchUser = []
		setSearchData({...searchData, isSearching: true})
		if (value.length >= 1) {
			for (let i = 0; i < users.length; i++) {
				const fullName = users[i].profile.first_name.toLowerCase() + ' ' + users[i].profile.last_name.toLowerCase()
				const fullNameReversed = users[i].profile.last_name.toLowerCase() + ' ' + users[i].profile.first_name.toLowerCase()
				const emailCheck = users[i].email.toLowerCase().includes(value)
				const firstNameCheck = users[i].profile.first_name.toLowerCase().includes(value)
				const lastNameCheck = users[i].profile.last_name.toLowerCase().includes(value)
				let phoneNoCheck
				if (phoneNoCheck){
					phoneNoCheck = users[i].profile.phone_number.toLowerCase().includes(value)
				} else {
					phoneNoCheck = false
				}
				const fullNameCheck = fullName.includes(value)
				const fullNameReversedCheck = fullNameReversed.includes(value)
				if ( emailCheck || firstNameCheck || lastNameCheck || phoneNoCheck || fullNameCheck || fullNameReversedCheck) {
					searchUser.push(users[i])
					setRole({...role, user_set: searchUser})
					setSearchData({...searchData, resultsFound: true})
				} else {
					const searchDataLength = searchUser.length === 0
					const length = i === users.length - 1
					if (searchDataLength && length){
						setRole({...role, user_set: []})
						setSearchData({...searchData, resultsFound: false})
					}
				}	
			}
		} else {
			setRole({...role, user_set: originalUsers})
			setSearchData({isSearching: false, resultsFound: false})
		}
	}

  return (
	<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
		<AssertiveModal
			show = {showAssertiveModal.show}
			promptText = {showAssertiveModal.promptText}
			btnPrimary = {{show: showAssertiveModal.btnPrimary.show, text: showAssertiveModal.btnPrimary.text}}
			btnSecondary = {{show: showAssertiveModal.btnSecondary.show, text: showAssertiveModal.btnSecondary.text}}
			handleActionBtnClick = {handleRemoveUserFromRole}
		/>
		<FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
			setRes({
				message: "",
				severity: "success",
				show: false
			})
		}} />
		{/*begin::Post*/}
		<div className="post d-flex flex-column-fluid" id="kt_post">
			{/*begin::Container*/}
			<div id="kt_content_container" className="container-xxl">
				{/*begin::Layout*/}
				<div className="d-flex flex-column flex-xl-row">
					{/*begin::Sidebar*/}
					<div className="flex-column flex-lg-row-auto w-lg-300px mb-10">
						{/*begin::Card*/}
						<div className="card card-flush">
							{/*begin::Card header*/}
							<div className="card-header">
								{/*begin::Card title*/}
								<div className="card-title">
									<h2 className="mb-0">{role.name}</h2>
								</div>
								{/*end::Card title*/}
							</div>
							{/*end::Card header*/}
							{/*begin::Card body*/}
							<div className="card-body pt-0" style={{overflow: "auto", maxHeight: "450px", marginBottom: "20px"}}>
								{/*begin::Permissions*/}
								<div className="d-flex flex-column text-gray-600">
								{role.permissions.map((Permission) => {
									return (<div className="d-flex align-items-center py-2">
											<span className="bullet bg-beysix me-3"></span>{Permission.name}</div>)
									})
								}
								</div>
								{/*end::Permissions*/}
							</div>
							{/*end::Card body*/}
							{/*begin::Card footer*/}
							<div className="card-footer pt-0">
								<button type="button" className="btn btn-light btn-active-primary" onClick={() => setIsUpdateModalOpen(true)}>Edit Role</button>
							</div>
							{/*end::Card footer*/}
						</div>
						{/*end::Card*/}
						</div>
						<MainModal handleClose={() => setIsUpdateModalOpen(false)} show={isUpdateModalOpen} modalTitle={"Update Role"}>
						{ role !== null ? 
							<UpdateRole role={role} 
								handleClose={() => setIsUpdateModalOpen(false)} 
								isOpen={isUpdateModalOpen} 
								role_id = {role.id} 
								groupedPermissions={groupedPermissions}
								handleSuccess={(res) => setRes(res)}
							/> 
						: null }
					</MainModal>
					{/*end::Sidebar*/}
					{/*begin::Content*/}
					<div className="flex-lg-row-fluid ms-lg-10">
						{/*begin::Card*/}
						<div className="card card-flush mb-6 mb-xl-9">
							{/*begin::Card header*/}
							<div className="card-header pt-5">
								{/*begin::Card title*/}
								<div className="card-title">
									<h3 className="d-flex align-items-center">Users Assigned
									<span className="text-gray-600 fs-6 ms-1">({role.user_count})</span></h3>
								</div>
								{/*end::Card title*/}
								{/*begin::Card toolbar*/}
								<div className="card-toolbar">
									{/*begin::Search*/}
									<div className="d-flex align-items-center position-relative my-1" data-kt-view-roles-table-toolbar="base">
										{/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}
										<span className="svg-icon svg-icon-1 position-absolute ms-6">
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
												<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
												<path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
											</svg>
										</span>
										{/*end::Svg Icon*/}
										<input type="text" data-kt-roles-table-filter="search" onChange={handleUserSearch} className="form-control form-control-solid w-250px ps-15" placeholder="Search Users" />
									</div>
									{/*end::Search*/}
									{/*begin::Group actions*/}
									<div className="d-flex justify-content-end align-items-center d-none" data-kt-view-roles-table-toolbar="selected">
										<div className="fw-bolder me-5">
										<span className="me-2" data-kt-view-roles-table-select="selected_count"></span>Selected</div>
										<button type="button" className="btn btn-danger" data-kt-view-roles-table-select="delete_selected">Delete Selected</button>
									</div>
									{/*end::Group actions*/}
								</div>
								{/*end::Card toolbar*/}
							</div>
							{/*end::Card header*/}
							{/*begin::Card body*/}
							<div className="card-body pt-0" style={{overflow: "auto", maxHeight: "450px", marginBottom: "20px"}}>
								{searchData.isSearching && !searchData.resultsFound ?
								 <div className='text-center mt-3 mb-3'>
									<p>No results have been found.</p>
								</div> :
								<table className="align-middle table-row-dashed fs-6 gy-5 mb-0" id="kt_roles_view_table">
									{/*begin::Table head*/}
									<thead>
										{/*begin::Table row*/}
										<tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
											{/* <th className="w-10px pe-2">
												<div className="form-check form-check-sm form-check-custom form-check-solid me-3">
													<input className="form-check-input" type="checkbox" data-kt-check="true" data-kt-check-target="#kt_roles_view_table .form-check-input" value="1" />
												</div>
											</th> */}
											{/* <th className="min-w-50px">ID</th> */}
											<th className="min-w-150px">User</th>
											<th className="min-w-125px">Joined Date</th>
											<th className="text-end min-w-100px">Action</th>
										</tr>
										{/*end::Table row*/}
									</thead>
									{/*end::Table head*/}
									{/*begin::Table body*/}
									<tbody className="fwt-bold text-gray-600">
										{role.user_set.map((User) => {
										const {randomColor, darkerShade, brighterShade} = getRandomHexColor()
										return (<tr>
											{/*begin::Checkbox*/}
											{/* <td>
												<div className="form-check form-check-sm form-check-custom form-check-solid">
													<input className="form-check-input" type="checkbox" value="1" />
												</div>
											</td> */}
											{/*end::Checkbox*/}
											{/*begin::ID*/}
											{/* <td>ID6847</td> */}
											{/*begin::ID*/}
											{/*begin::User=*/}
											<td className="d-flex align-items-center">
												{/*begin:: Avatar */}
												<div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
													<Link to={`/user-management/users/user-profile/${User.id}`}>
														{User.profile.profile_photo !== null && User.profile.profile_photo !== '' ? 
														User.profile.profile_photo_path ? <div className="symbol-label">
															<img src={User.profile.profile_photo_path} alt={User.profile.first_name.concat(' ' + User.profile.last_name)} className="w-100" />
														</div> :
														<div className="symbol-label fs-5" style={{ background: brighterShade, color: darkerShade }}>{User.profile.first_name[0]}{User.profile.last_name[0]}</div>
														:
														<div className="symbol-label fs-5" style = {{background: brighterShade, color: darkerShade}}>{User.profile.first_name[0]}{User.profile.last_name[0]}</div>
														}
													</Link>
												</div>
												{/*end::Avatar*/}
												{/*begin::User details*/}
												<div className="d-flex flex-column">
													<Link to={`/user-management/users/user-profile/${User.id}`} className="text-gray-800 text-hover-primary mb-1">{User.profile.first_name} {User.profile.last_name}</Link>
													<span>{User.email}</span>
												</div>
												{/*begin::User details*/}
											</td>
											{/*end::user=*/}
											{/*begin::Joined date=*/}
											<td>{parseTimestamp(User.profile.created_at)}</td>
											{/*end::Joined date=*/}
											{/*begin::Action=*/}
											<td className="text-end">
												<a onClick={handleRemoveUserFromRoleAssertiveModal} id={User.id} className="btn btn-sm btn-light btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Remove {User.profile.first_name} from this role</a>
											</td>
											{/*end::Action=*/}
										</tr>)
										})}                                                    
									</tbody>
									{/*end::Table body*/}
								</table>}
								{/*end::Table*/}
							</div>
							{/*end::Card body*/}
						</div>
						{/*end::Card*/}
					</div>
					{/*end::Content*/}
				</div>
				{/*end::Layout*/}
			</div>
			{/*end::Container*/}
		</div>
		{/*end::Post*/}
	</div>
  )
}
