import React, {useState, useEffect} from 'react'
import api from '../../store/api'
import {useSelector} from 'react-redux'
import passwordStrengthValidator from '../Utilities/passwordStrengthValidator'

export default function UpdateUserPasswordModalBody({user_id, handleErrors, handleSuccess}) {
    
    const [validated, setValidated] = useState(false)
    const [loading, setLoading] = useState(false)
    const [currentPassword, setCurrentPassword] = useState('')
    const [passwordInputs, setPasswordInputs] = useState({
        password: '',
        confirmPassword: ''
    })
    const [passwordError, setPasswordError] = useState('')
    const [showPassword, setShowPassword] = useState(false);

    const token = useSelector((state) => state.loginSlice.token);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const ValidateCurrentPassword = () => {
        setLoading(true)
        api.post(`/api/users/password-checker/`, {
            'password': currentPassword,
            'id': user_id
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
        })
        .then((response) => {
            if (response.status === 200) {
               const res = response.data.validated
               setValidated(res)
               setLoading(false)
            }
        })
        .catch((error) => {
            handleErrors(error.response.data.error)
            setLoading(false)
        })
    }

    const handleUpdatePassword = () => {
        setLoading(true)
        api.put(`/api/users/update-account-password/`, {
            'password': passwordInputs.password,
            'current_password': currentPassword,
            'user_id': user_id
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
        })
        .then((response) => {
            if (response.status === 200) {
                setValidated(false)
                setCurrentPassword('')
                setPasswordInputs({
                    password: '',
                    confirmPassword: ''
                })
                handleSuccess(response.data.message)
               setLoading(false)
            }
        })
        .catch((error) => {
            handleErrors(error.response.data.error)
            setLoading(false)
        })
    }

    const handleSubmit = (evt) => {
        evt.preventDefault()
        if (!validated) {
            ValidateCurrentPassword()
        } else {
            passwordInputs.password !== passwordInputs.confirmPassword ? 
                setPasswordError('Passwords do not match.') : passwordInputs.password === currentPassword ?
                setPasswordError('This password matches your old password.') :
                !passwordStrengthValidator(passwordInputs.password) ? setPasswordError(feedback) : handleUpdatePassword()
        }
    }

    const handlePasswordChange = (evt) => {
        const { name, value } = evt.target;
        setPasswordInputs({...passwordInputs, [name]: value});
        if (evt.target.name === 'password') {
            const {feedback, strong} =  passwordStrengthValidator(evt.target.value)
            !strong ? setPasswordError(feedback) : setPasswordError('')
        }
    }

    const handleCurrentPasswordChange = (evt) => {
        setCurrentPassword(evt.target.value)
    }
  
    return (
        <form id="kt_modal_update_password_form" className="form" onSubmit={handleSubmit}>
        {!validated ? <div className="fv-row mb-10">
            <label className="required form-label fs-6 mb-2">Current Password</label>
            <input className="form-control form-control-lg form-control-solid" type="password" placeholder="" name="current_password" autocomplete="off" value = {currentPassword} onChange={handleCurrentPasswordChange}/>
        </div> : null }
        {validated ? 
        <div> <div className="mb-10 fv-row" data-kt-password-meter="true">
            <div className="mb-1">
                <label className="form-label fwt-bold fs-6 mb-2">New Password</label>
                <div className="position-relative mb-3">
                    <input className="form-control form-control-lg form-control-solid" type={showPassword ? 'text' : 'password'} placeholder="" name="password" autocomplete="off" value = {passwordInputs.password} onChange={handlePasswordChange} />
                    <span onClick={togglePasswordVisibility} className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                            {!showPassword ? <i className="bi bi-eye-slash fs-4"></i> : <i className="bi bi-eye fs-4"></i>}
                    </span>
                </div>
                <div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
                    <div className="flex-grow-1 bg-secondary2 bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-secondary2 bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-secondary2 bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-secondary2 bg-active-success rounded h-5px"></div>
                    </div>
            </div>
            {passwordError === '' ? <div className="text-muted">Use 8 or more characters with a mix of letters, numbers &amp; symbols.</div>: 
            <div className="fv-plugins-message-container invalid-feedback"><div data-field="email" data-validator="emailAddress">{passwordError}</div></div>}
            </div>
                <div className="fv-row mb-10">
                    <label className="form-label fwt-bold fs-6 mb-2">Confirm New Password</label>
                    <input className="form-control form-control-lg form-control-solid" type="password" placeholder="" name="confirmPassword" autocomplete="off" value = {passwordInputs.confirmPassword} onChange={handlePasswordChange} />
                </div>
            </div> 
                : null }
        <div className="text-center pt-15">
            <button type="reset" className="btn btn-light me-3" data-kt-users-modal-action="cancel">Discard</button>
            <button type="submit" className="btn btn-beysix" data-kt-users-modal-action="submit">
                {!loading ? <span className="indicator-label">Submit</span> :
                    <span className="indicator-progress">Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                }
            </button>
        </div>
    </form>
  )
}
