import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router';
import api from '../../../store/api';
import { motion } from 'framer-motion';
import ItemSerialTable from '../ItemSerialTable/ItemSerialTable';

export default function IndividualItemsTable({firstImage = null}) {
    
    const token = useSelector((state) => state.loginSlice.token);
    const [items, setItems] = useState([])
    const [count, setCount] = useState(0)
    const [pageLoading, setPageLoading] = useState(false)
    const [resultsFound, setResultsFound] = useState(true)
    const { id } = useParams()
    const getItems = () => {
        setPageLoading(true)
        api.get(`/api/items/get-item-serials/${id}/`,  {headers: 
                {'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
        })
    
        .then((response) => {
            setPageLoading(false)
            if (response.data.results.length === 0)
                setResultsFound = 0
            setItems(response.data.results)
            setCount(response.data.count)
        })
    
        .catch((error) => {
            setPageLoading(false)
            throw new Error(error.response.data.error)
        })
    }

    useEffect(() => {
        getItems()
    }, [])

  return (
    <>
    <motion.p 
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.3 }} style={{fontWeight: '600'}}>{count} Individual Items Total</motion.p>
    <div className='card mb-5 mb-xl-8'>
    <ItemSerialTable items={items} firstImage={firstImage} loading={pageLoading} resultsFound={resultsFound}/>
    </div>
    </>
  )
}
