import React, {useRef, useEffect} from 'react'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { resetLoginState, setRedirectPath } from '../../store/loginSlice';
import api from '../../store/api';

export default function UserMenu({open, handleClose}) {

    const subMenu = useRef(null)
    const user  = useSelector((state) => state.loginSlice.user);
    const profile  = useSelector((state) => state.loginSlice.userProfile);
    const menuClass = "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fwt-bold py-4 fs-6 w-275px"
    const menuClassOpen = "show menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fwt-bold py-4 fs-6 w-275px"
    const handleOpenSubmenu = () => {
       subMenu.current.style.transform = `translate3d(-10px, 65px, 0px)`
    }

    const dispatch = useDispatch();
	const token = useSelector((state) => state.loginSlice.token);
	const permissions = useSelector((state) => state.loginSlice.permissions);
	const handleLogout = async () => {
		try {
			const response = await api.post(
			'/api/users/logout/',
			{},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Token ${token}`,
				},
			}
		);
		
		if (response.status === 200) {
			dispatch(resetLoginState());
			dispatch(setRedirectPath('/auth/login/'))
		} else {
			console.error('Logout failed');
		}
		} catch (error) {
			console.error('Logout failed:', error.message);
		}
	};

    useEffect(() => {
        handleOpenSubmenu();
        
        const handleClickOutside = (evt) => {
            if (open) {
                const sub = subMenu.current;
                const target = evt.target;
                const userBtn = document.getElementById('trigger_open_user_submenu')
                if (!sub.contains(target) && !userBtn.contains(target)) {
                    handleClose();
                } 
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [open]);

    return (
        <div className={open ? menuClassOpen : menuClass} ref={subMenu} data-popper-placement="bottom-end" style = {{
            zIindex: "105", 
            position: "fixed", 
            inset: "0px 0px auto auto", 
            margin: "0px"
        }}>
        {/*begin::Menu item*/}
        <div className="menu-item px-3">
            <div className="menu-content d-flex align-items-center px-3">
                {/*begin::Avatar*/}
                <div className="symbol symbol-60px me-3 symbol-circle">
                    {profile.profile_photo_path !== '' && profile.profile_photo_path ?
                        <img alt={`${profile.first_name}'s Profile Picture`} src={profile.profile_photo_path} style={{objectFit: 'cover'}}/>
                    : 
                    <span className="svg-icon svg-icon-muted svg-icon-4hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z" fill="black"/>
                        <path d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z" fill="black"/>
                    </svg></span>}
                </div>
                {/*end::Avatar*/}
                {/*begin::Username*/}
                <div className="d-flex flex-column">
                    <div className="fwt-bolder d-flex align-items-center fs-6">{profile.first_name} {profile.last_name}
                    <span className="badge badge-light-success fwt-bolder fs-8 px-2 py-1 ms-2" style = {{textTransform: 'capitalize'}}>{user.user_type}</span></div>
                    <a  className="fwt-bold text-muted text-hover-primary fs-7">{user.email}</a>
                </div>
                {/*end::Username*/}
            </div>
        </div>
        {/*end::Menu item*/}
        {/*begin::Menu separator*/}
        <div className="separator my-2"></div>
        {/*end::Menu separator*/}
        {/*begin::Menu item*/}
        <div className="menu-item ">
            <Link to={`/user-management/users/user-profile/${user.id}`} className="menu-link ">My Profile</Link>
        </div>
        {/*end::Menu item*/}
        {/*begin::Menu item*/}
        {/* <div className="menu-item ">
            <a href="../../demo1/dist/pages/projects/list.html" className="menu-link ">
                <span className="menu-text">My Projects</span>
                <span className="menu-badge">
                    <span className="badge badge-light-danger badge-circle fwt-bolder fs-7">3</span>
                </span>
            </a>
        </div> */}
        {/*end::Menu item*/}
        {/*begin::Menu item*/}
        {/* <div className="menu-item " data-kt-menu-trigger="hover" data-kt-menu-placement="left-start">
            <a  className="menu-link ">
                <span className="menu-title">My Subscription</span>
                <span className="menu-arrow"></span>
            </a>
            <div className="menu-sub menu-sub-dropdown w-175px py-4">
                <div className="menu-item px-3">
                    <a href="../../demo1/dist/account/referrals.html" className="menu-link ">Referrals</a>
                </div>
                <div className="menu-item px-3">
                    <a href="../../demo1/dist/account/billing.html" className="menu-link ">Billing</a>
                </div>
                <div className="menu-item px-3">
                    <a href="../../demo1/dist/account/statements.html" className="menu-link ">Payments</a>
                </div><div className="menu-item px-3">
                    <a href="../../demo1/dist/account/statements.html" className="menu-link d-flex flex-stack ">Statements
                    <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="View your statements" aria-label="View your statements"></i></a>
                </div>
                <div className="separator my-2"></div>
                <div className="menu-item px-3">
                    <div className="menu-content px-3">
                        <label className="form-check form-switch form-check-custom form-check-solid">
                            <input className="form-check-input w-30px h-20px" type="checkbox" value="1" checked="checked" name="notifications" />
                            <span className="form-check-label text-muted fs-7">Notifications</span>
                        </label>
                    </div>
                </div>
            </div>
        </div> */}
        {/*end::Menu item*/}
        {/*begin::Menu item*/}
        {/* <div className="menu-item ">
            <a href="../../demo1/dist/account/statements.html" className="menu-link ">My Statements</a>
        </div> */}
        {/*end::Menu item*/}
        {/*begin::Menu separator*/}
        <div className="separator my-2"></div>
        {/*end::Menu separator*/}
        {/*begin::Menu item*/}
        {/* <div className="menu-item " data-kt-menu-trigger="hover" data-kt-menu-placement="left-start">
            <a  className="menu-link ">
                <span className="menu-title position-relative">Language
                <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">English
                <img className="w-15px h-15px rounded-1 ms-2" src="/static/assets/media/flags/united-states.svg" alt="" /></span></span>
            </a>
            <div className="menu-sub menu-sub-dropdown w-175px py-4">
                <div className="menu-item px-3">
                    <a href="../../demo1/dist/account/settings.html" className="menu-link d-flex  active">
                    <span className="symbol symbol-20px me-4">
                        <img className="rounded-1" src="/static/assets/media/flags/united-states.svg" alt="" />
                    </span>English</a>
                </div>
                <div className="menu-item px-3">
                    <a href="../../demo1/dist/account/settings.html" className="menu-link d-flex ">
                    <span className="symbol symbol-20px me-4">
                        <img className="rounded-1" src="/static/assets/media/flags/spain.svg" alt="" />
                    </span>Spanish</a>
                </div>
                <div className="menu-item px-3">
                    <a href="../../demo1/dist/account/settings.html" className="menu-link d-flex ">
                    <span className="symbol symbol-20px me-4">
                        <img className="rounded-1" src="/static/assets/media/flags/germany.svg" alt="" />
                    </span>German</a>
                </div>
                <div className="menu-item px-3">
                    <a href="../../demo1/dist/account/settings.html" className="menu-link d-flex ">
                    <span className="symbol symbol-20px me-4">
                        <img className="rounded-1" src="/static/assets/media/flags/japan.svg" alt="" />
                    </span>Japanese</a>
                </div>
                <div className="menu-item px-3">
                    <a href="../../demo1/dist/account/settings.html" className="menu-link d-flex ">
                    <span className="symbol symbol-20px me-4">
                        <img className="rounded-1" src="/static/assets/media/flags/france.svg" alt="" />
                    </span>French</a>
                </div>
            </div>
        </div> */}
        {/*end::Menu item*/}
        {/*begin::Menu item*/}
        {/* <div className="menu-item  my-1">
            <a href="../../demo1/dist/account/settings.html" className="menu-link ">Account Settings</a>
        </div> */}
        {/*end::Menu item*/}
        {/*begin::Menu item*/}
        <div className="menu-item ">
            <a  onClick={handleLogout} className="menu-link ">Sign Out</a>
        </div>
        {/*end::Menu item*/}
        {/*begin::Menu separator*/}
        <div className="separator my-2"></div>
        {/*end::Menu separator*/}
        {/*begin::Menu item*/}
        {/* <div className="menu-item ">
            <div className="menu-content ">
                <label className="form-check form-switch form-check-custom form-check-solid pulse pulse-success" htmlFor="kt_user_menu_dark_mode_toggle">
                    <input className="form-check-input w-30px h-20px" type="checkbox" value="1" name="mode" id="kt_user_menu_dark_mode_toggle" data-kt-url="../../demo1/dist/index.html" />
                    <span className="pulse-ring ms-n1"></span>
                    <span className="form-check-label text-gray-600 fs-7">Dark Mode</span>
                </label>
            </div>
        </div> */}
        {/*end::Menu item*/}
    </div>
  )
}
