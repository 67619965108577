import Login from "../components/Authentication/Login/Login";
import SignUp from "../components/Authentication/SignUp/SignUp";
import SuccessAccountCreation from "../components/Authentication/SuccessAccountCreation/SuccessAccountCreation";
import VerifyEmail from "../components/Authentication/VerifyEmail/VerifyEmail";
import AccountDeactivation from "../components/Authentication/AccountDeactivation/AccountDeactivation";
import error404 from "../components/Authentication/404/error404";
import ForgotPassword from "../components/Authentication/ForgotPassword/ForgotPassword";
import ResetPassword from "../components/Authentication/ResetPassword/ResetPassword";
import ResetPasswordSuccess from "../components/Authentication/ResetPasswordSuccess/ResetPasswordSuccess";
import ForgotPasswordEmail from "../components/Authentication/ForgotPasswordEmail/ForgotPasswordEmail";
import UserVerificationAction from "../components/Authentication/UserVerificationAction/UserVerificationAction";
import InviteUserVerification from "../components/Authentication/InviteUserVerification/InviteUserVerification";
import SuccessfullAccountSetup from "../components/Authentication/SuccessfullAccountSetup/SuccessfullAccountSetup";

const publicRoutes = [
    { path: '/auth/login', component: Login },
    { path: '/auth/sign-up', component: SignUp },
    { path: '/auth/account-approval/:id', component: SuccessAccountCreation },
    { path: '/auth/verify-email/:id', component: VerifyEmail },
    { path: '/auth/account-deactivation/:id', component: AccountDeactivation },
    { path: '/auth/404-page-not-found', component: error404 },
    { path: '/auth/forgot-password', component: ForgotPassword },
    { path: '/auth/reset-password/:param1/:param2/:param3', component: ResetPassword },
    { path: '/auth/reset-password-success/:id', component: ResetPasswordSuccess },
    { path: '/auth/forgot-password-email/:id', component: ForgotPasswordEmail },
    { path: '/auth/verify-email/action/:id', component: UserVerificationAction },
    { path: '/auth/invite-email/action/:id', component: InviteUserVerification },
    { path: '/auth/successful-account-setup/:id', component: SuccessfullAccountSetup }
  ];


  export default publicRoutes
  