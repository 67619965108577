import React, {useState, useEffect} from 'react'
import AgencyTable from '../../Agency/AgencyTable/AgencyTable'
import api from '../../../store/api'
import {useSelector} from 'react-redux'
import { useParams } from 'react-router'

export default function ClientOrganizationCommAgency() {

    const token = useSelector((state) => state.loginSlice.token);
    const [agencies, setAgencies] = useState([])
    const [ogAgencies, setOgAgencies] = useState([])
    const [res, setRes] = useState({
        message: "",
        severity: "success",
        show: false
    })
    const [pageLoading, setPageLoading] = useState(false)
    const [count, setCount] = useState(0)
    const [resultsFound, setResultsFound] = useState(false)

    const { id } = useParams()

    const getCommissioningAgencies = () => {
            setPageLoading(true)
            api.get(`/api/clients/client-commissioning-agencies/${id}`, {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
            .then((response) => {
            const data = response.data.results
            const count = response.data.count
            setAgencies(data)
            setOgAgencies(data)
            setCount(count)
            setPageLoading(false)
            if (data.length > 0)
                setResultsFound(true)
            })
            .catch((error) => {
                setRes({
                    message: error.response.data.error,
                    severity: "error",
                    show: true
                })
                setPageLoading(false)
            })  
    }

    useEffect(() => {
        getCommissioningAgencies()
    }, [])
  
    return (
        <div>
            <AgencyTable agencyData={agencies}  resultsFound={resultsFound} loading={pageLoading} />
        </div>
    )
}
