import React, {useState, useEffect} from 'react'

export default function ReasonModal({handleConfirmationCloseClick, reasonShow, ReasonDetails, handleSuccess}) {

  const [reason, setReason] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [error, setError] = useState(false)
  const [reasonDetails, setReasonDetails] = useState({})  
  
  useEffect(() => {
    setReasonDetails(ReasonDetails)
  }, [ReasonDetails])

  return (
    <div className={reasonShow ? "modal-backdrop fade show": "modal-backdrop"} aria-hidden="true" style={!reasonShow ? {display: 'none'} : null}>
        <div className="swal2-container swal2-center" style = {reasonShow ? {display: "grid"} : {display: "none"}}>
            <div aria-labelledby="swal2-title" aria-describedby="swal2-html-container" style={{display: "block"}} className="swal2-popup swal2-modal swal2-icon-warning swal2-show" tabIndex="-1" role="dialog" aria-live="assertive" aria-modal="true">
            <div className="swal2-html-container" id="swal2-html-container" style={{display: "block"}}>
                {!reasonDetails ? "Are you sure you would like to close?" : reasonDetails.promptText}</div>
                <div className="align-items-center position-relative mt-3 mb-3">
                <textarea className="form-control form-control-solid ps-14" value = {reason} onChange={(evt) => 
                                                                                    {
                                                                                        setReason(evt.target.value)
                                                                                        if (evt.target.value.length === 0) {
                                                                                          setError(true); 
                                                                                          setErrorMessage('You have to provide details about why you are performing this action.')
                                                                                        } else {
                                                                                          setError(false); 
                                                                                          setErrorMessage('')
                                                                                        }
                                                                                      }}></textarea>
                {error && <div className="fv-plugins-message-container invalid-feedback"><div data-field="email" data-validator="notEmpty">{errorMessage}</div></div>}
                </div>
                <div className="swal2-actions" style={{display: "flex"}}>
                {reasonDetails ? 
                  <button type="button" close-attr = 'true' onClick={() => {
                                            if (reason.length === 0) {
                                              setError(true); 
                                              setErrorMessage('You have to provide details about why you are performing this action.')
                                            } else {
                                              setError(false); 
                                              setErrorMessage('')
                                              handleSuccess(reason);
                                              setReason('')
                                            }}} className="swal2-confirm btn btn-beysix" aria-label="" style={{display: "inlineBlock"}}>{reasonDetails.btnText}</button> : null}
                <button type="button" close-attr = 'false' onClick={handleConfirmationCloseClick} className="swal2-cancel btn btn-active-light" aria-label="" style={{display: "inlineBlock"}}>Abort This Action</button></div>
        </div>
        </div>
    </div>
  )
}


