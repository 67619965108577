import React, {useState, useEffect} from 'react'
import api from '../../store/api';
import { useSelector } from 'react-redux';


export default function RolesInputList({roles, user, loading, submit, edit, userId, handleSuccessEditOfRoles, handleErrorEditOfRoles, clients, setClient, client}) {

    const [selectedRoles, setSelectedRoles] = useState([]);
    const token = useSelector((state) => state.loginSlice.token);
	const [assignedRoles, setAssignedRoles] = useState([])
    const handleCheckboxClick = (roleId) => {
		const isSelected = selectedRoles.includes(roleId);
		if (isSelected) {
			setSelectedRoles(selectedRoles.filter(selectedRoleId => selectedRoleId !== roleId));
		} else {
			setSelectedRoles([...selectedRoles, roleId]);
		}
	};
	const [editAddedRoles, setEditAddedRoles] = useState([])
	const [editRemovedRoles, setEditRemovedRoles] = useState([])
    const handleSubmit = () => {
        submit(selectedRoles)
    }

	const handleSubmitEditRoles = () => {
		api.post(`/api/users/update-user-roles/`, {id: userId, new_roles: editAddedRoles, removed_roles: editRemovedRoles}, {
			headers: {
			  'Content-Type': 'application/json',
			  Authorization: `Token ${token}`,
			},
		})
		.then((response) => {
			handleSuccessEditOfRoles(response.data.message)
		})
		.catch((error) => {
			handleErrorEditOfRoles(error.response.data.error)
		})
	}

	const handleEditCheckboxClick = (evt) => {
		const id = parseInt(evt.target.getAttribute('id'))
		const checked = evt.target.checked;

		if (checked) {
			const editRemovedRolesArr = editRemovedRoles
			const index = editRemovedRolesArr.indexOf(id)
			if (index !== -1) 
				editRemovedRolesArr.splice(index, 1)
			setEditRemovedRoles(editRemovedRolesArr)
			setEditAddedRoles([...editAddedRoles, id])
			setAssignedRoles([...assignedRoles, id])
		} else {
			const editAddedRolesArr = editAddedRoles
			let index = editAddedRolesArr.indexOf(id)
			if (index !== -1) 
				editAddedRolesArr.splice(index, 1)
			setEditAddedRoles(editAddedRolesArr)
			setEditRemovedRoles([...editRemovedRoles, id])
			index = assignedRoles.indexOf(id)
			const _assignedRoles = assignedRoles
			if (index !== -1)
				_assignedRoles.splice(index, 1)
		}
	};

	const getUserRoles = () => {
		api.get(`/api/users/get-user-roles/${userId}`, {
			headers: {
			  'Content-Type': 'application/json',
			  Authorization: `Token ${token}`,
			},
		  })
	  
		  .then((response) => {
			if (response.status == 200) 
				setAssignedRoles(response.data.data)
		  })
	}

	useEffect(() => {
		console.log(userId)
		if (userId !== 0 && edit === true) {
			getUserRoles()
		}
	}, [edit])

  return (
    <div data-kt-stepper-element="content">
				{/*begin::Wrapper*/}
				<div className="w-100">
					{/*begin::Heading*/}
					<div className="pb-4" style={{textAlign: 'left'}}>
						<div className="text-muted fw-bold fs-6">
                            You need to assign this user a role based on what they will be required to do within the system
                        </div>
						{/*end::Notice*/}
					</div>
					{/*end::Heading*/}
					<div className="mb-7">
				{/*begin::Label*/}
				
				<div className='roles-input-list'>
				<label className="required fw-bold fs-6 mb-2">Role</label>
				{/*end::Label*/}
				{/*begin::Roles*/}
				{/*begin::Input row*/}
					{roles.length > 0 && 
					
					roles.map((role, index) => {
					return(
					<>
					<div className="d-flex fv-row" style={{justifyContent: "space-between"}} key={index}>
						{/*begin::Radio*/}
						<div className="form-check form-check-custom form-check-solid">
							{/*begin::Input*/}
							{!edit ? <input
								style={{ height: "1.8em", width: "1.8em" }}
								className="form-check-input me-3"
								name="user_role"
								type="checkbox"
								checked={selectedRoles.includes(role.id)}
								onChange={() => handleCheckboxClick(role.id)}
								/>: <input
								style={{ height: "1.8em", width: "1.8em" }}
								className="form-check-input me-3"
								name="user_role"
								type="checkbox"
								checked={assignedRoles.includes(role.id)}
								id={role.id}
								onChange={handleEditCheckboxClick}
								/>}
							{/*end::Input*/}
							{/*begin::Label*/}
							<label className="form-check-label d-flex" htmlFor="kt_modal_update_role_option_0">
								<div>
									<div className="ftw-bolder text-gray-800 fs-6">{role.name}</div>
								</div>
							</label>
							{/*end::Label*/}
						</div>
						{/*end::Radio*/}
						<div className="tool-tip" style={{textAlign: 'left'}}>
							<i className="tool-tip__icon">i</i>
							<p className="tool-tip__info right">
								{role.permissions.map((permission) =>
								 (
									<span className="info" style={{marginTop: "3px"}}><span className="info__title">x: </span>{permission.name}</span>
								 ))}
							</p>
						</div>
					</div>
					<div className="separator separator-dashed my-3"></div></>)})}

				</div>
					{user.is_client && 
				<div className="text-left fv-row fv-plugins-icon-container mt-3">
					<label className="required fs-6 fwt-bold mb-2">Assign to client</label>
					<select aria-label="Select example" id="dropdown" name="client" className='form-control form-control-solid' onChange={(evt) => setClient(evt.target.value)} value={client}>
					<option value="0">-- Select Client --</option>
					{clients.map((client) => {return(<option value={client.id}>{client.name}</option>)})}
					</select>
				</div>
				}
				</div>
                <button type="reset" disabled={loading ? true : false } className="btn btn-light me-3" data-kt-users-modal-action="cancel">Discard</button>
                <button type="submit" disabled={loading ? true : false } className="btn btn-beysix" onClick={!edit ? handleSubmit : handleSubmitEditRoles}>
                    {!loading ? <span className="indicator-label">Submit</span> :
                    <span className="indicator-progress">Please wait... 
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                    }
                </button>
			</div>
		</div>
  )
}
