import React from 'react'
import TablePagination from '../Utilities/TablePagination/TablePagination';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function MainTable({ currentPage, setCurrentPage, totalPages, searching, search, query, massAction, sameactivestat, activate, children}) {

const permissions = useSelector((state) => state.loginSlice.permissions);
  
return (
    <div className="card">
        <div className="card-header border-0 pt-6">
            <div className="card-title">
                <div className="d-flex align-items-center position-relative my-1">
                    <span className="svg-icon svg-icon-1 position-absolute ms-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                            <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                        </svg>
                    </span>
                    <input type="text" className="form-control form-control-solid w-250px ps-14" placeholder="Search user" onChange={search} value={query}/>
                </div>
            </div>
            <div className="card-toolbar">
            <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                {massAction && 
                <>
                    <button type="button" className="btn btn-light-primary me-3" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                    <span className="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="black"></path>
                        </svg>
                    </span>Approve Selected</button>
                    {sameactivestat && <button type="button" className="btn me-3" style={{color: 'rgb(217, 33, 78)', background:'rgb(217, 33, 78, 0.1)'}}>
                    <span className="svg-icon svg-icon-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <defs></defs>
                        <g id="Arrows"><g id="arr011-020"><g id="arr019"><path style={{opacity:"0.3"}} d="M12,10.59l2.83-2.83a1,1,0,1,1,1.41,1.41L13.41,12ZM10.59,12,7.76,14.83a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0L12,13.41Z"/>
                        <path d="M21,22H3a1,1,0,0,1-1-1V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1V21A1,1,0,0,1,21,22ZM13.41,12l2.83-2.83a1,1,0,1,0-1.41-1.41L12,10.59,9.17,7.76A1,1,0,0,0,7.76,9.17L10.59,12,7.76,14.83a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0L12,13.41l2.83,2.83a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41Z"/>
                        </g></g></g></svg>
                    </span>{activate} Selected</button>}
                </>}
                {permissions["can_invite_user"] &&
                <Link type="button" className="btn btn-beysix" to={'/user-management/invite-user'}>
                <span className="svg-icon svg-icon-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="black"></rect>
                        <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="black"></rect>
                    </svg>
                </span>Invite User</Link>}
            </div>
        </div>
        </div>
        <div className="card-body pt-0">
            <div id="kt_table_users_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
            <table className="align-middle table-row-dashed fs-6 gy-5" id="kt_table_users">
                {children}
            </table>
            </div>
            </div>
            {!searching && <TablePagination currentPage={currentPage} totalPages={totalPages} onPageChange={(page) => {
                setCurrentPage(page); 
            }} />}
        </div>
        {/*end::Card body*/}
    </div>
  )
}
