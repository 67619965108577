function convertCm(cm) {
    if (typeof cm !== 'number' || typeof cm !== 'float') {
        cm = parseFloat(cm);
    }

    const cmInMeter = 100;
    const cmInInch = 2.54;

    const meters = Math.floor(cm / cmInMeter);
    const remainingCm = cm % cmInMeter;
    const inches = (cm / cmInInch).toFixed(2);

    
    return `${cm} centimetre${cm !== 1 ? 's' : ''}`;
    // return `${meters} metre${meters !== 1 ? 's' : ''}, ${cm} centimetre${cm !== 1 ? 's' : ''}, ${inches} inch${inches != 1.00 ? 'es' : ''}`;
}

export default convertCm