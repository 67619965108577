import React, {useEffect, useState} from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import api from '../../../store/api'

export default function ResetPasswordSuccess() {
    const [isValid, setIsValid] = useState(false)
    const navigate = useNavigate()
    const { id } = useParams()

    const checkIfValid = () => {
        api.post('/api/users/check-if-reset-pass-succ-valid/', {
            "id": id
        })
        .then((response) => {
            return;
        })
        .catch((error) => {
            if (error.response.status === 404) {
                navigate(error.response.data.url)
            }
        })
    }

    useEffect(() => {
        checkIfValid()
    }, [])
    return (
    <div>
    {/*begin::Wrapper*/}
					<div className="pt-lg-10 mb-10">
						{/*begin::Logo*/}
						<h1 className="fwt-bolder fs-3 text-gray-800 mb-7 text-center">Password is changed</h1>
						{/*end::Logo*/}
						{/*begin::Message*/}
						<div className="fwt-bold fs-5 text-muted mb-15">Your password is successfully changed. Please Sign
						<br />in to your account.</div>
						{/*end::Message*/}
						{/*begin::Action*/}
						<div className="text-center">
							<Link to="/auth/login" className="btn btn-beysix btn-lg fwt-bolder">Sign In</Link>
						</div>
						{/*end::Action*/}
					</div>
					{/*end::Wrapper*/}
					{/*begin::Illustration*/}
					<div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px" style={{marginTop: "20px",
					backgroundImage: "url('/static/assets/media/illustrations/beysix-1/reset-success.svg')",
					width: "100%",
					height: "40vh",
					backgroundSize: "contain",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat"}}></div>
					{/*end::Illustration*/}
                    </div>
  )
}
