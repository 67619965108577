import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell, Legend } from 'recharts';
import formatMoney from '../../Utilities/FormatMoney'

const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#0088FE', '#00C49F', '#FFBB28'];

const MainBarChart = ({ _data, _name = null, _value = null, _money = null }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(_data);
    }, [_data]);

    // Estimate chart width based on the number of bars
    const chartWidth = data.length * 50;  // You can adjust the multiplier to control bar spacing

    return (
        <div style={{ width: '100%', overflowX: 'auto' }}> {/* Enable horizontal scrolling */}
            <div style={{ width: `${chartWidth}px`, minWidth: '100%' }}> {/* Chart width dynamically adjusts */}
                <ResponsiveContainer width="100%" height={450}>
                    <BarChart 
                        data={data} 
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey={_name ? _name : "name"}
                            angle={-40}  // Rotate labels
                            textAnchor="end"  // Align the labels to the end
                            height={170} // Adjust height to avoid overlapping
                        />
                        <Tooltip formatter={_money ? (value) => formatMoney(value, 'KES') : undefined}  />
                        <YAxis tickFormatter={_money ? formatMoney : undefined} />
                        <Tooltip />
                        <Bar dataKey={_value ? _value : "value"} barSize={10}>
                            {data && data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default MainBarChart;
