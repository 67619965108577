import React, {useState, useEffect} from 'react'
import {useParams, useNavigate} from 'react-router'
import api from '../../store/api'
import {useSelector} from 'react-redux'
import TableHead from '../Table/TableHead'
import UserTBody from '../Table/UserTBody'
import MainTable from '../Table/MainTable'

export default function UserList() {

    const { user_stat } = useParams()
    const navigate = useNavigate()
    const [userList, setUserList] = useState([])
	const [currentPage, setCurrentPage] = useState(1)
	const [totalPages, setTotalPages] = useState(0)
	const [thead, setThead] = useState([])
	const [isSearch, setIsSearch] = useState(false)
	const [searchQuery, setSearchQuery] = useState('')
	const [roles, setRoles] = useState([])
	const [selectedUsers, setSelectedUsers] = useState([])
	const [originalSelectedUsers, setOriginalSelectedUsers] = useState([])
    const [massAction, setMassAction] = useState(false)
    const token = useSelector((state) => state.loginSlice.token);
    const [allSameActiveStat, setallSameActiveStat] = useState(true)
	const [activeStat, setActiveStat] = useState([])
	const [activate, setActivate] = useState('')
	
    const getUsers = () => {
		api.get(`/api/users/get_users/${user_stat}?page=${currentPage}`,
		{
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Token ${token}`,
			},
		}
	)
        .then((response) => {
            if (response.status === 200) {
				setUserList(response.data.results.user_list)
				setTotalPages(response.data.total_pages)
            }
        })
        .catch((error) => {
            if (response.status === 404) {

            } else {
                throw new Error(error)
            }
        })
    }

	useEffect(() => {
		getUsers();
	}, [currentPage]);

	useEffect(() => {
		if (userList.length > 0){
			let _u = []
			for (let i = 0; i < userList.length; i++) {
				_u.push(userList[i].id)
			}
			setOriginalSelectedUsers(_u)
		}
	}, [userList]);

	useEffect(() => {
		getClients()
	}, [])

	const getRoles = async () => {
        const response = await api.get('/api/users/groups/', 
            {
                headers: {
					'Content-Type': 'application/json',
					 Authorization: `Token ${token}`,
				},
            }
        );
        setRoles(response.data.data);
    }

	useEffect(() => {
		setCurrentPage(1);
		if (user_stat === 'staff') {
			setThead(['User', 'Occupation', 'Status', 'Approved', 'Phone Number', 'Joined Date'])
		} else if (user_stat === 'clients') {
			setThead([ 'User', 'Client', 'Brand Count', 'Status', 'Approved', 'Phone Number', 'Joined Date'])
		} else if (user_stat === 'crew') {
			setThead([ 'User', 'Occupation', 'Status', 'Approved', 'Phone Number', 'Joined Date'])
		} else {
			setThead([ 'User', 'Email Verified', 'Approved', 'Joined Date'])
		}
		setSearchQuery('')
		getRoles()
		setSelectedUsers([])
		setallSameActiveStat(true)
		setActiveStat([])
		setSelectedUsers([])
		setActivate('')
		setMassAction(false)
	}, [user_stat]);

	const handleSearch = (query) => {
		setSearchQuery(query)
		if (query.length >= 3) {
			api.post(`/api/users/search-user/${user_stat}/`, {query: query},
			{headers: {
					'Content-Type': 'application/json',
					Authorization: `Token ${token}`,
				},
			})
			.then((response) => {
				if (response.status === 200) {
					setUserList(response.data.results)
					setIsSearch(true)
				}
			})
			.catch((error) => {
				if (response.status === 404) {

				} else {
					throw new Error(error)
				}
			})
		} else if (query.length === 0) {
			getUsers()
			setIsSearch(false)
		}
	}

	const returnUserActiveStatus = (id) => {
		let user;
		for (let i = 0; i < userList.length; i++) {
			if (id === userList[i].id) 
				user = userList[i]
		}
		return user.is_active
	}

	function allElementsSameBool(obj) {
		var keys = Object.keys(obj);
		let objectKeys = []
		for (let i = 0; i < obj.length; i++) 
			objectKeys.push(Object.keys(obj[i])[0])
		if (keys.length === 0)
			return { 'bool': false, 'status': null };
		var firstBool = obj[keys[0]][objectKeys[0]];
		for (var i = 1; i < keys.length; i++) {
			console.log(obj[keys[i]][objectKeys[i]])
			if (obj[keys[i]][objectKeys[i]] !== firstBool)
				return { 'bool': false, 'status': null };
		}
		var status = (firstBool === false) ? 'Activate' : 'Deactivate';
		return { 'bool': true, 'status': status };
	}
	

	const handleSelectAll = (evt) => {
		const cBox = evt.target.checked
		let activeArr;
		if (cBox) {
			const userIds = [];
			for (let i = 0; i < userList.length; i++) {
				const _uid = userList[i].id
				userIds.push(_uid);
				const _isActive = returnUserActiveStatus(_uid)
				activeArr = activeStat
				const strId = _uid.toString()
				let currentObj = {}
				currentObj[strId] = _isActive
				activeArr.push(currentObj)
				setActiveStat(activeArr)
			}

			const {bool, status} = allElementsSameBool(activeArr)
			setallSameActiveStat(bool)
			setSelectedUsers(userIds)
			setActivate(status)
			setOriginalSelectedUsers(userIds)
			setMassAction(true)
		} else {
			setallSameActiveStat(true)
			setActiveStat([])
			setSelectedUsers([])
			setActivate('')
			setMassAction(false)
		}
	}

	const findObjIndex = (id) => {
		for (let i = 0; i < activeStat.length; i++) {
			var key = Object.keys(activeStat[i]);
			if (parseInt(key) === id) 
				return i
		}
	}

	const handleSelectChange = (evt) => {
		const cBox = evt.target.checked
		const value = parseInt(evt.target.value)
		let new_users = [];
		let activeArr;
		if (cBox && !selectedUsers.includes(value)) {
			new_users = [...selectedUsers, value]
			setSelectedUsers(new_users)
			const _isActive = returnUserActiveStatus(value)
			activeArr = activeStat
			const strId = value.toString()
			let currentObj = {}
			currentObj[strId] = _isActive
			activeArr.push(currentObj)
			setActiveStat(activeArr)
		}

		if (!cBox && selectedUsers.includes(value)) {
			const users = selectedUsers
			const index = selectedUsers.indexOf(value)
			if (index !== -1) {
				new_users = [...users.slice(0, index), ...users.slice(index + 1)];
				setSelectedUsers(new_users)
				activeArr = activeStat
				const _ind = findObjIndex(value)
				const objIndex = activeArr.indexOf(_ind)
				const new_act = [...activeArr.slice(0, objIndex), ...activeArr.slice(objIndex + 1)];
				setActiveStat(new_act)
			}
		}

		const {bool, status} = allElementsSameBool(activeArr)
		setallSameActiveStat(bool)
		setActivate(status)
		if (new_users.length > 1) 
			setMassAction(true)
		else
			setMassAction(false)
	}

	function containsAllElements(arr1, arr2) {
		if (arr1.length > 0 || arr2.length > 0) 
			return arr2.every(element => arr1.includes(element));
		else
			return false
	}

	const [clients, setClients] = useState([])
	const getClients = () => {
		api.get('/api/clients/clients', {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
		.then((response) => {
			const data = response.data.results
			setClients(data)
		})
		.catch((error) => {
			throw new Error(error)
		})
	}

  return (
    <div>
		<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
			<div className="post d-flex flex-column-fluid" id="kt_post">
				<div id="kt_content_container" className="container-xxl">
					<MainTable activate={activate} sameactivestat={allSameActiveStat} massAction={massAction} query={searchQuery} searching={isSearch} currentPage={currentPage} totalPages={totalPages} search={(evt) => handleSearch(evt.target.value)} setCurrentPage={(page) => setCurrentPage(page)}>
						<TableHead allSelected={containsAllElements(selectedUsers, originalSelectedUsers)} toggleSelectAll={(evt) => handleSelectAll(evt)} columns = {thead} actions={true} checkbox = {true} />
						<UserTBody clients={clients} handleSelectChange={handleSelectChange} _selected={selectedUsers} roles={roles} userList={userList} user_stat={user_stat} token={token} actions={true} checkbox = {true}/>
					</MainTable>
				</div>
			</div>
		</div>
	</div>
  )
}
