import React, { useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useParams } from 'react-router';
import api from '../../../store/api';
import { useSelector } from 'react-redux';

export default function ActivationMedia({_activationMedia}) {
    const token = useSelector((state) => state.loginSlice.token);
    const [activationMedia, setActivationMedia] = useState([])
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const { id } = useParams()

    const getActivationMedia = () => {
      api.get(`/api/activations/get-media/${id}/`,
        {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
        .then((response) => {
            if (response.status === 200) {
                if (response.data.results.length > 0){
                    setActivationMedia(response.data.results)
                } else {
                    setActivationMedia(response.data.results)
                    setResultsFound(false)
                }
            setPageLoading(false)
            }
        })
        .catch((error) => {
            setPageLoading(false)
            throw new Error('Error getting data.')
        })
    }

    useEffect(() => {
        getActivationMedia()
    }, [_activationMedia])

  return (
    <div>
        <div className="gallery row">
        {activationMedia.length > 0 ? activationMedia.map((image, index) => (
          <div className='col-md-3 image-gallery-single'>
                <img
                    key={index}
                    src={image.media_path}
                    onClick={() => {
                    setPhotoIndex(index);
                    setIsOpen(true);
                    }}
                    alt={activationMedia.media_name}
                />
          </div>
        )) : 
        <div style={{width: '100%', textAlign: 'center'}}>
            <p>No Images have been added</p>
        </div>}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={activationMedia[photoIndex].media_path}
          nextSrc={activationMedia[(photoIndex + 1) % activationMedia.length].media_path}
          prevSrc={activationMedia[(photoIndex + activationMedia.length - 1) % activationMedia.length].media_path}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + activationMedia.length - 1) % activationMedia.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % activationMedia.length)
          }
        />
      )}
    </div>
  )
}
