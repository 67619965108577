import React, {useRef, useEffect, useState} from 'react'
import parseDateTimeStamp from '../../Utilities/parseDateTimeStamp.'
import { Link } from 'react-router-dom';
import convertCm from '../../Utilities/convertCm'
import SkeletonLoader from '../../Warehouse/SkeletonLoader/SkeletonLoader';
import { motion } from 'framer-motion';

export default function ItemTable({itemsList, pageLoading, handleSearch,  resultsFound, setPage=null, page=null, subsequentLoading=null}) {
  
    function handleScroll(container) {
        const scrollPosition = container.scrollTop + container.clientHeight;
        const threshold = container.scrollHeight * 0.7; // 70% of the total scroll height
      
        if (scrollPosition >= threshold) {
          if (!subsequentLoading) {
            setPage(prevPage => prevPage + 1);
          }
        }
    }

    const [items, setItems] = useState([])
    useEffect(() => {
        setItems(itemsList) //
    }, [itemsList])

    
    const tableRef = useRef(null);
  
return (
    <>
    {!pageLoading ? 
        resultsFound &&
        <div className="table-responsive"
            ref={tableRef}
            style={{ height: '60vh', overflowY: 'auto' }}
            onScroll={(setPage !== null && page !== null && subsequentLoading !== null) ? () => handleScroll(tableRef.current) : null}
        >
        {/*begin::Table*/}
        <motion.table
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.3 }}
            className="table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 p-5"
        >
        {/*begin::Table head*/}
        <thead>
        <tr className="fwt-bold bg-light">
            <th className="ps-4 min-w-250px rounded-start">Item Name</th>
            {/* <th className="min-w-200px">Asset(s)</th> */}
            <th className="min-w-125px">Item Length</th>
            <th className="min-w-125px">Item Width</th>
            <th className="min-w-125px">Item Height</th>
            <th className="min-w-125px">Item Quantity</th>
            <th className="min-w-125px">Aging Days</th>
            <th className="min-w-125px">Created Date</th>
            <th className="min-w-125px">Created By</th>
        </tr>
        </thead>
        {/*end::Table head*/}
        {/*begin::Table body*/}
        <tbody>
{items.map((item) => {
    return (<tr>
            <td>
                <div className="d-flex align-items-center">
                <Link to={`/asset-management/detailed-item/${item.id}`}>
                    <div className="symbol symbol-50px me-5">
                    <span className="symbol-label bg-light">
                        {item.images[0] ?  
                        <img src={item.images[0].image_url} className="h-100 align-self-end" alt="" style={{width: '100%', height: '100%', borderRadius: '5px'}} /> :
                        <span className="svg-icon svg-icon-3x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path opacity="0.3" d="M5 8.04999L11.8 11.95V19.85L5 15.85V8.04999Z" fill="black"/>
                        <path d="M20.1 6.65L12.3 2.15C12 1.95 11.6 1.95 11.3 2.15L3.5 6.65C3.2 6.85 3 7.15 3 7.45V16.45C3 16.75 3.2 17.15 3.5 17.25L11.3 21.75C11.5 21.85 11.6 21.85 11.8 21.85C12 21.85 12.1 21.85 12.3 21.75L20.1 17.25C20.4 17.05 20.6 16.75 20.6 16.45V7.45C20.6 7.15 20.4 6.75 20.1 6.65ZM5 15.85V7.95L11.8 4.05L18.6 7.95L11.8 11.95V19.85L5 15.85Z" fill="black"/>
                        </svg></span>}
                    </span>
                    </div>
                </Link>
                <div className="d-flex justify-content-start flex-column">
                    <Link to={`/asset-management/detailed-item/${item.id}`} className="text-dark fwt-bold text-hover-primary mb-1 fs-6">{item.item_name}</Link>
                </div>
                </div>
            </td>
            <td>
                {item.item_length !== '' && item.item_length !== null ?
                <a className="text-dark fwt-bold d-block mb-1 fs-6">{convertCm(item.item_length)}</a> :
                <a className="text-dark fwt-bold d-block mb-1 fs-6">Not added</a>}
            </td>
            <td>
                {item.item_width !== '' && item.item_width !== null ?
                <a className="text-dark fwt-bold d-block mb-1 fs-6">{convertCm(item.item_width)}</a> :
                <a className="text-dark fwt-bold d-block mb-1 fs-6">Not added</a>}
            </td>
            <td>
                {item.item_height !== '' && item.item_height !== null ?
                <a className="text-dark fwt-bold d-block mb-1 fs-6">{convertCm(item.item_height)}</a> :
                <a className="text-dark fwt-bold d-block mb-1 fs-6">Not added</a>}
            </td>
            <td>
                <a  className="text-dark fwt-bold d-block mb-1 fs-6">{item.quantity}</a>
                
            </td>
            <td>
                {item.aging_days ?
                <a  className="text-dark fwt-bold d-block mb-1 fs-6">{item.aging_days === 1 ? `${item.aging_days} Day` : `${item.aging_days} Days`}</a> : 
                <a  className="text-dark fwt-bold d-block mb-1 fs-6">Not added</a>}
            </td>
            <td>
                <a  className="text-dark fwt-bold d-block mb-1 fs-6">{parseDateTimeStamp(item.created_at)}</a>
            </td>
            <td>
                <Link to={`/user-management/users/user-profile/${item.created_by}`} className="text-dark fwt-bold text-hover-primary d-block mb-1 fs-6">{item.created_by_name}</Link>
            </td>
            </tr>)
                })}
            </tbody>
                    {/*end::Table body*/}
                </motion.table>
                {/*end::Table*/}
            </div> :
            <SkeletonLoader />}
        </>
  )
}
