import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import api from '../../../store/api';
import getRandomHexColor from '../../Utilities/getRandomHexColor';
import FeedbackModal from '../../Utilities/FeedbackModal';
import ProfileImageInput from '../../Utilities/ProfileImageInput'

export default function CreateBrand() {

    const token = useSelector((state) => state.loginSlice.token);
    const [searchQuery, setSearchQuery] = useState("")
    const [searchResults, setSearchResults] = useState([])
    const [isSearching, setIsSearching] = useState(false)
    const [userSearching, setUserSearching] = useState(false)
    const [resultsFound, setResultsFound] = useState(false)
    const [SuccessfullSumbission, setSuccessfullSumbission] = useState(false)
    
    const [clients, setClients] = useState([])

    const getClients = () => {
        api.get('/api/clients/clients', {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
        .then((response) => {
            const data = response.data.results
            setClients(data)
        })
        .catch((error) => {
            setRes({
                message: error.response.data.error,
                severity: "error",
                show: true
            })
        })
    }

    const [agencies, setAgencies] = useState([])
    const getAgencies = () => {
        api.get('/api/agencies/agencies', {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
        .then((response) => {
            const data = response.data.results
            setAgencies(data)
        })
        .catch((error) => {
            setRes({
                message: error.response.data.error,
                severity: "error",
                show: true
            })
        })
    }

    useEffect(() => {
        getAgencies();
        getClients();
    }, [])

    const handleSearch = (evt) => {
      evt.preventDefault()
      const query = evt.target.value;
          setSearchQuery(query)
          if (query.length >= 2) {
              setUserSearching(true)
              setIsSearching(true)
              api.post(`/api/users/search-user/clients/`, {query: query},
              {headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Token ${token}`,
                  },
              })
              .then((response) => {
                  if (response.status === 200) {
                      if (response.data.results.length > 0){
                          setResultsFound(true)
                          setSearchResults(response.data.results)
                          setIsSearching(false)
                      } else {
                          setResultsFound(false)
                          setSearchResults(response.data.results)
                          setIsSearching(false)
                      }
                  }
              })
              .catch((error) => {
                  if (response.status === 404) {
  
                  } else {
                      throw new Error(error)
                  }
              })
          } else if (query.length === 0) {
              setResultsFound(false)
              setIsSearching(false)
              setUserSearching(false)
          }
    }
  
    const handleCancelSearch = (evt) => {
        evt.preventDefault()
        setResultsFound(false)
        setIsSearching(false)
        setUserSearching(false)
        setSearchResults([])
        setResultsFound(false)
        setSearchQuery("")
    }

    const [brandData, setBrandData] = useState(
        {
          brandName: '',
          brandClient: '0',
          brandAgency: 0,
          brandDescription: '',
          brandRepresentativeId: '',
          brandImage: null
        }
      )
    
      const handleManagerClick = (id, name) => {
          setBrandData({...brandData, brandRepresentativeId: id})
          setResultsFound(false)
          setIsSearching(false)
          setUserSearching(false)
          setSearchResults([])
          setResultsFound(false)
          setSearchQuery(name)
      }

      const handleValidate = (data) => {
        if (data.brandName.length <= 0) {
          setErrors({...errors, brandNameError: 'The name of the brand cannot be empty.'})
          return false
        }  else {
            setErrors({...errors, brandNameError: ''})
        }
        if (data.brandRepresentativeId.toString().length <= 0) {
            setErrors({...errors, brandRepresentativeIdError: 'You need to select a brand representative.'})
            return false
        } else {
            setErrors({...errors, brandRepresentativeIdError: ''})
        }
        if (data.brandClient.toString() === '0') {
            setErrors({...errors, brandClientError: 'You need to select the client.'})
            return false
        } else {
            setErrors({...errors, brandClientError: ''})
        }
        if (data.brandAgency.toString() === '0') {
            setErrors({...errors, brandAgencyError: 'You need to select the commissioning agency.'})
            return false
        } else {
            setErrors({...errors, brandAgencyError: ''})
        }
    
        return true
      }
      
      const [res, setRes] = useState({
        message: "",
        severity: "success",
        show: false
      })
    
      const handleChange = (evt) => {
        evt.preventDefault()
        if (evt.target)
          setBrandData({...brandData, [evt.target.name]: evt.target.value})
      }

      const [loading, setLoading] = useState(false)

      const handleSubmit = (evt) => {
        evt.preventDefault()
        if (handleValidate(brandData)) {
            setLoading(true)
            const formData = new FormData()
            formData.append('brandName', brandData.brandName)
            formData.append('brandClient', brandData.brandClient)
            formData.append('brandDescription', brandData.brandDescription)
            formData.append('brandRepresentativeId', brandData.brandRepresentativeId)
            formData.append('brandImage', brandData.brandImage)
            formData.append('brandAgency', brandData.brandAgency)
            api.post('/api/brands/brand/', formData,
            {headers: {'Content-Type': 'multipart/form-data', Authorization: `Token ${token}`}})
    
          .then((response) => {
            const message = response.data.message
            setRes({
              message: message,
              severity: "success",
              show: true
            })
            setBrandData({
                brandName: '',
                brandClient: '0',
                brandDescription: '',
                brandRepresentativeId: '',
                brandImage: null
            })
            setErrors({
                    brandNameError: '',
                    brandClientError: '',
                    brandDescriptionError: '',
                    brandRepresentativeIdError: ''
            })
            setResultsFound(false)
            setIsSearching(false)
            setUserSearching(false)
            setSearchResults([])
            setResultsFound(false)
            setSearchQuery("")
            setLoading(false)
            setSuccessfullSumbission(true)
          })
    
          .catch((error) => {
            setRes({
              message: error.response.data.error,
              severity: "error",
              show: true
            })
            setLoading(false)
          })
        }
      }
    
    const [errors, setErrors] = useState({
        brandNameError: '',
        brandClientError: '',
        brandDescriptionError: '',
        brandRepresentativeIdError: '',
        brandImageError: '',
        brandAgencyError: ''        
    })

  return ( <>
    <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
        setRes({
            message: "",
            severity: "success",
            show: false
        })
    }}/><div id="kt_content_container" className="container-xxl">
    <form className="form">
        <div className="card">
            <div className="card-header"><div className="card-title fs-4 fwt-bolder">Create Brand</div></div>
            <div className="card-body" style={{maxWidth: "80%", margin: "0px auto", minWidth: "70%"}}>
                <div className="py-10 px-lg-17">
                    <div
                        className="scroll-y me-n7 pe-7"
                        id="kt_modal_new_address_scroll"
                        data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}"
                        data-kt-scroll-max-height="auto"
                        data-kt-scroll-dependencies="#kt_modal_new_address_header"
                        data-kt-scroll-wrappers="#kt_modal_new_address_scroll"
                        data-kt-scroll-offset="300px"
                        style={{maxHeight: "476px"}}
                    >
                        <div className="row mb-3">
                            <div className="col-md-12 fv-row fv-plugins-icon-container mb-4">
                                <div className="col-md-6 fv-row fv-plugins-icon-container">
                                {/*begin::Label*/}
                                <label className="required fs-6 fwt-bold mb-2">Brand Image</label>
                                {/*end::Label*/}
                                {/*begin::Input*/}
                                <ProfileImageInput setImage={(image) => {
                                    setBrandData({...brandData, brandImage: image})
                                }} edit={false} image={brandData.brandImage} 
                                SuccessfullSumbission={SuccessfullSumbission} />
                                </div>
                            </div>
                            <div className="col-md-12 fv-row fv-plugins-icon-container">
                                <label className="required fs-6 fwt-bold mb-2">Brand Name</label>
                                <input type="text" value={brandData.brandName} onChange={handleChange} name="brandName" className="form-control form-control-solid" placeholder="" />
                                <div className="fv-plugins-message-container invalid-feedback"><div data-field="account_name" data-validator="notEmpty">{errors.brandNameError}</div></div>
                            </div>
                            <div className="col-md-6 fv-row fv-plugins-icon-container mt-5">
                                <label className="required fs-6 fwt-bold mb-2">Client</label>
                                <select id="dropdown" name="brandClient" className='form-control form-control-solid' onChange={handleChange} value={brandData.brandClient}>
                                    <option value="0">-- Select Client --</option>
                                    {clients.map((client) => {return(<option value={client.id}>{client.name}</option>)})}
                                </select>
                                <div className="fv-plugins-message-container invalid-feedback"><div data-field="account_name" data-validator="notEmpty">{errors.brandClientError}</div></div>
                            </div>
                            <div className="col-md-6 fv-row fv-plugins-icon-container mt-5">
                                <label className="required fs-6 fwt-bold mb-2">Commissioning Agency</label>
                                <select id="dropdown" name="brandAgency" className='form-control form-control-solid' onChange={handleChange} value={brandData.brandAgency}>
                                    <option value="0">-- Select Commissioning Agency --</option>
                                    {agencies.map((agency) => {return(<option value={agency.id}>{agency.name}</option>)})}
                                </select>
                                <div className="fv-plugins-message-container invalid-feedback"><div data-field="account_name" data-validator="notEmpty">{errors.brandAgencyError}</div></div>
                            </div>
                        </div>
                        <div className="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                    <div className="fv-row mb-10 fv-plugins-icon-container mt-5">
                        <label className="form-label required">Brand Representative</label>
                        <div>
                            <div>
                            <div id="kt_docs_search_handler_basic" data-kt-search-keypress="true" data-kt-search-min-length="2" data-kt-search-enter="true" data-kt-search-layout="inline" data-kt-search="true" className="">
                                    <input type="hidden" />
                                    <div style={{position: 'relative'}}>
                                    <span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500" style={{position: "absolute", left: "2%", top: '25%'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                                            <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path>
                                        </svg>
                                    </span>
                                        <input type="text" className="form-control form-control-lg form-control-solid px-15" name="search" value={searchQuery} placeholder="Search by username, full name or email..." onChange={handleSearch} />
                                        {isSearching ? <span className="lh-0 me-5" data-kt-search-element="spinner" style={{position: "absolute", top: "35%", left: "94%"}}>
                                            <span className="spinner-border h-15px w-15px align-middle text-gray-400"></span>
                                        </span> :
                                        searchQuery.length > 0 && 
                                        <span className="btn btn-flush btn-active-color-primary end-0 lh-0" style={{position: "absolute", top: "25%", left: "93%"}} data-kt-search-element="clear" onClick={handleCancelSearch}>
                                            <span className="svg-icon svg-icon-2 svg-icon-lg-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                                                </svg>
                                            </span>
                                        </span>}
                                {userSearching && 
                                <div className="quick-search-dropdown" style={{position: "absolute"}}>
                                    {searchResults.length > 0 && resultsFound ? <div data-kt-search-element="results" className="">
                                    {searchResults.map((result, index) => {
                                    const { randomColor, darkerShade, brighterShade } = getRandomHexColor();
                                    return (
                                    <div className="d-flex align-items-center p-3 rounded-3 border-hover border border-dashed border-gray-300 cursor-pointer mb-1"key = {index} onClick={() => handleManagerClick(result.id, result.profile && `${result.profile.first_name} ${result.profile.last_name}`)}>
                                        <div className="symbol symbol-35px symbol-circle me-5">
                                            {result.profile && (result.profile.profile_photo_path !== "" && result.profile.profile_photo_path !== null) ?
                                            <img alt="Pic" src={result.profile.profile_photo_path} style={{objectFit: "cover"}}/> :
                                            result.profile && <div className="symbol-label fs-5" style={{ background: brighterShade, color: darkerShade }}>{result.profile.first_name[0]}{result.profile.last_name[0]}</div>}
                                        </div>
                                        <div className="fw-bold">
                                            <span className="fs-6 text-gray-800 me-2">{result.profile && `${result.profile.first_name} ${result.profile.last_name}`}</span>
                                            <span className="badge badge-light">{result.profile && result.profile.occupation}</span>
                                        </div>
                                    </div>)})}
                                    </div> :
                                    <div data-kt-search-element="empty" className="text-center">
                                        {/*begin::Message*/}
                                        <div className="fwt-bold py-0 mb-10">
                                            <div className="text-gray-600 fs-3 mb-2">No users found</div>
                                            <div className="text-gray-400 fs-6">Try to search by username, full name or email...</div>
                                        </div>
                                        {/*end::Message*/}
                                        {/*begin::Illustration*/}
                                        <div className="text-center px-4">
                                            <img className="mw-100 mh-200px" alt="" src="assets/media/illustrations/alert.png" />
                                        </div>
                                        {/*end::Illustration*/}
                                    </div>}
                                    {/*end::Empty*/}
                                </div>}
                                {/*end::Wrapper*/}
                                            </div>
                                            {/*end::Search*/}
											</div>
                            </div>
                        </div>
                        <div className="fv-plugins-message-container invalid-feedback"><div data-field="account_name" data-validator="notEmpty">{errors.brandRepresentativeIdError}</div></div>
                    </div>
                    <div className="d-flex flex-column mb-3 fv-row fv-plugins-icon-container">
                        <label className="required fs-6 fwt-bold mb-2">Description</label>
                        <textarea value={brandData.brandDescription} className="form-control form-control-solid" onChange={handleChange} name="brandDescription" rows="7" cols="30"></textarea>
                        <div className="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                </div>
            </div>
            <div className="card-footer d-flex justify-content-end py-6">
            <button type="submit" id="kt_modal_new_card_submit" onClick={handleSubmit} className="btn btn-beysix" disabled={!loading ? false : true}>
                {!loading ? <span className="indicator-label">Create Brand</span>
                : <span className="indicator-progress">Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
            </button>
            </div>
        </div>
    </form>
</div>
</>
  )
}
