import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import api from '../../../store/api'

export default function VerifyEmail() {
	const [email, setEmail] = useState('')
	const [clause, setClause] = useState(' have')
	const { id } = useParams()
	const navigate = useNavigate()
	const [timeLeft, setTimeLeft] = useState(30);
	const [showResendOption, setShowResendOption] = useState(false)
			
	const checkIfEmailIsVerified = async () => {
		api.get(`/api/users/show-email-verification/${id}`)
        .then(response => {
            if (response.status === 200) {
                setEmail(response.data.email);
                setClause(response.data.clause);
				setShowResendOption(response.data.show_resend_option)
            } 
        })
        .catch(error => {
			const err = error.response
			if (err.status === 404)  {
				navigate(err.data.url);
				return;
			}
			throw new Error(err.data.error)
        });
	}

	useEffect(() => {
		checkIfEmailIsVerified()
	}, [])

	useEffect(() => {
		if (showResendOption === false) {
			if (timeLeft === 0) {
				setShowResendOption(true)
				update_resend_option(true)
				return;
			}

			const countdownInterval = setInterval(() => {
				setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
			}, 1000);
			return () => clearInterval(countdownInterval);
		}
	}, [timeLeft, showResendOption]);

	const handleResendVerificationEmail = (evt) => {
		evt.preventDefault();
		api.post(`/api/users/resend-email/`, {
            "show_resend_option": !showResendOption,
			"id": id,
			"resend_type": "verify_email"
        })
        .then(response => {
            if (response.status === 200) {
				setTimeLeft(30)
                setShowResendOption(response.data.show_resend_option)
            } 
        })
        .catch(error => {
			const err = error.response
			throw new Error(err.data.error)
        });
	}


	const update_resend_option = (update) => {
		api.post(`/api/users/update-resend-btn/`, {
            "id": id, 'show_resend_btn': update, 'resend_type': 'verify_email'
        })
	}

  return (
    <div className="pt-lg-10 mb-10 text-center">
		{/*begin::Logo*/}
		<h1 className="fwt-bolder fs-3 text-gray-800 mb-7 text-center">Verify Your Email</h1>
		{/*end::Logo*/}
		{/*begin::Message*/}
		<div className="fs-5 fwt-bold text-muted mb-10">We{clause} sent an email to
		<Link to="" className="link-primary fwt-bolder" style={{marginLeft: "5px"}}>{email}</Link>
		<br />please follow the link on your email to activate your account.</div>
		{/*begin::Action*/}
		<div className="fs-6 text-center">
		<span className="fwt-bold text-gray-700">Did’t receive an email?</span>
		{showResendOption === false ? <p>Resend email in {timeLeft} seconds.</p>
		:  <a  onClick={handleResendVerificationEmail} className="link-primary fwt-bolder" style={{marginLeft: "5px"}}>Resend</a>
		}
		</div>
		
	<div  style={{marginTop: "70px",
					backgroundImage: "url('/static/assets/media/illustrations/beysix-1/email-verification.svg')",
					width: "70vw",
					height: "40vh",
					backgroundSize: "contain",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat"
				}}></div>
		{/*end::Action*/}
	</div>
  )
}
