import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; // Import skeleton styles
import { motion } from 'framer-motion'

const DashboardCardComponent = ({ isLoading, children }) => {
  return (
    <div
      className={!isLoading ? "card h-100" : "h-100"}
      style={{display: 'block'}}
    //   style={{
    //     width: '300px',
    //     height: '400px',
    //     padding: '16px',
    //     boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    //     borderRadius: '8px',
    //     backgroundColor: '#fff',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     justifyContent: 'space-between',
    //   }}
    >
      {isLoading ? (
        <Skeleton 
          height="100%" // Makes the skeleton fill the entire card area
          width="100%"
        />
      ) : (
        <motion.div
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.3 }}style={{fontWeight: '600'}}>
            {children}
        </motion.div>
      )}
    </div>
  );
};

export default DashboardCardComponent;
