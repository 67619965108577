import React,  { useState, useEffect }  from 'react'
import parseDateTimeStamp from '../../Utilities/parseDateTimeStamp.'
import { Link } from 'react-router-dom';
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader'
import { motion } from 'framer-motion';
import NoResults from '../../Utilities/NoResults';

export default function ProjectTable({ projectData,  loading, resultsFound }) {

    const [projectsList, setProjectsList] = useState([])
    useEffect(() => {
        setProjectsList(projectData)
    }, [projectData])

  return (<>
            {!loading ?
                resultsFound ?
                <div className="table-responsive">
                    <motion.table
                        initial={{ y: 100, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.3 }}
                        className="table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 p-5"
                        >
                        <thead>
                            <tr className="fw-bolder text-muted-2 bg-tr-light">
                                <th className="ps-4 min-w-200px rounded-start">Project Name</th>
                                <th className="min-w-125px">Client</th>
                                <th className="min-w-125px">Brand</th>
                                <th className="min-w-200px">Commissioning Agency</th>
                                <th className="min-w-125px">Project Manager</th>
                                <th className="min-w-125px">Created Date</th>
                                <th className="min-w-125px">Created By</th>
                                {/* <th className="min-w-200px text-end rounded-end"></th> */}
                            </tr>
                        </thead>
                        <tbody>
                        {projectsList.map((project, index) => (
                            <tr key={index}>
                                <td>
                                    {/* <Link to = {`/project-management/detailed-project/${project.id}`} className="text-dark  text-hover-primary  fw-bolder d-block mb-1 fs-6">{project.project_name}</Link> */}
                                    <div className="d-flex align-items-center">
                                        <Link to = {`/project-management/detailed-project/${project.id}`}>
                                        <div className="symbol symbol-60px me-3" style={{width: '60px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            {project.profile_project_image_path && project.profile_project_image_path !== '' ? 
                                            <img src={project.profile_project_image_path} className="h-100 align-self-end" alt={project.profile_project_image_name} style={{width: '100%', height: '100%', borderRadius: '5px'}} />
                                            :
                                            <span className="svg-icon svg-icon-3x"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"><path opacity="0.3" d="M8.9 21L7.19999 22.6999C6.79999 23.0999 6.2 23.0999 5.8 22.6999L4.1 21H8.9ZM4 16.0999L2.3 17.8C1.9 18.2 1.9 18.7999 2.3 19.1999L4 20.9V16.0999ZM19.3 9.1999L15.8 5.6999C15.4 5.2999 14.8 5.2999 14.4 5.6999L9 11.0999V21L19.3 10.6999C19.7 10.2999 19.7 9.5999 19.3 9.1999Z" fill="black"></path><path d="M21 15V20C21 20.6 20.6 21 20 21H11.8L18.8 14H20C20.6 14 21 14.4 21 15ZM10 21V4C10 3.4 9.6 3 9 3H4C3.4 3 3 3.4 3 4V21C3 21.6 3.4 22 4 22H9C9.6 22 10 21.6 10 21ZM7.5 18.5C7.5 19.1 7.1 19.5 6.5 19.5C5.9 19.5 5.5 19.1 5.5 18.5C5.5 17.9 5.9 17.5 6.5 17.5C7.1 17.5 7.5 17.9 7.5 18.5Z" fill="black"></path></svg></span>
                                            }
                                        </div>
                                        </Link>
                                        <div className="d-flex justify-content-start flex-column">
                                            <Link className="text-dark fwt-bold text-hover-primary mb-1 fs-6" to = {`/project-management/detailed-project/${project.id}`}>{project.project_name}</Link>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    {project.client ? <Link to = {`/client-management/clients/detailed-client-organization/${project.client.client_id}`} className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{project.client.client_name}</Link> :
                                    <a className="text-dark fw-bolder d-block mb-1 fs-6">Not Added</a>}
                                </td>
                                <td>
                                    <Link to = {`/brand-management/detailed-brand/${project.brand.id}`} className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{project.brand_name}</Link>
                                </td>
                                <td>
                                    {project.commissioning_agency ? <Link to = {`/agency-management/agencies/detailed-agency/${project.commissioning_agency.agency_id}`} className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{project.commissioning_agency.agency_name}</Link> :
                                    <a className="text-dark fw-bolder d-block mb-1 fs-6">Not Added</a>}
                                </td>
                                <td>
                                    <Link className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6" to={`/user-management/users/user-profile/${project.project_manager}`}>{project.project_manager_name}</Link>
                                </td>
                                <td><a className="text-dark fw-bolder d-block mb-1 fs-6">{parseDateTimeStamp(project.created_at)}</a></td>
                                <td>
                                    <Link className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6" to={`/user-management/users/user-profile/${project.created_by}`}>{project.created_by_name}</Link>
                                </td>
                                {/* <td className="text-end">
                                    <Link className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2" to={`/project-management/clients/detailed-project/${project.id}`}>View</Link>
                                    <a className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4" href="/client-management/clients/detailed-client-organization/3#edit">Edit</a>
                                </td> */}
                            </tr>
                        ))}

                        </tbody>
                    </motion.table> 
            </div> :
                <NoResults />
        : <SkeletonLoader />}
        </>
  )
}
