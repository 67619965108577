import api from "../../store/api"

const getAssetSearchData = (searchQuery, endpoint, token) => {
    return new Promise((resolve, reject) => {
        api.post(endpoint, {searchQuery: searchQuery},
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject(new Error(`Unexpected response status: ${response.status}`));
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export default getAssetSearchData;
