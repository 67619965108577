import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader'
import { motion } from 'framer-motion';

export default function WarehouseTable({warehouses, pageLoading, handleSearch, resultsFound=null, searchQuery=''}) {

  return (
    <div className="card mb-5 mb-xl-8">
        {/*begin::Header*/}
        <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
            <div className="d-flex align-items-center position-relative my-1">
            <>
                <span className="svg-icon svg-icon-1 position-absolute ms-6">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect><path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path></svg>
                </span>
                <input type="text" className="form-control form-control-solid w-250px ps-14" placeholder="Search warehouse" onChange={handleSearch}/>
            </>
            </div>
            </h3>
            <div className="card-toolbar">
                <Link to="/warehouse-management/create-warehouse" className="btn btn-sm btn-light-primary">
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
                <span className="svg-icon svg-icon-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="black"></rect>
                        <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="black"></rect>
                    </svg>
                </span>
                {/*end::Svg Icon*/}Create Warehouse</Link>
            </div>
        </div>
        <div className="card-body py-3" style={{overflow: 'auto'}}>
            {/*begin::Table container*/}
            {!pageLoading ? 
                resultsFound && 
                <div className="table-responsive">
                    {/*begin::Table*/}
                    <motion.table
                            initial={{ y: 100, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ duration: 0.3 }}
                            className="table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 p-5"
                    >
                        <thead>
                            <tr className="fw-bold text-muted bg-light">
                                <th className="ps-4 min-w-250px rounded-start">Name</th>
                                <th className="min-w-125px">Capacity</th>
                                <th className="min-w-125px">Manager</th>
                                <th className="min-w-125px">Created By</th>
                                <th className="min-w-150px">Contact</th>
                                <th className="min-w-125px">Asset Count</th>
                                <th className="min-w-125px">Item Count</th>
                                <th className="min-w-125px">Project Count</th>
                                <th className="min-w-125px">Client Count</th>
                                <th className="min-w-125px">Brand Count</th>
                                <th className="min-w-125px">Zone Count</th>
                            </tr>
                        </thead>
                        {/*end::Table head*/}
                        {/*begin::Table body*/}
                        <tbody>
                            {warehouses &&
                            warehouses.map((warehouse, index) => {
                            return (<tr key={index}>
                                <td>
                                    <div className="d-flex align-items-center">
                                    <div className="symbol symbol-60px me-3" style={{width: '60px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        {warehouse.warehouse_image_path && warehouse.warehouse_image_path !== '' ? 
                                        <img src={warehouse.warehouse_image_path} className="h-100 align-self-end" alt={warehouse.warehouse_image_name} style={{width: '100%', height: '100%', borderRadius: '5px'}} />
                                        :
                                        <span className='className="symbol-label bg-light"'>
                                            <span className="svg-icon svg-icon-muted svg-icon-3x">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M11 2.375L2 9.575V20.575C2 21.175 2.4 21.575 3 21.575H9C9.6 21.575 10 21.175 10 20.575V14.575C10 13.975 10.4 13.575 11 13.575H13C13.6 13.575 14 13.975 14 14.575V20.575C14 21.175 14.4 21.575 15 21.575H21C21.6 21.575 22 21.175 22 20.575V9.575L13 2.375C12.4 1.875 11.6 1.875 11 2.375Z" fill="black"></path></svg>
                                            </span>
                                        </span>
                                        }
                                    </div>
                                    <div className="d-flex justify-content-start flex-column">
                                        <Link to={`/warehouse-management/warehouses/detailed-warehouse/${warehouse.id}`} className="text-dark fw-bolder text-hover-primary fs-6" href="/asset-management/detailed-asset/1">{warehouse.Warehouse_name}</Link>
                                    </div>
                                </div>
                                </td>
                                <td>
                                    <a className="text-dark ftw-bold d-block mb-1 fs-6">{(warehouse.Warehouse_capacity !== '' && warehouse.Warehouse_capacity !== null) ? warehouse.Warehouse_capacity.concat(' ft²') : 'Not Added'}</a>
                                </td>
                                <td>
                                {(warehouse.Warehouse_manager !== '' && warehouse.Warehouse_manager !== null) ?
                                    <Link to={`/user-management/users/user-profile/${warehouse.Warehouse_manager}`} className="text-dark ftw-bold text-hover-primary d-block mb-1 fs-6">{warehouse.Warehouse_manager_name}</Link> :
                                    <a className="text-dark ftw-bold d-block mb-1 fs-6">Not Added</a>}
                                </td>
                                <td>
                                    <Link to={`/user-management/users/user-profile/${warehouse.Created_by}`} className="text-dark ftw-bold text-hover-primary d-block mb-1 fs-6">{warehouse.created_by_name}</Link>
                                </td>
                                <td>
                                    <a className="text-dark ftw-bold d-block mb-1 fs-6">{(warehouse.Warehouse_contact !== '' && warehouse.Warehouse_contact !== null) ? warehouse.Warehouse_contact : 'Not Added'}</a>
                                </td>
                                <td>
                                    <a className="text-dark ftw-bold d-block mb-1 fs-6">{warehouse.asset_count} {warehouse.asset_count === 1 ? 'Asset' : 'Assets'}</a>
                                </td>
                                <td>
                                    <a className="text-dark ftw-bold d-block mb-1 fs-6">{warehouse.item_count} {warehouse.item_count === 1 ? 'Item' : 'Items'}</a>
                                </td>
                                <td>
                                    <a className="text-dark ftw-bold d-block mb-1 fs-6">{warehouse.project_count} {warehouse.project_count === 1 ? 'Project' : 'Projects'}</a>
                                </td>
                                <td>
                                    <a className="text-dark ftw-bold d-block mb-1 fs-6">{warehouse.client_count} {warehouse.client_count === 1 ? 'Client' : 'Clients'}</a>
                                </td>
                                <td>
                                    <a className="text-dark ftw-bold d-block mb-1 fs-6">{warehouse.brand_count} {warehouse.brand_count === 1 ? 'Brand' : 'Brands'}</a>
                                </td>
                                <td>
                                    <a className="text-dark ftw-bold d-block mb-1 fs-6">{warehouse.wz_count} {warehouse.wz_count === 1 ? 'Zone' : 'Zones'}</a>
                                </td>
                            </tr>)})}
                        </tbody>
                        {/*end::Table body*/}
                    </motion.table>
                </div> :
            <SkeletonLoader /> }
        </div>
    </div>
  )
}
