import React, { useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export default function ItemImages({images}) {

    const [itemImages, setItemImages] = useState([])
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setItemImages(images)
    }, [images])

  return (
    <div>
        <div className="gallery row">
        {images.length > 0 ? images.map((image, index) => (
          <div className='col-md-3 image-gallery-single'>
                <img
                    key={index}
                    src={image.image_url}
                    onClick={() => {
                    setPhotoIndex(index);
                    setIsOpen(true);
                    }}
                    alt={itemImages.image_path_name}
                />
          </div>
        )) : 
        <div style={{width: '100%', textAlign: 'center'}}>
            <p>No Images have been added</p>
        </div>}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].image_url}
          nextSrc={images[(photoIndex + 1) % images.length].image_url}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].image_url}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  )
}
