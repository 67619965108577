import React, {useState, useEffect, useRef} from 'react'
import api from '../../store/api';
import { useDispatch, useSelector } from 'react-redux';
import ProfileImageInput from '../Utilities/ProfileImageInput'
import { updateUser, updateUserProfile } from '../../store/loginSlice';


export default function UpdateUserProfileModalBody({profile, user_type, user_email, user_id, updateSuccessState, clause}) {

    const [userProfile, setUserProfile] = useState({})
    const [userEmail, setUserEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const token = useSelector((state) => state.loginSlice.token);
    const dispatch = useDispatch();

    useEffect(() => {
        setUserProfile(profile)
        setUserEmail(user_email)

        return () => {
            userProfile.imagePath && URL.revokeObjectURL(userProfile.imagePath);
        }
    }, [profile, user_email, user_id])

    const handleImageChange = (event) => {
        const imageFile = event.target.files[0];
        if (imageFile && imageFile.type.startsWith('image/')) {
            const imagePath = URL.createObjectURL(imageFile);
            setUserProfile({...userProfile, profile_photo: imageFile, profile_photo_path: imagePath});
        } else {
          throw new Error('Invalid file type. Please select an image file.');
        }
      };


    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserProfile({
          ...userProfile,
          [name]: value
        });
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        setLoading(true)
        const formData = new FormData();
        if (user_type === 'crew') {
            formData.append("first_name", userProfile.first_name)
            formData.append("user_address", userProfile.user_address)
            formData.append("last_name", userProfile.last_name)
            formData.append("phone_number", userProfile.phone_number)
            formData.append("crew_role", userProfile.crew_role)
            formData.append("nhif_number", userProfile.nhif_number)
            formData.append("nssf_number", userProfile.nssf_number)
            formData.append("kra_pin", userProfile.kra_pin)
            formData.append("profile_photo", userProfile.profile_photo)
        } else if (user_type === 'staff') {
            formData.append("first_name", userProfile.first_name)
            formData.append("user_address", userProfile.user_address)
            formData.append("last_name", userProfile.last_name)
            formData.append("phone_number", userProfile.phone_number)
            formData.append("occupation", userProfile.occupation)
            formData.append("profile_photo", userProfile.profile_photo)
        } else {
            formData.append("first_name", userProfile.first_name)
            formData.append("user_address", userProfile.user_address)
            formData.append("last_name", userProfile.last_name)
            formData.append("phone_number", userProfile.phone_number)
            formData.append("profile_photo", userProfile.profile_photo)
        }

        api.put(`/api/users/update-profile/${user_id}/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Token ${token}`,
            },
        })
        .then((response) => {
            const message = response.data.message;
            const profile = response.data.updated_profile
            const user = response.data.updated_user
            updateSuccessState(profile, message)
            dispatch(updateUser(user));
            dispatch(updateUserProfile(profile));
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
            throw new Error(error)
        })
    }
    
    const renderUserProfileForm = () => {
        if (user_type === 'staff') {
            return (
                <div id="kt_modal_update_user_user_info" className="collapse show">
                    <div className="mb-7">
                        <label className="fs-6 fwt-bold mb-2">
                            <span>Update Profile Picture</span>
                            <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Allowed file types: png, jpg, jpeg." aria-label="Allowed file types: png, jpg, jpeg."></i>
                        </label>
                        <ProfileImageInput setImage={(image) => {
                            setUserProfile({...userProfile, profile_photo: image})
                        }} edit={true} image={userProfile.profile_photo_path} />
                    </div>
                    <div className='row'>
                    <div className="fv-row mb-7 col-md-6">
                        <label className="fs-6 fwt-bold mb-2">First Name</label>
                        <input type="text" className="form-control form-control-solid" placeholder="" name="first_name" value={userProfile.first_name} onChange={handleChange}/>
                    </div>
                    <div className="fv-row mb-7 col-md-6">
                        <label className="fs-6 fwt-bold mb-2">Last Name</label>
                        <input type="text" className="form-control form-control-solid" placeholder="" name="last_name" value={userProfile.last_name} onChange={handleChange}/>
                    </div>
                    </div>
                    <div className="fv-row mb-7">
                        <label className="fs-6 fwt-bold mb-2">
                            <span>Email</span><i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Email address can be changed under the settings tab." aria-label="Email address must be active"></i>
                        </label>
                        <input type="email" className="form-control form-control-solid"  disabled placeholder="" name="email" value={userEmail} />
                    </div>
                    <div className="fv-row mb-7">
                    <label className="fs-6 fwt-bold mb-2">Phone Number</label>
                    <input type="text" className="form-control form-control-solid" placeholder="" name="phone_number" value={userProfile.phone_number} onChange={handleChange}/></div>
                    <div className="fv-row mb-7">
                    <label className="fs-6 fwt-bold mb-2">Occupation</label>
                    <input type="text" className="form-control form-control-solid" placeholder="" name="occupation" value={userProfile.occupation} onChange={handleChange}/></div>
                </div>
            )
        } else if (user_type === 'client') {
            return (
                <div id="kt_modal_update_user_user_info" className="collapse show">
                    <div className="mb-7">
                        <label className="fs-6 fwt-bold mb-2">
                            <span>Update Avatar</span>
                            <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Allowed file types: png, jpg, jpeg." aria-label="Allowed file types: png, jpg, jpeg."></i>
                        </label>
                        <ProfileImageInput setImage={(image) => {
                            setUserProfile({...userProfile, profile_photo: image})
                        }} edit={true} image={userProfile.profile_photo_path} />
                    </div>
                    <div className='row'>
                    <div className="fv-row mb-7 col-md-6">
                        <label className="fs-6 fwt-bold mb-2">First Name</label>
                        <input type="text" className="form-control form-control-solid" placeholder="" name="first_name" value={userProfile.first_name} onChange={handleChange} />
                    </div>
                    <div className="fv-row mb-7 col-md-6">
                        <label className="fs-6 fwt-bold mb-2">Last Name</label>
                        <input type="text" className="form-control form-control-solid" placeholder="" name="last_name" value={userProfile.last_name} onChange={handleChange} />
                    </div>
                    </div>
                    <div className="fv-row mb-7">
                        <label className="fs-6 fwt-bold mb-2">
                            <span>Email</span><i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Email address can be changed under the settings tab." aria-label="Email address must be active"></i>
                        </label>
                        <input type="email" className="form-control form-control-solid"  disabled placeholder="" name="email" value={userEmail} />
                    </div>
                    <div className="fv-row mb-7">
                    <label className="fs-6 fwt-bold mb-2">Phone Number</label>
                    <input type="text" className="form-control form-control-solid" placeholder="" name="phone_number" value={userProfile.phone_number} onChange={handleChange} /></div>
                </div>
            )
        } else {
            return (
                <div id="kt_modal_update_user_user_info" className="collapse show">
                <div className="mb-7">
                    <label className="fs-6 fwt-bold mb-2">
                        <span>Update Profile photo</span>
                        <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Allowed file types: png, jpg, jpeg." aria-label="Allowed file types: png, jpg, jpeg."></i>
                    </label>
                    <ProfileImageInput setImage={(image) => {
                            setUserProfile({...userProfile, profile_photo: image})
                    }} edit={true} image={userProfile.profile_photo_path} />
                </div>
                <div className='row'>
                <div className="fv-row mb-7 col-md-6">
                    <label className="fs-6 fwt-bold mb-2">First Name</label>
                    <input type="text" className="form-control form-control-solid" placeholder="" name="first_name" value={userProfile.first_name} onChange={handleChange}/>
                </div>
                <div className="fv-row mb-7 col-md-6">
                    <label className="fs-6 fwt-bold mb-2">Last Name</label>
                    <input type="text" className="form-control form-control-solid" placeholder="" name="last_name" value={userProfile.last_name} onChange={handleChange}/>
                </div>
                </div>
                <div className="fv-row mb-7">
                    <label className="fs-6 fwt-bold mb-2">
                        <span>Email</span><i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Email address can be changed under the settings tab." aria-label="Email address must be active"></i>
                    </label>
                    <input type="email" className="form-control form-control-solid"  disabled placeholder="" name="email" value={userEmail} />
                </div>
                <div className="fv-row mb-7">
                <label className="fs-6 fwt-bold mb-2">Phone Number</label>
                <input type="text" className="form-control form-control-solid" placeholder="" name="phone_number" value={userProfile.phone_number} onChange={handleChange}/></div>
                <div className="fv-row mb-7">
                <label className="fs-6 fwt-bold mb-2">Crew Role / Occupation</label>
                <input type="text" className="form-control form-control-solid" placeholder="" name="crew_role" value={userProfile.crew_role} onChange={handleChange} /></div>
                <div className="fv-row mb-7">
                <label className="fs-6 fwt-bold mb-2">NHIF</label>
                <input type="text" className="form-control form-control-solid" placeholder="" name="nhif_number" value={userProfile.nhif_number} onChange={handleChange} /></div>
                <div className="fv-row mb-7">
                <label className="fs-6 fwt-bold mb-2">NSSF</label>
                <input type="text" className="form-control form-control-solid" placeholder="" name="nssf_number" value={userProfile.nssf_number} onChange={handleChange} /></div>
                <div className="fv-row mb-7">
                <label className="fs-6 fwt-bold mb-2">KRA Pin</label>
                <input type="text" className="form-control form-control-solid" placeholder="" name="kra_pin" value={userProfile.kra_pin} onChange={handleChange}/></div>
            </div>
            )
        }
    }

  return (
    <div className="d-flex flex-column scroll-y me-n7 pe-7"
    id="kt_modal_update_user_scroll"
    data-kt-scroll="true"
    data-kt-scroll-activate="{default: false, lg: true}"
    data-kt-scroll-max-height="auto"
    data-kt-scroll-dependencies="#kt_modal_update_user_header"
    data-kt-scroll-wrappers="#kt_modal_update_user_scroll"
    data-kt-scroll-offset="300px"
    style={{maxHeight: "586px"}}
    >
    <form className="form" id="kt_modal_update_user_form" onSubmit={handleSubmit}>
    <div className="fwt-boldest fs-5 rotate collapsible mb-7" data-bs-toggle="collapse" href="#kt_modal_update_user_user_info" role="button" aria-expanded="false" aria-controls="kt_modal_update_user_user_info">
        {clause} Information
        <span className="ms-2 rotate-180">
            <span className="svg-icon svg-icon-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                        fill="black"
                    ></path>
                </svg>
            </span>
        </span>
    </div>
    {renderUserProfileForm()}
    <div className="modal-footer flex-center">
    <button type="reset" className="btn btn-light me-3" data-kt-users-modal-action="cancel">Discard</button>
    <button type="submit" className="btn btn-beysix" data-kt-users-modal-action="submit" disabled={loading ? true : false}>
    {!loading ? <span className="indicator-label">Submit</span>
    : <span className="indicator-progress">Please wait...<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
    }
    </button>
    </div>
    </form>
</div>
  )
}
