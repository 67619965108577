import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import api from '../../../store/api'

export default function SuccessAccountCreation() {

  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    api.get(`/api/users/show-account-approval/${id}`)
    .then((response) => {
      return;
    })
    .catch((error) => {
      const err = error.response
			if (err.status === 404)  {
				navigate(err.data.url);
				return;
			}
			throw new Error(err.data.error)
    })
  }, [])

  return (
        <div className="pt-lg-10 mb-10" style={{width: "30vw"}}>
            {/*begin::Logo*/}
            <h1 className="fwt-bolder fs-2qx text-gray-800 mb-7 text-center">Welcome to Beysix Studio</h1>
            {/*end::Logo*/}
            {/*begin::Message*/}
            <div className="fwt-bold fs-5 text-muted mb-15">Please contact an administrator for approval to access the system, or wait for your account to be approved.</div>
            {/*end::Message*/}
            {/*begin::Action*/}
            <div className="text-center">
                <Link to='/auth/login' className="btn btn-lg btn-beysix fwt-bolder">Go to Login</Link>
            </div>
            {/*end::Action*/}
        </div>
  )
}
