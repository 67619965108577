import React,  { useState, useEffect }  from 'react'
import { useSelector } from 'react-redux'
import api from '../../../store/api'
import parseDateTimeStamp from '../../Utilities/parseDateTimeStamp.'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import BrandTable from '../../Brand/BrandTable/BrandTable'

export default function ActivationBrands() {

    const [brandList, setBrandList] = useState([])
    const token = useSelector((state) => state.loginSlice.token);
    const [pageLoading, setPageLoading] = useState(false)
    const [resultsFound, setResultsFound] = useState(true)
    const { id } = useParams()

    useEffect(() => {
        setPageLoading(true)
        api.get(`/api/activations/activation-brands/${id}/`,
        {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
        .then((response) => {
            setBrandList(response.data.results)
            setPageLoading(false)
            if (response.data.length === 0)
                setResultsFound(false)
        })
        .catch((error) => {
            setPageLoading(false)
            throw new Error('Error getting data.')
        })
    }, [])

  return (
    <div id="kt_content_container" className="container-xxl">
    <div className="card mb-5 mb-xl-8">
        <BrandTable brandData={brandList} loading={pageLoading} resultsFound={resultsFound} />
    </div>
</div>

  )
}
